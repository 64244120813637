<template>
  <GDialog v-model="hrAddAccruedDialog" max-width="32rem" persistent data-cy="hrAddAccruedDialog">
    <div class="p-4">
      <div class="flex items-center justify-between">
        <h3 class="H600 N900 dark:text-" data-cy="dialogTitle">Add/Subtract leave days</h3>
        <CloseCircle @click="$emit('closeDialog'); resetForm();" data-cy="closeButton" />
      </div>

      <div class="
          flex flex-col
          lg:flex-row
          items-stretch
          justify-start
          mt-[0.44rem]
          w-full
          gap-x-[0.62rem]
        ">
        <ProfileImageComponent :initials="employeeInformation?.full_name
          ? getUserNameInitials(employeeInformation.full_name)
          : ''
          " :imgSrc="employeeInformation?.profile_image ?? ''" widthSize="60px" heightSize="60px" text-size="text-2xl" />
        <div class="flex flex-col N700">
          <div class="flex text-base gap-x-2">
            <p class="font-medium lg:truncate lg:w-[7rem]">
              {{ employeeInformation?.full_name }}
            </p>
            <p class="N500">|</p>
            <p class="font-normal lg:truncate lg:w-[7rem]">
              {{ employeeInformation?.department_name }}
            </p>
          </div>
          <div>
            <p class="font-normal">{{ employeeInformation?.position_name }}</p>
          </div>
        </div>

        <!-- available days -->
        <div class="
            flex
            items-center
            justify-center
            w-[120px]
            h-[120px]
            m-5
            border-2 border-red-600
            rounded-full
          " data-cy="availableDays">
          <span class="flex flex-col items-center justify-center">
            <h1 data-cy="daysAccrued">{{ leaveDaysAccrued?.toFixed(2) }}</h1>
            <h6>available</h6>
          </span>
        </div>
      </div>

      <div class="flex flex-col lg:flex-row items-center justify-between pb-5" data-cy="textFields">
        <div class="flex items-stretch justify-between gap-5" data-cy="radioButtons">
          <label class="flex items-center gap-2">
            <RadioInput label="Add leave day" fieldId="addLeaveDay" :checked="leave.addition === 'add'"
              @change="onRadioChange('add')" />
          </label>

          <label class="flex items-center gap-2">
            <RadioInput label="Subtract leave day" fieldId="subtractLeaveDay" :checked="leave.addition === 'subtract'"
              @change="onRadioChange('subtract')" />
          </label>
        </div>
      </div>
      <div class="pb-5">
        <InputFieldValue data-cy="numberInput" class="BG0"
          :placeholder="'Enter number of days to ' + (leave.addition === 'subtract' ? 'subtract' : 'add')" type="number"
          v-model="leave.hr_change" />
      </div>
      <div class="pb-5">
        <MultiLineInputBox data-cy="commentInput" class="BG0" label="Comment" :showlength="true" :maxlength="200"
          :placeholder="'Reason for ' + (leave.addition === 'subtract' ? 'subtracting' : 'adding')"
          v-model="leave.comment" />
      </div>
      <!-- Form actions -->
      <div class="flex justify-end gap-4 pt-2" data-cy="formActions">
        <ButtonComponent button-label="Cancel" :variant="'secondary'" @click="$emit('closeDialog'); resetForm();"
          dataCy="cancel"></ButtonComponent>
        <ButtonComponent data-cy="submitButton" @click="() => { addOrSubstractAccrued.mutate(); }"
          :disabled="leave.hr_change.trim().length < 1 || leave.addition.trim().length < 1 || parseFloat(leave.hr_change) <= 0"
          :loading="loading">
          {{ leave.addition === 'subtract' ? 'Subtract' : 'Add' }}
        </ButtonComponent>

      </div>
    </div>

    <AlertComponent :message-block="messageBlock" />
  </GDialog>
</template>

<script setup lang="ts">
// Vue imports
import { ref, toRefs, getCurrentInstance, reactive } from "vue";

//interfaces
import { HrLeaveAccruedData } from "../../interfaces/hr-dashboard/LeaveHrApprovals";

// Helper functions
import { getUserNameInitials } from "@/helpers/functions";

// SVG Component Imports
import CloseCircle from "@/apps/leave-management/assets/svgComponents/CloseCircle.vue";

// UI Component Imports
import ButtonComponent from "@/ui-kit/button/ButtonComponent.vue";
import MultiLineInputBox from "@/ui-kit/Inputs/TextArea.vue";
import InputFieldValue from "@/ui-kit/Inputs/InputField.vue";
import RadioInput from "@/ui-kit/Inputs/RadioInput.vue";
import ProfileImageComponent from "@/components/ProfileImageComponent.vue";
import AlertComponent from "@/ui-kit/AlertComponent.vue";

// Apollo Imports
import {
  useQuery,
  useMutation,
  useMutationLoading,
} from "@vue/apollo-composable";

// GraphQL Mutations and Queries
import { ADD_OR_SUBTRACT_LEAVE_DAYS } from "../../graphql/mutations/hr-dashboard/setHrApprovals";
import { GET_ACCRUED_DAYS } from "@/apps/leave-management/graphql/querries/book-leaves/getLeaveRequests";

const props = defineProps({
  leaveDaysAccrued: Number,
  openLeaveEmployeeDialog: Boolean,
  editLeaveRequest: Boolean,
  employeeId: String,
  employeeInformation: Object,
});
const { leaveDaysAccrued, employeeId, employeeInformation } = toRefs(props);

const hrAddAccruedDialog = ref(false);
const messageBlock = reactive({ open: false, severity: "", message: "" });
const loading = useMutationLoading();

const leave = reactive<HrLeaveAccruedData>({
  user_id: "",
  hr_change: "",
  comment: "",
  addition: "add",
});

const resetForm = (): void => {
  leave.hr_change = "";
  leave.comment = "";
};

const onRadioChange = (value: string) => {
  leave.addition = value;
};

const addOrSubstractAccrued = useMutation(ADD_OR_SUBTRACT_LEAVE_DAYS, () => ({
  variables: {
    data: {
      user_id: employeeId?.value,
      hr_change: parseInt(leave.hr_change),
      comment: leave.comment,
      addition: leave.addition === "add",
    },
  },
}));

const { refetch: refetchGetLeaveAccruedData } = useQuery(GET_ACCRUED_DAYS, {
  userId: employeeId,
});
const { emit } = getCurrentInstance() as {
  emit: (event: string, ...args: []) => void;
};
addOrSubstractAccrued.onDone(() => {
  messageBlock.open = true;
  resetForm();
  messageBlock.severity = "success";
  messageBlock.message = leave.addition === "add" ? "Accrual added successfully" : "Accrual subtracted successfully";
  refetchGetLeaveAccruedData();
  setTimeout(() => {
    messageBlock.open = false;
    emit("closeDialog");
    hrAddAccruedDialog.value = false;
  }, 3000);
});

addOrSubstractAccrued.onError((error) => {
  messageBlock.open = true;
  resetForm();
  refetchGetLeaveAccruedData();
  messageBlock.severity = "error";
  messageBlock.message =
    error.message.length <= 100
      ? error.message
      : "Please try again or contact support";
  setTimeout(() => {
    messageBlock.open = false;
    emit("closeDialog");
    hrAddAccruedDialog.value = false;
  }, 3000);
});
</script>
