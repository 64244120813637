import gql from 'graphql-tag';

export const GET_MANAGER_REQUESTS = gql`
  query GetManagerRequests($managerId: ID!, $page: LeaveRequestInput) {
    getManagerRequests(manager_id: $managerId, page: $page) {
      id
      status
      days_off
      carry_over_used
      user_id
      manager_id
      manager_approved
      days
      year
      file
      first_last_days
      leave_type
      user {
        id
        full_name
        profile_image
      }
    }
  }
`;

export const GET_TOTAL_MANAGER_REQUESTS = gql`
  query CountManagerRequests($data: leaveCountInput!, $managerId: ID!) {
    countManagerRequests(data: $data, manager_id: $managerId) {
      count
      status
    }
  }
`;

export const GET_MANAGER_PLANNER = gql`
  query ListManagerLeavePlanner($managerId: ID!) {
    listManagerLeavePlanner(manager_id: $managerId) {
      user_id
      project_id
      days {
        date
        leave_type
        leave_request {
          days_off
          first_last_days
        }
      }
      leave_types_used {
        days_used
        leave_type {
          title
        }
      }
      user {
        first_name
        last_name
      }
    }
  }
`;

export const GET_EMPLOYEE_PLANNER = gql`
  query GetEmployeeLeavePlanner($userId: ID!) {
    getEmployeeLeavePlanner(user_id: $userId) {
      user_id
      project_id
      days {
        date
        leave_type
        leave_request {
          days_off
          first_last_days
        }
      }
      leave_types_used {
        days_used
        leave_type {
          title
        }
      }
      user {
        first_name
        last_name
      }
    }
  }
`;

export const GET_TOUCH_POINT_COMMENTS = gql`
  query ListCommentsById($requestId: ID!) {
    listCommentsById(request_id: $requestId) {
      id
      message
      user_id
      timestamp
      booked_leaves_id
      user {
        full_name
        profile_image
      }
    }
  }
`;

export const ALL_DEPARTMENTS = gql`
  query listDepartments {
    listDepartments {
      id
      codename
      department_name
      parent_department
      user {
        id
        first_name
        last_name
      }
    }
  }
`;
