import { useAccessStore } from '@/apps/leave-management/store/store_leaves_permissions';

interface HeaderType {
  key: string;
  label: string;
}

export const removeActionHeader = (headers: HeaderType[], check: boolean) => {
  if (check) {
    return headers;
  }
  return headers.filter((header) => {
    return header.label !== 'Action' && header.label !== 'Actions';
  });
};

export const resetPermissionsStores = () => {
  useAccessStore().$reset();
};
