<template>
  <div class="BG0 px-5 py-5 pb-8 rounded-md">
    <!-- ONBOARDING CARD -->
    <OnboardingCardComponent class="z-10" @btncall="setLocal" v-if="ListLeavesBookedTableData.length === 0 &&
      ListLeavesBookedTimeOffTableData.length === 0 &&
      showOnboarding &&
      !queryLoading
    " title="Welcome to Leaves"
      content="On this page, you can book and edit leave requests. Click on book a leave to get started.">
    </OnboardingCardComponent>

    <!-- Header Section -->
    <div class="flex flex-col items-center gap-5 lg:justify-between lg:flex-row lg:gap-0">
      <div class="flex flex-col">
        <h3 class="H700 N900">Leave Requests ({{ CombinedLeavesTableData.length }})</h3>
        <p class="N600">{{ countryName }}</p>
      </div>
      <div class="flex gap-2">
        <ButtonSkeleton v-if="queryLoading" />
        <ButtonComponent v-if="!queryLoading && bookLeavePerm?.manage_leaves" buttonLabel="Book a leave"
          variant="primary"
          @submit="[(editMode = false), (scheduleMode = false), (addDialog = true), fetchPublicHolidays(),]"
          data-cy="bookLeave" class="shadow-md" />
      </div>
    </div>

    <!-- Search and Filters Section -->
    <div class="w-full flex flex-col items-center justify-between gap-10 colwidth lg:flex-row lg:gap-0">
      <div class="w-[70%] pr-5">
        <div class="flex flex-col items-center gap-5 my-6 -ml-2 lg:justify-between lg:flex-row lg:gap-0">
          <div class="flex items-center gap-2 pl-2">
            <FilterComponent data-cy="filterButton" @click="toggleFilters" />
            <!-- Search Input -->
            <SearchComponent data-cy="search" class="rounded-2xl w-38" @search="searchTableData" />
            <div class="holidaySelect">
              <div v-if="queryLoading" class="flex flex-col items-center justify-center h-full">
                <div class="
                      border-solid border-t border-[#db5b34]
                      rounded-full
                      w-5
                      h-5
                      animate-spin
                    "></div>
              </div>
              <LeaveCalculator data-cy="leaveCalculator" v-if="!queryLoading" @click="calculatorDialog = true" class="
                    w-fit
                    h-10
                    p-2.5
                    transition
                    duration-300
                    border border-gray-200
                    BG0
                    rounded-lg
                    cursor-pointer
                    hover:shadow-xl
                  " />
            </div>
          </div>
          <!-- Public Holidays Button -->
          <div class="flex ml-2">
            <div class="relative inline-block group">
              <ButtonSkeleton v-if="queryLoading" />
              <ButtonComponent data-cy="publicHolidays" v-if="!queryLoading" @click="togglePublicHolidays"
                variant="secondary" class="flex items-center w-full h-10 truncate cursor-pointer BG0 with_border">
                Public Holidays
                <UpArrow v-if="isHolidaysVisible" />
                <DownArrow v-else />
              </ButtonComponent>
              <div v-show="isHolidaysVisible" class="
                    absolute
                    BG0
                    min-w-[350px]
                    h-[450px]
                    overflow-y-auto
                    p-5
                    shadow-md
                    right-0
                    top-11
                    rounded-lg
                    z-10
                    group-hover:block
                  ">
                <div class="flex lg:justify-between sm:justify-between md:justify-between">
                  <h6 class="H500 N900">{{ publicHolidayTitle }}</h6>
                  <CloseCircle data-cy="cancelPublicHolidays" @click="togglePublicHolidays" />
                </div>
                <div v-if="ListLeavesHolidays.length === 0" class="flex flex-col items-center justify-center h-full">
                  No Public Holidays :(
                </div>
                <div v-for="holiday in ListLeavesHolidays" :key="holiday.id">
                  <p class="pt-3 truncate w-80 N900 hover:text-red-500" :title="holiday.title">
                    {{ holiday.start_day.split("T")[0] }} -
                    {{ holiday.title }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Filter Selects -->
        <div v-show="showFilters" class="flex items-center gap-4 px-4 mb-3">
          <p class="N900">Filter</p>
          <div class="holidaySelect">
            <SelectFieldValue :showSearch="false" :showLabel="false" id="status" data-cy="filterField"
              class="w-full outline-none" placeholder="Filter" :options="['All', 'Pending', 'Approved', 'Denied']"
              v-model="selectedFilter" />
          </div>
          <DatePicker :requireTag="true" v-model="selectedDate" separator=" to " :formatter="formatter"
            style="height: 42px; width: 250px" placeholder="Filter by date" />
        </div>

        <!-- Booked Leave Table -->
        <div class="pb-4 rounded-lg tableView" v-if="!queryLoading">
          <TableComponentVue :overflowXAuto="true" :customScrollbar="true" :headers="displayedHeaders"
            :itemsPerPage="10" :items="CombinedLeavesTableData" emptyMessage="No Upcoming timeoff & History requests">
            <!-- Slot Templates -->
            <template #days="{ item }">
              <TooltipComponent :text="getSpanTitle(item.first_last_days)" :position="'right'">
                <div class="flex items-center w-fit">
                  {{ formatDateShort(item.first_last_days[0]) }} - {{ formatDateShort(item.first_last_days[1]) }}
                </div>
              </TooltipComponent>
            </template>

            <template #leave_type="slotProps">
              <span class="flex items-center gap-2">
                {{ slotProps.item.leave_type }}
                <span v-if="hasTouchPointComments(slotProps.item)" class="relative group">
                  <ToolTipIcon />
                  <div
                    class="w-80 max-h-32 bg-opacity-90 bg-[#101840] z-10 N0 text-sm rounded p-2 absolute left-full hidden group-hover:block overflow-hidden whitespace-normal -top-8">
                    {{ slotProps.item.touch_point_comments?.[0]?.message }}
                  </div>
                </span>
              </span>
            </template>

            <template #days_off="slotProps">
              <span class="flex items-center">
                {{ (slotProps.item as LeaveRequest).days_off.toFixed(2) }}
                <span v-if="(slotProps.item as LeaveRequest).carry_over_used > 0" title="Carry over"
                  class="text-[0.7rem] O300">
                  &nbsp;+{{ (slotProps.item as LeaveRequest).carry_over_used.toFixed(2) }}
                </span>
              </span>
            </template>

            <template #status="slotProps">
              <div data-cy="approvalStatus" class="flex items-center px-2 truncate rounded-lg fontSize gap-x-1 w-fit"
                :class="statusClass(slotProps.item)">
                <span>•</span>
                {{ getStatusText(slotProps.item) }}
              </div>
            </template>

            <template #action="slotProps">
              <!-- Table Action Buttons -->
              <div v-if="showActionButtons(slotProps.item)">
                <div class="flex justify-start">
                  <DropdownMenu :isLastItem="isLastItem(slotProps)" :bottom-class="'-mt-[10rem] absolute z-50'"
                    data-cy="actions" @selectOption="(name: string) => handleSelectOption(slotProps.item, name)"
                    :optionsList="computedDropdownMenu(slotProps.item)" />
                </div>
              </div>
            </template>
          </TableComponentVue>
        </div>
      </div>

      <!-- Summary Section -->
      <div class="w-[30%] p-5 rounded-lg border border-gray-200 BG0 sticky mt-[25px]">
        <h2 class="H700 N900">My time offs</h2>
        <p class="mt-2 N700">Track your available leave days</p>
        <!-- Annual Leave Days -->
        <h4 class="flex items-center justify-center mt-10 H700 N900">
          Annual leave days
        </h4>
        <div class="flex items-center justify-center m-5">
          <div class="
                flex
                items-center
                justify-center
                w-[11rem]
                h-[11rem]
                border-2 border-[#B5461D]
                rounded-full
              " title="Accrued Leave Entitlement for Booking">
            <div v-if="queryLoading" class="flex flex-col items-center justify-center h-full">
              <div class="
                    border-solid border-t-2 border-[#db5b34]
                    rounded-full
                    w-[11rem]
                    h-[11rem]
                    animate-spin
                  "></div>
            </div>
            <span v-if="!queryLoading" class="flex relative flex-col items-center justify-center text-[#101840]">
              <h1>
                {{ GetAccruedDays?.leaveDaysAccrued?.toFixed(2) || "0.00" }}
              </h1>
              <h6>available</h6>
              <p class="
                flex flex-col
                absolute
                left-[6rem]
                -bottom-5
              ">
                <span v-if="showCarryOver && !queryLoading"
                  class="flex relative justify-end left-5 SPC-MR-200 H500 N900 BG0">
                  {{ GetAccruedDays?.carry_over_days.toFixed(2) }}
                </span>
                <span class="text-sm BG0 relative left-8" v-if="showCarryOver && !queryLoading">Carry&nbsp;over</span>
              </p>
            </span>
          </div>
        </div>
        <!-- Leave Type Days -->
        <div class="flex flexCol lg:flex-row items-center justify-center gap-2">
          <div class="
                flex flex-col
                w-[150px]
                height-[150px]
                p-5
                border border-gray-300
                rounded-lg
              " title="Used so far this year">
            <div v-if="queryLoading" class="flex flex-col items-center justify-center h-full">
              <div class="
                    border-solid border-t border-[#db5b34]
                    rounded-full
                    w-5
                    h-5
                    animate-spin
                  "></div>
            </div>
            <h1 v-if="!queryLoading" class="flex items-center justify-center H700 N900">
              {{ GetAccruedDays?.leaveDaysUsed?.toFixed(2) || "0.00" }}
            </h1>
            <p class="N700">Used this year</p>
          </div>
          <div class="
                flex flex-col
                w-[150px]
                height-[150px]
                p-5
                border border-gray-300
                rounded-lg
              " title="For Annual leave">
            <div v-if="queryLoading" class="flex flex-col items-center justify-center h-full">
              <div class="
                    border-solid border-t border-[#db5b34]
                    rounded-full
                    w-5
                    h-5
                    animate-spin
                  "></div>
            </div>
            <h1 v-if="!queryLoading" class="flex items-center justify-center H700 N900">
              {{ GetAccruedDays?.max_accrual?.toFixed(2) || "0.00" }}
            </h1>
            <p class="N700">For Annual leave</p>
          </div>
        </div>
        <div>
          <hr class="m-5" />
          <h4 class="H700 N900">Leave type days</h4>
          <ol>
            <li class="flex flex-row items-center gap-2 mt-2 truncate fontSize">
              <AnnualLeaveIcon />
              Annual Leave
              <span class="N700">{{
                GetAccruedDays?.leaveDaysAccrued?.toFixed(2) || "0.00"
              }}
                days available</span>
            </li>
            <li v-for="leaveTypeCount in filteredLeaveTypeCounts" :key="leaveTypeCount.leave_type.title"
              class="flex flex-row items-center gap-2 mt-2 truncate fontSize">
              <component :is="getLeaveTypeIcon(leaveTypeCount.leave_type.title)" />
              {{ leaveTypeCount.leave_type.title }}
              <span class="text-sm N700">{{ leaveTypeCount.days_off.toFixed(2) }} days used</span>
            </li>
          </ol>
        </div>
      </div>

      <!-- Loading State -->
      <div v-if="queryLoading && ListLeavesBooked.length === 0">
        <LoaderIconVue />
      </div>
    </div>

    <!-- Dialogs for Request -->
    <LeaveRequestDialog v-if="addDialog" v-model="addDialog" @closeDialog="addDialog = false"
      :maxAccrual="GetAccruedDays?.max_accrual" :leaveDaysUsed="GetAccruedDays?.leaveDaysUsed"
      :leaveDaysAccrued="GetAccruedDays?.leaveDaysAccrued" :leaveDaysCarryOver="GetAccruedDays?.carry_over_days"
      :listLeavesHolidays="ListLeavesHolidays" :editLeaveRequest="editMode" :handleScheduler="scheduleMode"
      :editLeaveRequestId="leave.id" :editLeaveRequestDescription="leave.description"
      :editLeaveRequestDocument="leave.document" :editLeaveRequestLeaveType="leave.leave_type"
      :editLeaveRequestFirstLastDaysStart="leave.first_last_days_start"
      :editLeaveRequestFirstLastDaysEnd="leave.first_last_days_end" :editLeaveRequestManagerId="leave.manager_id"
      :leaveDaysCanNegate="GetAccruedDays?.can_negate" :tableData="ListLeavesBookedTableData"
      :ListLeavesBookedTimeOff="ListLeavesBookedTimeOffTableData" :employee-id="store?.user?.user_id?.toString()" />

    <!-- Dialogs for employee planner -->
    <LeaveEmplyeePlannerDialog v-model="employeePlannerDialog" @closeDialog="employeePlannerDialog = false"
      :employee-id="store?.user?.user_id?.toString()" v-if="employeePlannerDialog" />

    <!-- Dialogs for employee calculator -->
    <LeaveCalculatorDialog v-model="calculatorDialog" @closeDialog="calculatorDialog = false"
      :employee-id="store?.user?.user_id?.toString()" v-if="calculatorDialog" />

    <!-- Archive dialog -->
    <GDialog v-model="archiveFormDialog" max-width="29.688rem">
      <ArchiveDialog v-if="leave.status === RequestStatus.PENDING" @close-dialog="archiveFormDialog = false"
        :component-title="archiveDialogTitle" :deleteBtn="archiveDialogDeleteBtn" @delete="archive"
        :delete-message="archiveDialogDeleteMessage" />
    </GDialog>
    <AlertComponent :message-block="messageBlock" />
  </div>
</template>

<script setup lang="ts">
// Vue imports
import { reactive, ref, computed, onMounted, watchEffect, onBeforeMount } from "vue";
import { useStore } from "@/store";
import router from "@/router";
import { useAccessStore } from "@/apps/leave-management/store/store_leaves_permissions";
import { setToken, decodeToken } from "@/services/auth";

// Interfaces
import { LeaveHoliday } from "@/apps/leave-management/interfaces/leave-configuration/LeaveHolidays";
import {
  LeaveRequest,
  EditLeaveRequest,
} from "@/apps/leave-management/interfaces/book-leaves/LeaveRequests";

// Helper functions
import {
  formatDateShort,
  RequestStatus,
  filterByApproved,
  SelectedFilter,
} from "../../helpers/leaveHelperFunctions";
import { removeActionHeader } from "@/apps/leave-management/store/permissionFunctions";


// SVG Component Imports
import CloseCircle from "@/apps/leave-management/assets/svgComponents/CloseCircle.vue";
import ToolTipIcon from "@/apps/leave-management/assets/svgComponents/ToolTipIcon.vue";
import TooltipComponent from "@/ui-kit/Tooltip/TooltipComponent.vue";
import EditIcon from "@/assets/EditSvg.vue";
import TrashIcon from "@/assets/TrashSvg.vue";
import AnnualLeaveIcon from "../../assets/svgComponents/AnnualLeaveIcon.vue";
import MaternityLeaveIcon from "../../assets/svgComponents/MaternityLeaveIcon.vue";
import SickLeaveIcon from "../../assets/svgComponents/SickLeaveIcon.vue";
import BereaveMentLeaveIcon from "../../assets/svgComponents/BereaveMentLeaveIcon.vue";
import LeaveCalculator from "../../assets/svgComponents/LeaveCalculator.vue";
import DownArrow from "@/apps/leave-management/assets/svgComponents/DownArrow.vue";
import UpArrow from "@/apps/leave-management/assets/svgComponents/UpArrow.vue";
import ButtonSkeleton from "@/ui-kit/loaderSkeletons/ButtonSkeleton.vue";

// UI Component Imports
import LeaveRequestDialog from "../../reusables/book-leaves/LeaveRequestDialog.vue";
import LeaveCalculatorDialog from "../../reusables/book-leaves/LeaveCalculator.vue";
import ArchiveDialog from "@/ui-kit/DeleteDialog.vue";
import SelectFieldValue from "@/ui-kit/Inputs/SelectField.vue";
import ButtonComponent from "@/ui-kit/button/ButtonComponent.vue";
import LoaderIconVue from "@/ui-kit/loader/LoaderIcon.vue";
import TableComponentVue from "@/ui-kit/table/TableComponent.vue";
import AlertComponent from "@/ui-kit/AlertComponent.vue";
import OnboardingCardComponent from "@/components/OnboardingCardComponent.vue";
import SearchComponent from "@/components/SearchComponent.vue";
import DatePicker from 'vue-tailwind-datepicker';
import DropdownMenu from "@/components/DropdownMenu.vue";
import FilterComponent from "@/components/FilterComponent.vue";
import LeaveEmplyeePlannerDialog from "../../reusables/book-leaves/EmployeePlanner.vue";

// Apollo Imports
import { useQuery, useMutation, useQueryLoading, UseQueryReturn } from "@vue/apollo-composable";

// GraphQL Mutations and Queries
import {
  LIST_LEAVE_REQUEST,
  UPCOMING_LIST_LEAVE_REQUEST, GET_ACCRUED_DAYS, GETUSER_ACCESS_LEVEL, LIST_HOLIDAYS, GETEMPLOYEE_LOCATION_BYID
} from "@/apps/leave-management/graphql/querries/book-leaves/getLeaveRequests";
import { SOFT_DELETE_LEAVE_REQUEST } from "@/apps/leave-management/graphql/mutations/book-leaves/setLeaveRequests";

// Constants
const store = useStore();

// Reactive Variables
const Access = useAccessStore();
const bookLeavePerm = ref(Access.permissions?.settings?.leave_management);

const formatter = ref({
  date: 'DD MMM YYYY',
  month: 'MMM'
});

Access.$subscribe((_, state) => {
  bookLeavePerm.value = state.permissions?.settings?.leave_management;
});

const urlQuery = router.currentRoute.value.query;
const appToken = urlQuery["app-token"];
const loginHint = urlQuery["login-hint"];
const countryName = ref("");
const searchedQuery = ref("");

onBeforeMount(() => {
  if (appToken) {
    setToken(appToken as string);
    store.setUser(decodeToken());
    store.setLoginHint(loginHint as string);
    router.replace({ path: "/" });

    const UserAccess = useQuery(GETUSER_ACCESS_LEVEL, {
      userId: store.user.user_id?.toString(),
    });
    UserAccess.onResult((result) => {
      const newPermissions = result.data?.getUserLeaveAccessLevel?.access_level?.permissions;
      Access.setPermission(newPermissions);
    });
  }
});

const showOnboarding = ref(true);
const setLocal = () => {
  showOnboarding.value = false;
};

const headers = [
  { key: "days", label: "Days" },
  { key: "leave_type", label: "Leave type" },
  { key: "days_off", label: "Duration" },
  { key: "status", label: "Status" },
  { key: "action", label: "Actions" },
];

const addDialog = ref(false);
const editMode = ref(false);
const scheduleMode = ref(false);
const showFilters = ref(false);
const archiveFormDialog = ref(false);
const selectedFilter = ref<SelectedFilter>(SelectedFilter.ALL);
const ListLeavesBooked = ref<LeaveRequest[]>([]);
const ListLeavesBookedTimeOff = ref<LeaveRequest[]>([]);
const ListLeavesHolidays = ref<LeaveHoliday[]>([]);
const calculatorDialog = ref(false);
const employeePlannerDialog = ref(false);
const messageBlock = reactive({ open: false, severity: "", message: "" });
const queryLoading = useQueryLoading();
const publicHolidayTitle = ref("");
const isHolidaysVisible = ref(false);

const leave: LeaveRequest = reactive<LeaveRequest>({
  id: "",
  created_by: "",
  is_hr_approved: false,
  leave_type_id: "",
  leave_type: "",
  selectedDate: {
    startDate: "",
    endDate: "",
  },
  description: "",
  document: "",
  file: "",
  dates: [],
  days: "",
  duration: 0,
  carry_over_used: 0,
  first_last_days_start: "",
  first_last_days_end: "",
  first_last_days: [],
  days_off: 0,
  status: "",
  comments: "",
  employee_detail: "",
  manager_approved: "",
  manager_id: "",
  is_archived: false,
  can_negate: false,
  touch_point_comments: [],
});

// computed properties
const displayedHeaders = computed(() =>
  removeActionHeader(headers, true)
);

const computedDropdownMenu = (item: LeaveRequest) => {
  const status = item.status;
  const allowVisible =
    status === RequestStatus.APPROVED || status === RequestStatus.DENIED
      ? false
      : true;

  const options = [];

  if (allowVisible) {
    options.push({
      id: 1,
      name: "Edit",
      label: "Edit",
      icon: EditIcon,
      fill: "#696F8C",
      allowAccess: true,
    });

    options.push({
      id: 2,
      name: "Remove",
      label: "Remove",
      icon: TrashIcon,
      fill: "#696F8C",
      allowAccess: true,
    });
  }

  return options;
};

// combined two tables
const selectedDate = ref({
  startDate: '',
  endDate: ''
});
const CombinedLeavesTableData = computed(() => {
  const leavesBookedData = ListLeavesBookedTableData.value;
  const leavesBookedTimeOffData = ListLeavesBookedTimeOffTableData.value;

  const sortedLeavesBookedTimeOffData = leavesBookedTimeOffData.slice().sort((a, b) => {
    //
  });
  const combinedData = [...sortedLeavesBookedTimeOffData, ...leavesBookedData];

  // filter by date
  if (selectedDate.value.startDate && selectedDate.value.endDate) {
    const startDate = new Date(selectedDate.value.startDate);
    const endDate = new Date(selectedDate.value.endDate);

    let filteredLeaveRequests = combinedData.filter((leave) => {
      const leaveStartDate = new Date(leave.first_last_days[0]);
      const leaveEndDate = new Date(leave.first_last_days[1]);

      return leaveStartDate >= startDate && leaveEndDate <= endDate;
    });

    return filteredLeaveRequests;
  } else {
    return combinedData;

  }
});

// history table
const ListLeavesBookedTableData = computed(() => {
  if (searchedQuery.value.trim() !== "") {
    const filteredList = ListLeavesBooked?.value?.filter(
      (item: { leave_type: string }) => {
        if (item?.leave_type) {
          return item.leave_type
            .toLowerCase()
            .includes(searchedQuery.value.toLowerCase());
        }
        return false;
      }
    );

    return filteredList || [];
  } else {
    const filteredData = filterByApproved(
      ListLeavesBooked.value as LeaveRequest[],
      selectedFilter.value
    );

    return filteredData.slice();
  }
});

// time off table
const ListLeavesBookedTimeOffTableData = computed(() => {
  if (searchedQuery.value.trim() !== "") {
    const filteredList = ListLeavesBookedTimeOff?.value?.filter(
      (item: { leave_type: string }) => {
        if (item && item.leave_type) {
          return item.leave_type
            .toLowerCase()
            .includes(searchedQuery.value.toLowerCase());
        }
        return false;
      }
    );

    return filteredList || [];
  } else {
    const filteredData = filterByApproved(
      ListLeavesBookedTimeOff.value as LeaveRequest[],
      selectedFilter.value
    );

    return filteredData.slice();
  }
});

onBeforeMount(() => {
  async function fetchData() {
    try {
      const { result: queryResult } = useQuery(GETEMPLOYEE_LOCATION_BYID, {
        userId: store.user.user_id?.toString(),
      });

      watchEffect(() => {
        if (queryResult.value?.getEmployeeActiveInfo?.user?.employee_info?.organization?.address?.country) {

          const country = queryResult.value.getEmployeeActiveInfo.user.employee_info.organization.address.country;

          if (country && Object.keys(country).length > 0) {
            countryName.value = country;
            localStorage.setItem('countryName', country);
          }
        }
      });
    } catch (error) {
      //
    }
  }
  countryName.value = localStorage.getItem('countryName') ?? '';
  fetchData();

  const leaveBookedTimeOff = useQuery(UPCOMING_LIST_LEAVE_REQUEST, {
    userId: store.user.user_id?.toString(),
  });
  leaveBookedTimeOff.onResult((result) => {
    if (result.data?.getUpcomingTimeOff) {
      ListLeavesBookedTimeOff.value = result.data.getUpcomingTimeOff;
    }
  });

  const leaveBooked = useQuery(LIST_LEAVE_REQUEST, {
    userId: store.user.user_id?.toString(),
  });
  leaveBooked.onResult((result) => {
    if (result.data?.getLeaveHistoryByUserId) {
      ListLeavesBooked.value = result.data.getLeaveHistoryByUserId;
    }
  });
  leaveBooked.refetch();
  leaveBookedTimeOff.refetch();
});

const filteredLeaveTypeCounts = computed<LeaveTypeCount[]>(() => {
  const includedLeaveTypes = [
    "Bereavement Leave",
    "Maternity Leave",
    "Sick Leave",
  ];
  return GetAccruedDays.value.leaveTypeCounts.filter((leaveTypeCount) =>
    includedLeaveTypes.includes(leaveTypeCount.leave_type.title)
  );
});

const showCarryOver = computed(() => GetAccruedDays.value.carry_over_days > 0);

let leaveHolidays: UseQueryReturn<LeaveHoliday, Record<string, never>>;
onMounted(() => {
  leaveHolidays = useQuery(LIST_HOLIDAYS);

  leaveHolidays.onResult((result) => {
    if (result.data?.listHolidays) {
      const currentYear = new Date().getFullYear();
      const filteredHolidays = result.data.listHolidays.filter(
        (holiday: { is_archived: boolean; country: string; start_day: Date }) =>
          !holiday.is_archived &&
          holiday.country === localStorage.getItem('countryName') &&
          new Date(holiday.start_day).getFullYear() === currentYear
      );
      filteredHolidays.sort(
        (
          a: { start_day: string | number | Date },
          b: { start_day: string | number | Date }
        ) => {
          const dateA = new Date(a.start_day);
          const dateB = new Date(b.start_day);

          return (
            dateA.getMonth() - dateB.getMonth() ||
            dateA.getDate() - dateB.getDate()
          );
        }
      );
      ListLeavesHolidays.value = filteredHolidays;
    }
  });
  leaveHolidays.refetch();
});

// functions
const togglePublicHolidays = () => {
  isHolidaysVisible.value = !isHolidaysVisible.value;
  if (isHolidaysVisible.value) {
    if (leaveHolidays && ListLeavesHolidays.value.length === 0) {
      leaveHolidays.refetch();
    }
  }
};
const fetchPublicHolidays = () => {
  if (leaveHolidays && ListLeavesHolidays.value.length === 0) {
    leaveHolidays.refetch();
  }
};

const toggleFilters = () => {
  showFilters.value = !showFilters.value;
};

const getSpanTitle = ([firstDay]: [Date, Date]): "Upcoming timeoff" | "History" => {
  const currentDate = new Date();
  const startDate = new Date(firstDay);

  return startDate > currentDate ? "Upcoming timeoff" : "History";
};

function searchTableData(searchString: string) {
  searchedQuery.value = searchString;
}

const getLeaveTypeIcon = (leaveTypeTitle: string) => {
  switch (leaveTypeTitle) {
    case "Sick Leave":
      return SickLeaveIcon;
    case "Maternity Leave":
      return MaternityLeaveIcon;
    case "Bereavement Leave":
      return BereaveMentLeaveIcon;
    default:
      return null;
  }
};

const handleSelectOption = (item: EditLeaveRequest, name: string) => {
  if (name === "Edit") {
    editLeaveRequest(item);
  } else if (name === "Remove") {
    handleArchiveClick(item);
  }
};

const editLeaveRequest = (item: EditLeaveRequest) => {
  leave.id = item.id;
  leave.description = item.touch_point_comments?.[0]?.message;
  leave.document = item.file;
  leave.leave_type = item.leave_type;
  leave.first_last_days_start = item.first_last_days[0];
  leave.first_last_days_end = item.first_last_days[1];
  leave.manager_id = item.manager_id;
  editMode.value = true;
  addDialog.value = true;
  scheduleMode.value = false;
};

const hasTouchPointComments = (item: LeaveRequest) => {
  return item["touch_point_comments"]?.[0]?.message.length > 0;
};

const statusClass = (item: LeaveRequest) => {
  const { status, manager_approved } = item;

  if (status === RequestStatus.APPROVED) {
    return 'text-[#10899e] bg-[#D3F5F7]';
  } else if (status === RequestStatus.PENDING && manager_approved === RequestStatus.PENDING) {
    return 'text-[#b48429] bg-[#fcefd6]';
  } else if (status === RequestStatus.DENIED) {
    return 'text-[#b42f2f] bg-[#f7c5c5]';
  } else if (manager_approved === RequestStatus.APPROVED && status !== RequestStatus.DENIED) {
    return 'text-[#138fa5] bg-[#D3F5F7]';
  } else if (manager_approved === RequestStatus.DENIED && status !== RequestStatus.APPROVED) {
    return 'text-[#ab2b2b] bg-[#f7c5c5]';
  } else if (manager_approved === RequestStatus.PENDING) {
    return 'text-[#0e8ba1] bg-[#d1f6f8]';
  } else {
    return '';
  }
};

const getStatusText = (item: LeaveRequest) => {
  const { status, manager_approved } = item;

  if (status === RequestStatus.APPROVED) {
    return 'Approved by HR';
  } else if (status === RequestStatus.DENIED) {
    return 'Disapproved by HR';
  } else if (manager_approved === RequestStatus.APPROVED && status !== RequestStatus.DENIED) {
    return 'Approved by manager';
  } else if (manager_approved === RequestStatus.DENIED && status !== RequestStatus.APPROVED) {
    return 'Disapproved by manager';
  } else if (status === RequestStatus.PENDING && manager_approved === RequestStatus.PENDING) {
    return 'Pending approval';
  } else {
    return '';
  }
};

const isLastItem = (slotProps: { displayedItems: number; index: number }) => {
  const { displayedItems, index } = slotProps;
  return displayedItems > 3 ? [displayedItems - 1, displayedItems - 2, displayedItems - 3].includes(index) : [displayedItems - 1, displayedItems - 2].includes(index);
};

const showActionButtons = (item: LeaveRequest) => {
  const { status, manager_approved } = item;
  return status !== RequestStatus.APPROVED && status !== RequestStatus.DENIED && manager_approved !== RequestStatus.APPROVED && manager_approved !== RequestStatus.DENIED;
};

// Mutations & Queries
const GetAccruedDays = ref({
  leaveDaysAccrued: 0,
  leaveDaysUsed: 0,
  max_accrual: 0,
  carry_over_days: 0,
  can_negate: false,
  leaveTypeCounts: [] as LeaveTypeCount[],
});

type LeaveTypeCount = {
  leave_type: {
    title: string;
  };
  days_off: number;
};

onMounted(() => {
  const GetAccruedDaysByUserId = useQuery(GET_ACCRUED_DAYS, {
    userId: store.user.user_id?.toString(),
    fetchPolicy: "network-only",
  });

  GetAccruedDaysByUserId.onResult((result) => {
    if (result.data?.getAccruedDaysByUserId) {
      const accruedData = result.data.getAccruedDaysByUserId;
      GetAccruedDays.value.leaveDaysAccrued = accruedData.totalAccumulated;
      GetAccruedDays.value.leaveDaysUsed = accruedData.activeAccrual?.leaveDaysUsed;
      GetAccruedDays.value.max_accrual = accruedData.activeAccrual?.max_accrual;
      GetAccruedDays.value.carry_over_days = accruedData.carry_over_days || 0;
      GetAccruedDays.value.can_negate = accruedData.active_policy?.can_negate || false;
      GetAccruedDays.value.leaveTypeCounts = accruedData.type_count || [];
    }
    if (result.data?.getAccruedDaysByUserId === null || result.data?.getAccruedDaysByUserId === undefined || result.data?.getAccruedDaysByUserId.length === 0) {
      GetAccruedDaysByUserId.refetch();
    }
  });

  GetAccruedDaysByUserId.refetch();
});

onMounted(() => {
  const currentYear = new Date().getFullYear();
  publicHolidayTitle.value = `${currentYear} AmaliTech Holidays`;
});

const archiveDialogTitle = ref("");
const archiveDialogDeleteBtn = ref("");
const archiveDialogDeleteMessage = ref("");

const handleArchiveClick = (item: EditLeaveRequest) => {
  leave.id = item.id;
  leave.leave_type = item.leave_type;
  leave.status = item.status;
  leave.first_last_days_start = item.first_last_days[0];
  leave.first_last_days_end = item.first_last_days[1];

  if (leave.status === RequestStatus.PENDING) {
    archiveFormDialog.value = true;
    archiveDialogTitle.value = "Confirmation";
    archiveDialogDeleteBtn.value = "Remove";
    archiveDialogDeleteMessage.value = `Do you really want to remove ${leave.leave_type
      } from ${formatDateShort(
        leave.first_last_days_start
      )} to ${formatDateShort(
        leave.first_last_days_end
      )}?`;
  }
};

const archive_leave_request = useMutation(SOFT_DELETE_LEAVE_REQUEST, () => ({
  variables: {
    deleteBookedLeaveId: leave.id,
    isArchived: true,
  },
}));

const archive = () => {
  archive_leave_request.mutate();
};

const { refetch: refetchGetLeaveAccruedData } = useQuery(GET_ACCRUED_DAYS, {
  userId: store.user.user_id?.toString(),
});

archive_leave_request.onDone(() => {
  ListLeavesBooked.value = ListLeavesBooked.value.filter(
    (item) => item.id !== leave.id
  );
  ListLeavesBookedTimeOff.value = ListLeavesBookedTimeOff.value.filter(
    (item) => item.id !== leave.id
  );
  messageBlock.open = true;
  messageBlock.severity = "success";
  archiveFormDialog.value = false;
  messageBlock.message = "Leave request removed successfully";
  refetchGetLeaveAccruedData();
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});

archive_leave_request.onError((error) => {
  messageBlock.open = true;
  archiveFormDialog.value = false;
  messageBlock.severity = "error";
  messageBlock.message =
    error.message.length <= 100 ? error.message : "Please contact support";
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});
</script>

<style scoped>
.lg\:gap-0 {
  align-items: flex-start;
}

.justify-between {
  justify-content: flex-start;
}

@media only screen and (min-device-width: 1024px) and (min-resolution: 168dpi) {
  .flexCol {
    flex-direction: column;
  }
}
</style>