import gql from 'graphql-tag';

export const GET_ALL_LEAVE_TYPES = gql`
  query ListLeaveTypes {
    listLeaveTypes {
      id
      title
      description
      organization_id
      is_archived
      is_annual_default
      organization {
        id
        name
      }
    }
  }
`;

export const GET_ASSIGNED_LEAVE_POLICIES = gql`
  query Query($leaveTypeId: ID!) {
    checkForAssignedLeavePolicies(leave_type_id: $leaveTypeId)
  }
`;

export const GET_ORGANISATIONS = gql`
  query GetAllLeavesOrganizations {
    getAllLeavesOrganizations {
      id
      name
    }
  }
`;
