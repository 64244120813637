import gql from "graphql-tag";

export const LIST_HOLIDAYS = gql`
  query ListHolidays {
    listHolidays {
      id
      title
      description
      start_day
      end_day
      is_archived
      country
    }
  }
`;

export const GETEMPLOYEE_LOCATION_BYID = gql`
query Employee_contacts($userId: ID!) {
  getEmployeeActiveInfo(user_id: $userId) {
    user {
      employee_info {
        organization {
          address {
            country
          }
        }
      }
    }
  }
}
`;
