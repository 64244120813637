import gql from 'graphql-tag';

export const LIST_LEAVE_REQUEST = gql`
  query GetLeaveHistoryByUserId($userId: ID!) {
    getLeaveHistoryByUserId(user_id: $userId) {
      id
      status
      days_off
      carry_over_used
      user_id
      manager_id
      days
      first_last_days
      leave_type
      manager_approved
      touch_point_comments {
        message
      }
    }
  }
`;

export const UPCOMING_LIST_LEAVE_REQUEST = gql`
  query GetUpcomingTimeOff($userId: ID!) {
    getUpcomingTimeOff(user_id: $userId) {
      id
      status
      days_off
      carry_over_used
      user_id
      manager_id
      days
      first_last_days
      leave_type
      manager_approved
      touch_point_comments {
        id
        message
      }
    }
  }
`;

export const GET_ACCRUED_DAYS = gql`
  query GetAccruedDaysByUserId($userId: ID!) {
    getAccruedDaysByUserId(user_id: $userId) {
      totalAccumulated
      activeAccrual {
        leaveDaysAccrued
        leaveDaysUsed
        max_accrual
      }
      carry_over_days
      active_policy {
        can_negate
      }
      type_count {
        leave_type {
          title
        }
        days_off
      }
    }
  }
`;

export const EMPLOYEE_LEAVE_CALCULATOR = gql`
  query Query($date: DateTime!, $userId: ID!) {
    leaveCalculator(date: $date, user_id: $userId)
  }
`;

export const GETUSER_ACCESS_LEVEL = gql`
  query GetUserLeaveAccessLevel($userId: ID!) {
    getUserLeaveAccessLevel(user_id: $userId) {
      access_level {
        permissions
      }
    }
  }
`;

export const LIST_HOLIDAYS = gql`
  query ListHolidays {
    listHolidays {
      title
      start_day
      country
    }
  }
`;

export const GET_ALL_LEAVE_TYPES = gql`
  query ListLeaveTypes {
    listLeaveTypes {
      id
      title
    }
  }
`;

export const GETEMPLOYEE_LOCATION_BYID = gql`
query Employee_contacts($userId: ID!) {
  getEmployeeActiveInfo(user_id: $userId) {
    user {
      employee_info {
        organization {
          address {
            country
          }
        }
      }
    }
  }
}
`;