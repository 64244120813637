<template>
  <template v-if="!env.VUE_APP_LEAVE_POLICY && leavesSettingsPerm.view_leave_policy">
    <!-- ONBOARDING CARD -->
    <OnboardingCardComponent v-if="tableData.length === 0 && showOnboarding" class="z-10" @btncall="setLocal"
      title="Welcome to Leave Policies"
      content="On this page you can easily manage leave policies for your organisation. Click on add a leave policy to get started now!" />
    <div>
      <!-- Message Alert -->
      <div v-if="tableData.length === 0 && showAlertMessage && leavesSettingsPerm.view_leave_policy" class="
          mt-4
          flex
          border-2 border-solid border-[#E6E8F0]
          rounded-lg
          bg-[#FAFBFF]
          gap-x-[0.69rem]
          py-2
          p-[0.69rem]
          justify-between
          items-center
        ">
        <div class="flex items-center gap-x-4">
          <div class="">
            <InfoClose />
          </div>
          <p class="text-base N700">
            To include a custom Leave Type at a specific Location, access the
            'Assign Leave Policy' section. Select the Leave Type you've created
            under the 'Leave Types' tab and adjust the Leave Policy settings.
          </p>
        </div>
        <span @click="showAlertMessage = false" type="button">
          <CloseCircle />
        </span>
      </div>

      <!-- Leave policy section -->
      <div class="
          flex flex-col
          items-center
          justify-between
          gap-5
          my-2
          lg:flex-row lg:gap-0
        ">
        <div class="flex items-center gap-x-2">
          <FilterComponent data-cy="filterButton" @click="toggleFilters" />
          <SearchComponent data-cy="search" class="py-2" @search="searchTableData" />
        </div>
        <div v-if="leavesSettingsPerm.manage_leave_policy">
          <ButtonComponent buttonLabel="Add a Leave Policy" variant="primary"
            @submit="[(editMode = false), (addDialog = true)]" data-cy="addLeavePolicy" class="shadow-md" />
        </div>
      </div>

      <div v-show="showFilters" class="flex gap-2 items-center mb-3">
        <p class="N900">Filter</p>
        <div class="holidaySelect">
          <SelectFieldValue :showSearch="false" :showLabel="false" id="status" data-cy="filterField"
            placeholder="Filter" :options="['Unarchived', 'Archived']" v-model="selectedFilter" />
        </div>
      </div>

      <TableComponentVue :overflowXAuto="false" :headers="displayedHeaders" :items="tableData" :itemsPerPage="9"
        emptyMessage="You haven't created any leave policy for your organisation">
        <template #title="slotProps">
          <span @click="editLeavePolicy(slotProps.item)" class="flex items-center gap-2 pt-3 pb-3 cursor-pointer">
            {{ (slotProps.item as LeavePolicy)["title"] }}
            <span class="relative group" v-if="(slotProps.item as LeavePolicy)['description']">
              <ToolTipIcon />
              <div class="
                  w-80
                  max-h-32
                  bg-opacity-90 bg-[#101840]
                  z-10
                  N0
                  text-sm
                  rounded
                  p-2
                  absolute
                  left-full
                  hidden
                  group-hover:block
                  overflow-hidden
                  whitespace-normal
                  -top-8
                ">
                {{ (slotProps.item as LeavePolicy)["description"] }}
              </div>
            </span>
          </span>
        </template>
        <template #max_accrual="slotProps">
          <span class="flex items-center">
            {{ (slotProps.item as LeavePolicy)["max_accrual"] }}
          </span>
        </template>
        <template #carry_over="slotProps">
          <span class="flex items-center">
            {{ (slotProps.item as LeavePolicy)["carry_over"] }}
          </span>
        </template>
        <template #action="slotProps">
          <div class="flex justify-start">
            <DropdownMenu :isLastItem="slotProps.displayedItems > 3 ? slotProps.displayedItems - 1 === slotProps.index ||
              slotProps.displayedItems - 2 === slotProps.index ||
              slotProps.displayedItems - 3 === slotProps.index :
              slotProps.displayedItems - 1 === slotProps.index ||
              slotProps.displayedItems - 2 === slotProps.index" :bottom-class="'-mt-[14rem] absolute z-50'"
              data-cy="actions" @selectOption="(name: string) => handleSelectOption(slotProps.item, name)" :optionsList="[
                {
                  id: 1,
                  name: 'Edit',
                  label: 'Edit',
                  icon: EditIcon,
                  fill: '#696F8C',
                  allowAccess: true,
                },
                {
                  id: 2,
                  name: slotProps.item.is_archived ? 'Unarchive' : 'Archive',
                  label: slotProps.item.is_archived ? 'Unarchive' : 'Archive',
                  icon: ArchiveSvg,
                  fill: '#696F8C',
                  allowAccess: true,
                },
                {
                  id: 3,
                  name: 'Remove',
                  label: 'Remove',
                  icon: TrashIcon,
                  fill: '#696F8C',
                  allowAccess: true,
                },
              ]" />
          </div>
        </template>
      </TableComponentVue>

      <!-- Loading state -->
      <div v-if="queryLoading && ListPolicies.length === 0">
        <LoaderIconVue />
      </div>

      <!-- Dialog for edit, add, and archive -->
      <GDialog v-model="addDialog" max-width="40rem" persistent>
        <div class="p-4">
          <div class="flex items-center justify-between">
            <h3 class="H600 N900 dark:text-">
              {{
                editMode
                  ? "Edit a leave policy"
                  : "Add a leave policy"
              }}
            </h3>
            <CloseCircle @click="close()" />
          </div>

          <div class="py-2">
            <div>
              <InputFieldValue data-cy="name" label="Name" placeholder="Name" :requireTag="true"
                v-model="leave.title" />
            </div>
            <div>
              <MultiLineInputBox data-cy="description" label="Description" :showlength="true" :maxlength="200"
                :requireTag="false" placeholder="Description" v-model="leave.description" />
            </div>
            <div class="flex flex-row items-center gap-2 w-20">
              <CheckBoxValue data="checkbox" id="toggleId" topLabel v-model="leave.can_negate" />
              <span title="Negative balance" class="mb-1">Negative&nbsp;balance</span>
              <span class="relative cursor-pointer group">
                <ToolTipIcon />
                <div class="
                      w-[200px]
                      max-h-32
                      bg-opacity-90 bg-[#101840]
                      N0
                      text-sm
                      rounded
                      p-2
                      absolute
                      -top-8
                      left-full
                      hidden
                      group-hover:block
                    ">
                  Allow users to exceed their Leave limit.
                </div>
              </span>
            </div>

            <div class="mt-2">
              <InputFieldValue data-cy="annualLeaveAllocation" label="Annual leave allocation"
                placeholder="Enter amount" type="number" :requireTag="true" v-model="leave.max_accrual" />
            </div>
            <div class="flex flex-col gap-2 mt-7">
              <div class="flex flex-col gap-2 md:flex-row md:gap-2">
                <InputFieldValue data-cy="carroverAmount" label="Carryover" placeholder="Enter amount" type="number"
                  :requireTag="true" v-model="leave.carry_over" />
                <InputFieldValue data-cy="carryoverExpiry" label="Expiry date" type="date" :requireTag="true"
                  :showlength="false" v-model="leave.carry_over_expiry" />
              </div>
            </div>
          </div>

          <div class="relative py-4">
            <RoundedTop class="absolute top-[0.15rem] left-[16.5rem] BG0 cursor-pointer" />
            <div class="py-2 border-t border-gray-100">
              <div v-if="editMode" class="flex items-center justify-between gap-2 py-4">
                <span data-cy="assignItem" v-if="editMode" @click="removeAssignments" class="O400 cursor-pointer">
                  Unassign position
                </span>
              </div>


              <div class="flex flex-row items-center justify-between">
                <template v-if="editMode">
                  <SelectFieldValue :showSearch="true" :showLabel="true" id="positions" data-cy="positonSingleSelect"
                    label="Position" v-if="selectedAssignment === 'positions'" placeholder="Choose a position"
                    :requireTag="true" :options="positions.map((type) => type.name)" v-model="leave.position_id"
                    class="w-full" />
                </template>
                <template v-else>
                  <div class="flex flex-col w-full">

                    <Multiselect data-cy="positonMultiselect" v-if="selectedAssignment === 'positions'"
                      :options="positions" v-model="leave.position_id" :multiple="true" :closeOnSelect="false"
                      :blockKeys="['Delete']" :groupSelect="true" :clearOnSelect="false" :preserveSearch="true"
                      :hideSelected="true" :taggable="true" :param="'name'" :trackBy="'id'" :label="'Positions'"
                      :placeholder="'Choose a position'" class="w-full" :requireTag="true" />
                  </div>
                </template>
              </div>
              <div class="pt-2">
                <SelectFieldValue :showSearch="false" :showLabel="true" :isAbsolute="false" id="leavetypes"
                  data-cy="leaveType" label="Leave type" placeholder="Choose a leave type" :requireTag="true"
                  :options="leavetypes.map((type) => type.title)" v-model="selectedLeaveType" />
              </div>
            </div>
          </div>
          <!-- Form actions -->
          <div class="flex justify-end gap-4 pt-2">
            <ButtonComponent button-label="Cancel" :variant="'secondary'" @click.stop="close" dataCy="cancel">
            </ButtonComponent>
            <ButtonComponent @click="updateLeavePolicy.mutate()" data-cy="updateLeavePolicy" v-if="editMode" :disabled="leave.title.trim().length < 1 ||
              selectedLeaveType.trim().length < 1 ||
              leave.carry_over_expiry.trim().length < 1 ||
              leave.carry_over.trim().length < 1 ||
              leave.max_accrual.trim().length < 1
              " :loading="loading">Update</ButtonComponent>
            <ButtonComponent data-cy="saveLeavePolicy" v-else @click="() => addLeavePolicy.mutate()" :disabled="leave.title.trim().length < 1 ||
              selectedLeaveType.trim().length < 1 ||
              leave.carry_over_expiry.trim().length < 1 ||
              leave.carry_over.trim().length < 1 ||
              leave.max_accrual.trim().length < 1 ||
              leave.position_id.length < 1
              " :loading="loading">Save</ButtonComponent>
          </div>
        </div>
      </GDialog>

      <!-- Archive dialog -->
      <GDialog v-model="archiveFormDialog" max-width="29.688rem">
        <ArchiveDialog v-if="leave.is_archived === false" @close-dialog="archiveFormDialog = false"
          :component-title="archiveDialogTitle" :deleteBtn="archiveDialogDeleteBtn" @delete="archive"
          :delete-message="archiveDialogDeleteMessage" />
        <ArchiveDialog v-else @close-dialog="archiveFormDialog = false" :component-title="unarchiveDialogTitle"
          :deleteBtn="unarchiveDialogDeleteBtn" @delete="unarchive" :delete-message="unarchiveDialogDeleteMessage" />
      </GDialog>

      <!-- Remove dialog -->
      <GDialog v-model="removeFormDialog" max-width="29.688rem">
        <ArchiveDialog @close-dialog="removeFormDialog = false" :component-title="removeDialogTitle"
          :deleteBtn="removeDialogDeleteBtn" @delete="remove" :delete-message="removeDialogDeleteMessage" />
      </GDialog>
    </div>

    <AlertComponent :message-block="messageBlock" />
  </template>
  <div v-else-if="env.VUE_APP_LEAVE_POLICY">
    <VersionDisplay class="" />
  </div>
  <div class="flex flex-col" v-else>
    <NotAuthorised class="" />
  </div>
</template>

<script setup lang="ts">
// Vue Imports
import {
  reactive,
  ref,
  computed,
  watchEffect,
  watch
} from "vue";

// Interfaces
import { LeavePolicy } from "../../interfaces/leave-configuration/LeavePolicies";

// SVG Component Imports
import CloseCircle from "../../assets/svgComponents/CloseCircle.vue";
import ToolTipIcon from "../../assets/svgComponents/ToolTipIcon.vue";
import EditIcon from "@/assets/EditSvg.vue";
import TrashIcon from "@/assets/TrashSvg.vue";
import ArchiveSvg from "@/assets/ArchiveSvg.vue";
import InfoClose from "../../assets/svgComponents/InfoClose.vue";

// UI Component Imports
import ArchiveDialog from "@/ui-kit/DeleteDialog.vue";
import MultiLineInputBox from "@/ui-kit/Inputs/TextArea.vue";
import Multiselect from "@/ui-kit/Inputs/MultiSelectInput.vue";
import CheckBoxValue from "@/ui-kit/button/FlexColToggleButton.vue";
import SelectFieldValue from "@/ui-kit/Inputs/SelectField.vue";
import InputFieldValue from "@/ui-kit/Inputs/InputField.vue";
import ButtonComponent from "@/ui-kit/button/ButtonComponent.vue";
import LoaderIconVue from "@/ui-kit/loader/LoaderIcon.vue";
import TableComponentVue from "@/ui-kit/table/TableComponent.vue";
import AlertComponent from "@/ui-kit/AlertComponent.vue";
import VersionDisplay from "@/components/HelloWorld.vue";
import OnboardingCardComponent from "@/components/OnboardingCardComponent.vue";
import SearchComponent from "@/components/SearchComponent.vue";
import DropdownMenu from "@/components/DropdownMenu.vue";
import FilterComponent from "@/components/FilterComponent.vue";
import RoundedTop from "../../assets/svgComponents/RoundedTop.vue";

// Apollo Imports
import {
  useQuery,
  useMutation,
  useMutationLoading,
  useQueryLoading,
} from "@vue/apollo-composable";

// GraphQL Query & Mutation Imports
import {
  CREATE_LEAVE_POLICY,
  UPDATE_LEAVE_POLICY,
  TOGGLE_POLICY_STATUS,
  REMOVE_LEAVE_POLICY_STATUS,
} from "../../graphql/mutations/leave-configuration/setPolicies";
import {
  LIST_LEAVE_POLICIES, ALL_POSITIONS
} from "../../graphql/querries/leave-configuration/getPoilicies";
import { GET_ALL_LEAVE_TYPES } from "../../graphql/querries/book-leaves/getLeaveRequests";

import { useAccessStore } from "@/apps/leave-management/store/store_leaves_permissions";
import NotAuthorised from "@/components/NotAuthorized.vue";
import { removeActionHeader } from "@/apps/leave-management/store/permissionFunctions";

// Constants
const env = process.env;

// Reactive Variables
const Access = useAccessStore();
const permissions = Access.permissions?.settings?.leave_management;
const leavesSettingsPerm = permissions ?? {};
const selectedAssignment = ref("positions");
const searchedQuery = ref("");

let showOnboarding = ref(true);
const setLocal = () => {
  showOnboarding.value = false;
};

const headers = [
  { key: "title", label: "Name" },
  { key: "max_accrual", label: "Annual Leave Allocation" },
  { key: "carry_over", label: "Carry Over" },
  { key: "action", label: "Actions" },
];

let addDialog = ref(false);
let editMode = ref(false);
const showFilters = ref(false);
let ListPolicies = ref<LeavePolicy[]>([]);
let archiveFormDialog = ref(false);
let removeFormDialog = ref(false);
let selectedFilter = ref("Unarchived");
let selectedAccrualRate = ref("");
const selectedLeaveType = ref("");
const selectedAnEmployee = ref("");
let showAlertMessage = ref(true);

const messageBlock = reactive({ open: false, severity: "", message: "" });
const loading = useMutationLoading();
let queryLoading = useQueryLoading();

const leave: LeavePolicy = reactive<LeavePolicy>({
  id: "",
  title: "",
  description: "",
  position_id: [{ id: "", name: "" }],
  max_accrual: "",
  start_delay: "",
  delay_range: "",
  can_negate: false,
  leave_type_id: "",
  approval_required: false,
  accrual_rate: "",
  carry_over: "",
  carry_over_expiry: "",
  is_archived: false,
});

// functions
watch(selectedAssignment, () => {
  if (!editMode.value) {
    leave.position_id = [];
    selectedAnEmployee.value = "";
  }
});

const removeAssignments = () => {
  leave.position_id = [];
  selectedAnEmployee.value = "";
};

const close = () => {
  leave.id = "";
  leave.title = "";
  leave.description = "";
  leave.position_id = [];
  leave.max_accrual = "";
  leave.start_delay = "";
  leave.delay_range = "";
  leave.can_negate = false;
  leave.leave_type_id = "";
  leave.accrual_rate = "";
  leave.carry_over = "";
  leave.carry_over_expiry = "";
  leave.approval_required = false;
  selectedAccrualRate.value = "";
  selectedLeaveType.value = "";
  selectedAnEmployee.value = "";
  editMode.value = false;
  addDialog.value = false;
};

watchEffect(() => {
  if (!addDialog.value) {
    close();
  }
});

const toggleFilters = () => {
  showFilters.value = !showFilters.value;
};

function searchTableData(searchString: string) {
  searchedQuery.value = searchString;
}


const editLeavePolicy = (item: LeavePolicy) => {
  leave.id = item.id;
  leave.title = item.title;
  leave.description = item.description;
  leave.position_id = positions.value.find(
    (position) => position.id === item.position_id
  )?.name || "";
  leave.max_accrual = item.max_accrual;
  leave.accrual_rate = item.accrual_rate;
  leave.can_negate = item.can_negate;
  selectedLeaveType.value =
    leavetypes.value.find((leaveType) => leaveType.id === item.leave_type_id)
      ?.title || "";
  leave.carry_over = item.carry_over;
  leave.carry_over_expiry = new Date(item.carry_over_expiry)
    .toISOString()
    .split("T")[0];
  editMode.value = true;
  addDialog.value = true;
};

const handleSelectOption = (item: LeavePolicy, name: string) => {
  if (name === "Edit") {
    editLeavePolicy(item);
  } else if (name === "Archive" || name === "Unarchive") {
    handleArchiveClick(item);
  } else if (name === "Remove") {
    handleRemoveClick(item);
  }
};

//computed properties
const displayedHeaders = computed(() =>
  removeActionHeader(headers, leavesSettingsPerm.manage_leave_policy)
);

const tableData = computed(() => {
  if (searchedQuery.value.trim() !== "") {
    return ListPolicies?.value?.filter((item: { title: string }) => {
      return (
        item?.title.toLowerCase().indexOf(searchedQuery.value.toLowerCase()) !=
        -1
      );
    });
  } else {
    let filteredData = (ListPolicies.value as LeavePolicy[]).filter((item) => {
      if (selectedFilter.value === "Archived") {
        return item.is_archived;
      } else if (selectedFilter.value === "Unarchived") {
        return !item.is_archived;
      }
    });

    filteredData.sort((a, b) => {
      const titleA = a.title.toLowerCase();
      const titleB = b.title.toLowerCase();
      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }
      return 0;
    });

    return filteredData.slice();
  }
});

const positions = computed<{ id: string; name: string }[]>(() => {
  if (positionsResult.value && positionsResult.value.getAllLeavesPositions) {
    return positionsResult.value.getAllLeavesPositions.map(
      (item: { id: string; position_name: string }) => ({
        id: item.id,
        name: item.position_name,
      })
    );
  } else {
    return [];
  }
});

const leavetypes = computed<{ id: string; title: string }[]>(() => {
  if (leaveTypesResult.value && leaveTypesResult.value.listLeaveTypes) {
    return leaveTypesResult.value.listLeaveTypes
      .filter(
        (item: { id: string; title: string; is_archived: boolean }) =>
          !item.is_archived
      )
      .map((item: { id: string; title: string }) => ({
        id: item.id,
        title: item.title,
      }));
  } else {
    return [];
  }
});

// Mutations and Queries
const { result: positionsResult } = useQuery(ALL_POSITIONS);
const { result: leaveTypesResult } = useQuery(GET_ALL_LEAVE_TYPES);
const LeavePolicies = useQuery(LIST_LEAVE_POLICIES, selectedFilter);

LeavePolicies.onResult((result) => {
  if (result.data && result.data.listLeavePolicies) {
    ListPolicies.value = result.data.listLeavePolicies;
  }
});

const addLeavePolicy = useMutation(CREATE_LEAVE_POLICY, () => ({
  variables: {
    data: {
      title: leave.title,
      description: leave.description,
      can_negate: leave.can_negate === true,
      max_accrual: leave.max_accrual,
      leave_type_id:
        leavetypes.value.find((type) => type.title === selectedLeaveType.value)
          ?.id || "",
      carry_over: leave.carry_over,
      carry_over_expiry: leave.carry_over_expiry,
      position_id: leave.position_id
        ? leave.position_id.map((position) => position.id)
        : null,
    },
  },
}));

const { refetch: refetchLeavesPolicies } = useQuery(LIST_LEAVE_POLICIES);
addLeavePolicy.onDone(() => {
  refetchLeavesPolicies();
  close();
  messageBlock.open = true;
  messageBlock.severity = "success";
  messageBlock.message = "Leave policy added successfully";
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});

addLeavePolicy.onError((error) => {
  messageBlock.open = true;
  messageBlock.severity = "error";
  messageBlock.message =
    error.message.length <= 100
      ? error.message
      : "Leave policy creation failed";
  close();
  setTimeout(() => {
    messageBlock.open = false;
  }, 10000);
});

const updateLeavePolicy = useMutation(UPDATE_LEAVE_POLICY, () => ({
  variables: {
    id: leave.id,
    data: {
      id: leave.id,
      title: leave.title,
      description: leave.description,
      can_negate: leave.can_negate === true,
      max_accrual: leave.max_accrual,
      leave_type_id: selectedLeaveType.value
        ? leavetypes.value.find(
          (leaveType) => leaveType.title === selectedLeaveType.value
        )?.id
        : null,
      carry_over: leave.carry_over,
      carry_over_expiry: leave.carry_over_expiry,
      position_id: leave.position_id
        ? positions.value.find(
          (type) => type.name === leave.position_id
        )?.id
        : null,
    },
  },
}));

updateLeavePolicy.onDone(() => {
  refetchLeavesPolicies();
  messageBlock.open = true;
  messageBlock.severity = "success";
  close();
  messageBlock.message = "Leave policy updated successfully";
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});

updateLeavePolicy.onError((error) => {
  messageBlock.open = true;
  addDialog.value = false;
  messageBlock.severity = "error";
  messageBlock.message =
    error.message.length <= 100 ? error.message : "Please contact support";
  close();
  setTimeout(() => {
    messageBlock.open = false;
  }, 10000);
});

const removeDialogTitle = ref("");
const removeDialogDeleteBtn = ref("");
const removeDialogDeleteMessage = ref("");

const handleRemoveClick = async (item: LeavePolicy) => {
  leave.id = item.id;
  leave.title = item.title;
  leave.is_archived = item.is_archived;

  removeFormDialog.value = true;
  removeDialogTitle.value = "Remove Leave Policy";
  removeDialogDeleteMessage.value = `Do you really want to remove ${leave.title}?`;
  removeDialogDeleteBtn.value = "Remove";
};

// REMOVE LEAVE FUNCTION
const remove_leave_type = useMutation(REMOVE_LEAVE_POLICY_STATUS, () => ({
  variables: {
    deleteLeavePolicyId: leave.id,
  },
}));

const remove = () => {
  remove_leave_type.mutate();
};

remove_leave_type.onDone(() => {
  refetchLeavesPolicies();
  messageBlock.open = true;
  messageBlock.severity = "success";
  removeFormDialog.value = false;
  messageBlock.message = "Leave policy removed successfully";
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});

remove_leave_type.onError((error) => {
  messageBlock.open = true;
  removeFormDialog.value = false;
  messageBlock.severity = "error";
  messageBlock.message =
    error.message.length <= 100 ? error.message : "Please contact support";
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});

const archiveDialogTitle = ref("");
const archiveDialogDeleteBtn = ref("");
const archiveDialogDeleteMessage = ref("");
const unarchiveDialogTitle = ref("");
const unarchiveDialogDeleteBtn = ref("");
const unarchiveDialogDeleteMessage = ref("");

const handleArchiveClick = (item: LeavePolicy) => {
  leave.id = item.id;
  leave.title = item.title;
  leave.is_archived = item.is_archived;

  if (item.is_archived === false) {
    archiveFormDialog.value = true;
    archiveDialogTitle.value = "Archive Leave Policy";
    archiveDialogDeleteBtn.value = "Archive";
    archiveDialogDeleteMessage.value = `Do you really want to archive ${leave.title}?`;
  } else if (leave.is_archived === true) {
    archiveFormDialog.value = true;
    unarchiveDialogTitle.value = "Unarchive Leave Policy";
    unarchiveDialogDeleteBtn.value = "Unarchive";
    unarchiveDialogDeleteMessage.value = `Are you sure you want to unarchive ${leave.title}?`;
  }
};

const archive_leave_policy = useMutation(TOGGLE_POLICY_STATUS, () => ({
  variables: {
    archiveLeavePolicyId: leave.id,
    isArchived: true,
  },
}));

const archive = () => {
  archive_leave_policy.mutate();
};

archive_leave_policy.onDone(() => {
  messageBlock.open = true;
  messageBlock.severity = "success";
  archiveFormDialog.value = false;
  messageBlock.message = "Leave policy archived successfully";
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});

archive_leave_policy.onError((error) => {
  messageBlock.open = true;
  archiveFormDialog.value = false;
  messageBlock.severity = "error";
  messageBlock.message =
    error.message.length <= 100 ? error.message : "Please contact support";
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});

const unarchive_leave_policy = useMutation(TOGGLE_POLICY_STATUS, () => ({
  variables: {
    archiveLeavePolicyId: leave.id,
    isArchived: false,
  },
}));

const unarchive = () => {
  unarchive_leave_policy.mutate();
};

unarchive_leave_policy.onDone(() => {
  messageBlock.open = true;
  messageBlock.severity = "success";
  archiveFormDialog.value = false;
  messageBlock.message = "Leave policy unarchived successfully";
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});

unarchive_leave_policy.onError((error) => {
  messageBlock.open = true;
  archiveFormDialog.value = false;
  messageBlock.severity = "error";
  messageBlock.message =
    error.message.length <= 100 ? error.message : "Please contact support";
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});
</script>
