import gql from 'graphql-tag';

export const CREATE_ACCESS_LEVEL = gql`
  mutation CreateLeaveAccessLevel($data: createLeaveAccessLevelInput) {
    createLeaveAccessLevel(data: $data) {
      id
    }
  }
`;

export const UPDATE_ACCESS_LEVEL = gql`
  mutation UpdateLeaveAccessLevel($data: updateLeaveAccessLevelInput) {
    updateLeaveAccessLevel(data: $data) {
      id
    }
  }
`;

export const DELETE_ACCESS_LEVEL = gql`
  mutation DeleteLeaveAccessLevel($accessLevelId: ID!) {
    deleteLeaveAccessLevel(access_level_id: $accessLevelId) {
      id
    }
  }
`;

export const ARCHIVE_ACCESS_LEVEL = gql`
  mutation ArchiveLeaveAccessLevel($accessLevelId: ID!) {
    archiveLeaveAccessLevel(access_level_id: $accessLevelId) {
      id
      is_archived
      name
    }
  }
`;

export const DELETE_USERS_FROM_ACCESS_LEVEL = gql`
  mutation UnassignUsersWithLeaveAccessLevel($users: [ID!]) {
    unassignUsersWithLeaveAccessLevel(users: $users) {
      id
    }
  }
`;
