import gql from 'graphql-tag';

export const APPROVE_HR_LEAVE_REQUEST = gql`
  mutation AddHrLeaveApproval($data: addLeaveApprovalInput!) {
    addHrLeaveApproval(data: $data) {
      id
      status
      days_off
      user_id
      manager_id
      manager_approved
      days
      file
    }
  }
`;

export const APPROVE_HR_APPROVERS = gql`
  mutation SetLeaveApprovers($data: setLeaveApproversInput!) {
    setLeaveApprovers(data: $data) {
      id
      organization_id
      description
      organization {
        id
      }
      user_id
      user {
        id
      }
    }
  }
`;

export const EDIT_HR_APPROVERS = gql`
  mutation editLeaveApprovers($data: editLeaveApproversInput!) {
    editLeaveApprovers(data: $data) {
      id
      description
      organization_id
      organization {
        id
      }
      user_id
      user {
        id
      }
    }
  }
`;

export const REMOVE_HR_APPROVERS = gql`
  mutation RemoveLeaveApprover($approverId: ID!) {
    removeLeaveApprover(approver_id: $approverId) {
      id
      organization_id
      organization {
        id
      }
      user_id
      user {
        id
      }
    }
  }
`;

export const ADD_OR_SUBTRACT_LEAVE_DAYS = gql`
  mutation AddOrSubtractLeaveDays($data: addOrSubtractLeaveDaysInput!) {
    addOrSubtractLeaveDays(data: $data) {
      id
      user_id
      accrued_days
      carry_over_days
      annual_days_used
      leave_policy_id
      hr_addition
      hr_deduction
      on_leave
      updated_at
      is_carryover_executed
    }
  }
`;
