import moment from 'moment';
import { decodeUrlPath } from './utils';

export const getUserNameInitials = (name: string): string => {
  let words = name?.trim().split(' ');
  words = words?.length > 2 ? words.slice(0, 2) : words;
  return words?.map((word) => word[0]).join('') || '';
};

export const forMatNumbers = (number: string): string => {
  if (!number) return '';
  const numericOnly = number.trim().replace(/\D/g, '');
  if (numericOnly.startsWith('233')) {
    return `+233 ${numericOnly.substring(3, 6)} ${numericOnly.substring(6)}`;
  }
  if (numericOnly?.startsWith('0')) {
    return `+233 ${numericOnly.substring(1, 3)} ${numericOnly.substring(
      3,
      6
    )} ${numericOnly.substring(6)}`;
  }
  if (!number) return '';
  return `+233 ${numericOnly.substring(0, 2)} ${numericOnly.substring(
    2,
    6
  )} ${numericOnly.substring(6)}`;
};

export function getUserId(path: string) {
  const paths = path.split('=@');
  if (paths.length > 1) {
    return paths[1];
  }

  return decodeUrlPath(path);
}

//date Formating
export function formatDate(date: string) {
  return new Date(date).toISOString().split('T').splice(0, 1).join('') || '';
}

// time Formatting
export function formatTime(date: string) {
  const time = new Date(date).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });
  return time || '';
}

export const formattedDate = (item: string, isUnix?: boolean) => {
  const dateObj = isUnix ? moment(parseInt(item)) : moment(item);
  const formattedDate = dateObj.format('Do MMMM YYYY');
  return formattedDate || '';
};

export function formattedTime(date: string, isUnix?: boolean) {
  const dateObj = isUnix ? moment(parseInt(date)) : moment(date);
  const formattedTime = dateObj.format('hh:mm A');
  return formattedTime || '';
}

export function numberToWords(num: number): string {
  if (num === 0) {
    return 'Zero';
  }
  const units = [
    '',
    'One',
    'Two',
    'Three',
    'Four',
    'Five',
    'Six',
    'Seven',
    'Eight',
    'Nine'
  ];
  const teens = [
    'Ten',
    'Eleven',
    'Twelve',
    'Thirteen',
    'Fourteen',
    'Fifteen',
    'Sixteen',
    'Seventeen',
    'Eighteen',
    'Nineteen'
  ];
  const tens = [
    '',
    '',
    'Twenty',
    'Thirty',
    'Forty',
    'Fifty',
    'Sixty',
    'Seventy',
    'Eighty',
    'Ninety'
  ];
  function convertLessThanThousand(num: number) {
    let result = '';
    if (num >= 100) {
      result += units[Math.floor(num / 100)] + ' Hundred';
      num %= 100;
      if (num > 0) {
        result += ' and ';
      }
    }
    if (num >= 20) {
      result += tens[Math.floor(num / 10)];
      num %= 10;
      if (num > 0) {
        result += '-';
      }
    }
    if (num > 0) {
      if (num < 10) {
        result += units[num];
      } else {
        result += teens[num - 10];
      }
    }
    return result;
  }
  if (num < 0) {
    return 'Negative ' + numberToWords(-num);
  }
  let result = '';
  const billion = Math.floor(num / 1_000_000_000);
  const million = Math.floor((num % 1_000_000_000) / 1_000_000);
  const thousand = Math.floor((num % 1_000_000) / 1_000);
  const remainder = num % 1_000;
  if (billion > 0) {
    result += convertLessThanThousand(billion) + ' Billion';
    if (million > 0 || thousand > 0 || remainder > 0) {
      result += ', ';
    }
  }
  if (million > 0) {
    result += convertLessThanThousand(million) + ' Million';
    if (thousand > 0 || remainder > 0) {
      result += ', ';
    }
  }
  if (thousand > 0) {
    result += convertLessThanThousand(thousand) + ' Thousand';
    if (remainder > 0) {
      result += ', ';
    }
  }
  if (remainder > 0) {
    result += convertLessThanThousand(remainder);
  }
  return result || '';
}
