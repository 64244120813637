<template>
  <template v-if="!env.VUE_APP_LEAVE_MANAGER_APPROVAL &&
    leavesPerm.view_manager_leave_planner
    ">
    <div v-if="openManagerPlanner">
      <div class="container">
        <BreadCrumbsComponent :breadcrumbs="leaveBreadCrumbs" />
      </div>

      <div class="px-5 py-4 pb-8 mx-4 rounded-md BG0">
        <!-- Filtering -->
        <div class="flex items-center justify-between gap-5 px-2 py-2 w-fit">
          <FilterComponent data-cy="filterButton" @click="toggleFilters" class="mr-2" />
          <DatePicker :requireTag="true" v-model="selectedDate" separator=" to " :formatter="formatter"
            style="height: 42px" />
          <SearchComponent data-cy="search" @search="searchTableData" value="searchTermLower" />
        </div>

        <div v-show="showFilters" class="flex items-center gap-2 mb-3 ml-12">
          <p class="N900">Filter</p>
          <div class="w-40 holidaySelect">
            <SelectFieldValue :show-search="true" :show-label="false" id="department" data-cy="filterField"
              placeholder="Departments" :options="departments" />
          </div>
        </div>

        <!-- Leave Planner Table-->
        <div class="container px-2">
          <TableComponentVue :overflowXAuto="true" :headers="headers" :headerBgColor="headers.map((header) => ({
    bgcolor: header.bgcolor || 'bg-gray-50'
  }))
    " :items="searchData.length ? searchData : filteredLeaves" :itemsPerPage="30" :customScrollbar="true"
            emptyMessage="No planner requests">
            <template #employee_name="slotProps">
              <div class="flex items-center mr-4">
                <div class="w-72"><span :title="slotProps.item.name" class="flex items-center gap-2 py-3 truncate">
                    <ProfileImageComponent :initials="getUserNameInitials(slotProps.item.name)" :imgSrc="''"
                      widthSize="3rem" heightSize="3rem" text-size="text-2xl" />
                    {{ slotProps.item.name }}
                  </span></div>
                <div v-for="day in slotProps.item.days" :key="day.formattedDate" class="flex">
                  <span class="relative cursor-pointer group left-8">
                    <div :class="{
    'bg-[#D3F5F7]': day.leave_type === 'Annual Leave',
    'bg-[#f4c4c4]': day.leave_type === 'Sick Leave',
    'bg-[#fcefd6]': day.leave_type === 'Bereavement Leave',
    'bg-[#f0d0f7]': day.leave_type === 'Compensatory Leave',
    'bg-[#d6d0f7]': day.leave_type === 'Maternity Leave',
    'bg-[#ddf7d0]': day.leave_type === 'Paternity Leave',
    'bg-[#f2f2f2]': ![
      'Annual Leave',
      'Sick Leave',
      'Bereavement Leave',
      'Annual holiday',
      'Compensatory Leave',
      'Maternity Leave',
      'Paternity Leave'
    ].includes(day.leave_type)
  }" class="
                        flex
                        items-center
                        justify-center
                        cursor-point
                        h-[40px]
                        w-[65px]
                        rounded
                        cursor-grabbing
                      " :title="slotProps.item.name">
                      {{ day.formattedDate }}
                      <span class="
                          absolute
                          h-[30px]
                          right-0
                          border-r-2 border-gray-200
                        "></span>
                      <div class="
                          w-fit
                          max-h-62
                          bg-opacity-90 bg-[#101840]
                          text-white text-md
                          rounded
                          p-2
                          absolute
                          -top-20
                          left-full
                          hidden
                          group-hover:block
                          z-10
                          text-left
                        ">
                        <span class="text-[#52BD94]">Leave Details</span>
                        <p>{{ day.leave_type }}</p>
                        <p>
                          Leave Start Date
                          {{
    formatDateCorrectly(
      day.leave_request.first_last_days[0]
    )
  }}
                        </p>
                        <p>
                          Leave Stop Date
                          {{
      formatDateCorrectly(
        day.leave_request.first_last_days[1]
      )
    }}
                        </p>
                        <p>Days Taken {{ day.leave_request.days_off }}</p>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </template>

            <template #annual_leave="slotProps">
              <span :title="slotProps.item.name">{{
    getTotalLeaveUsed(slotProps.item, 'Annual Leave')
  }}</span>
            </template>

            <template #sick_leave="slotProps">
              <span :title="slotProps.item.name">{{
    getTotalLeaveUsed(slotProps.item, 'Sick Leave')
  }}</span>
            </template>

            <template #maternity_leave="slotProps">
              <span :title="slotProps.item.name">{{
    getTotalLeaveUsed(slotProps.item, 'Maternity Leave')
  }}</span>
            </template>

            <template #paternity_leave="slotProps">
              <span :title="slotProps.item.name">{{
    getTotalLeaveUsed(slotProps.item, 'Paternity Leave')
  }}</span>
            </template>

            <template #bereavement_leave="slotProps">
              <span :title="slotProps.item.name">{{
                getTotalLeaveUsed(slotProps.item, 'Bereavement Leave')
                }}</span>
            </template>
          </TableComponentVue>

          <!-- Table insights lists -->
          <div class="flex flex-row justify-end gap-3 mt-3">
            <div class="
                pl-3
                pr-3
                lg:rounded-lg lg:right-[44rem]
                bg-[#ddf7d0]
                users-row
              ">
              <span class="text-[1.3rem]">&#8226;</span> Paternity
            </div>
            <div class="
                pl-3
                pr-3
                lg:rounded-lg lg:right-[36rem]
                bg-[#d6d0f7]
                users-row
              ">
              <span class="text-[1.3rem]">&#8226;</span> Maternity
            </div>
            <div class="
                pl-3
                pr-3
                lg:rounded-lg lg:right-[27rem]
                bg-[#D3F5F7]
                users-row
              ">
              <span class="text-[1.3rem]">&#8226;</span> Annual Day
            </div>
            <div class="
                pl-3
                pr-3
                lg:rounded-lg lg:right-[17rem]
                bg-[#fcefd6]
                users-row
              ">
              <span class="text-[1.3rem]">&#8226;</span> Bereavement
            </div>
            <div class="
                pl-3
                pr-3
                lg:rounded-lg lg:right-[9rem]
                bg-[#f4c4c4]
                users-row
              ">
              <span class="text-[1.3rem]">&#8226;</span> Sick Day
            </div>
            <div class="
                pl-3
                pr-3
                lg:rounded-lg lg:right-[2rem]
                bg-[#f2f2f2]
                users-row
              ">
              <span class="text-[1.3rem]">&#8226;</span> On-site
            </div>
          </div>
        </div>

        <!-- Loading state -->
        <div v-if="queryLoading && ListLeaves.length === 0">
          <LoaderIconVue />
        </div>
      </div>
    </div>
    <div v-if="openManagerPlanner === false">
      <LoaderIconVue :showLeaveService="false" />
    </div>
  </template>

  <!-- Empty session state -->
  <div v-else-if="env.VUE_APP_LEAVE_MANAGER_APPROVAL">
    <VersionDisplay class="" />
  </div>
  <div class="flex flex-col" v-else>
    <NotAuthorised class="" />
  </div>
</template>

<script setup lang="ts">
// Vue Imports
import { ref, onMounted, computed } from 'vue';
import { useStore } from '@/store';

// helper functions
import { getUserNameInitials } from '@/helpers/functions';
import {
  formatDateCorrectly,
  getLeaveBetweenDates
} from '../../helpers/leaveHelperFunctions';

// Interfaces
import {
  LeaveManagerPlanner,
  Department
} from '../../interfaces/manager-dashboard/LeaveManagerApprovals';

// UI Component Imports
import BreadCrumbsComponent from '@/ui-kit/bread-crumbs/BreadCrumbsComponent.vue';
import VersionDisplay from '@/components/HelloWorld.vue';
import DatePicker from 'vue-tailwind-datepicker';
import SearchComponent from '@/components/SearchComponent.vue';
import ProfileImageComponent from '@/components/ProfileImageComponent.vue';
import LoaderIconVue from '@/ui-kit/loader/LoaderIcon.vue';
import FilterComponent from '@/components/FilterComponent.vue';
import SelectFieldValue from '@/ui-kit/Inputs/SelectField.vue';
import TableComponentVue from '@/ui-kit/table/TableComponent.vue';

// Apollo Imports
import { useQuery, useQueryLoading } from '@vue/apollo-composable';
import {
  GET_MANAGER_PLANNER,
  ALL_DEPARTMENTS
} from '../../graphql/querries/manager-dashboard/getManagerApprovals';
import { useAccessStore } from '@/apps/leave-management/store/store_leaves_permissions';
import NotAuthorised from '@/components/NotAuthorized.vue';

// Constants
const env = process.env;
const store = useStore();

// Reactive Variables
const Access = useAccessStore();
const permissions = Access.permissions?.settings?.leave_management;
const leavesPerm = permissions ?? {};
let currentUserId = store.user.user_id;
const selectedDate = ref({
  startDate: '',
  endDate: ''
});
const showFilters = ref(false);
const openManagerPlanner = ref(false);
let queryLoading = useQueryLoading();
let searchData = ref<LeaveManagerPlanner[]>([]);
let searchTerm = ref('');

const formatter = ref({
  date: 'DD MMM YYYY',
  month: 'MMM'
});

const leaveBreadCrumbs = ref([
  { label: 'Home', to: '/home' },
  { label: 'Manager Dashboard', to: '/leave-manager-approval' },
  { label: 'Leave Planner', to: '/leave-manager-leave-planner' }
]);

const headers = [
  { key: 'employee_name', label: 'Employee name' },
  { key: 'annual_leave', label: 'Total Annual Days', bgcolor: 'bg-[#A3E6CD]' },
  { key: 'sick_leave', label: 'Total Sick Days', bgcolor: 'bg-[#F4B6B6]' },
  {
    key: 'maternity_leave',
    label: 'Total Maternity Days',
    bgcolor: 'bg-[#d6d0f7]'
  },
  {
    key: 'paternity_leave',
    label: 'Total Paternity Days',
    bgcolor: 'bg-[#ddf7d0]'
  },
  {
    key: 'bereavement_leave',
    label: 'Total Bearevement Days',
    bgcolor: 'bg-[#FFDFA6]'
  }
];

const table_heads: { name: string; days: string[] }[] = [];
const tableHeadStartDate = ref(new Date()); // Current date
const tableHeadEndDate = ref(new Date(tableHeadStartDate.value));
tableHeadEndDate.value.setFullYear(tableHeadStartDate.value.getFullYear() + 1); // One year from now

for (let i = 1; i <= 1; i++) {
  table_heads.push({ name: 'Employee name', days: [] });
}

onMounted(() => {
  setTimeout(() => {
    openManagerPlanner.value = true;
  }, 2000);
});

// functions
const toggleFilters = () => {
  showFilters.value = !showFilters.value;
};

const getTotalLeaveUsed = (
  user: LeaveManagerPlanner,
  leaveType: string
): number => {
  const total = user.leave_types_used.reduce((total, item) => {
    if (item.leave_type.title === leaveType) {
      return total + item.days_used;
    }
    return total;
  }, 0);

  return parseFloat(total.toFixed(2));
};

const searchTableData = (searchString: string) => {
  searchTerm.value = searchString.trim();

  const searchTermLower = searchTerm.value.trim().toLowerCase();

  searchData.value = ListLeaves.value.filter((user: LeaveManagerPlanner) => {
    const name = user.name.toLowerCase();

    return name.includes(searchTermLower);
  });
};

onMounted(() => {
  tableHeadGenerateDates();
});

const tableHeadGenerateDates = () => {
  while (tableHeadStartDate.value <= tableHeadEndDate.value) {
    for (let i = 0; i < table_heads.length; i++) {
      const oneusercurrentDate = new Date(tableHeadStartDate.value);
      const dayOfWeek = oneusercurrentDate.getDay();

      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const tableHeadDaysOfWeek = days[dayOfWeek];
        table_heads[i].days.push(tableHeadDaysOfWeek);
      }
    }
    tableHeadStartDate.value.setDate(tableHeadStartDate.value.getDate() + 1);
  }
};
tableHeadGenerateDates();

const departments = ref<Department[]>([]);
const listDepartments = useQuery(ALL_DEPARTMENTS);

listDepartments.onResult((result) => {
  if (result.data?.listDepartments) {
    departments.value = result.data.listDepartments.map(
      (department: Department) => department.department_name
    );
  }
});

const ListLeaves = ref<LeaveManagerPlanner[]>([]);
const startDate = ref(new Date());
const endDate = ref(new Date(startDate.value));
endDate.value.setFullYear(startDate.value.getFullYear() + 1);

const managerId = currentUserId?.toString();
const leaveTypes = useQuery(GET_MANAGER_PLANNER, {
  managerId
});

leaveTypes.onResult((result) => {
  if (result.data?.listManagerLeavePlanner) {
    ListLeaves.value = result.data.listManagerLeavePlanner.map(
      (request: {
        user: {
          first_name: string;
          last_name: string;
          user_id: string;
          project_id: string;
        };
        days: Array<{
          date: string;
          leave_type: string;
          leave_request: {
            days_off: number;
            first_last_days: string[];
          };
        }>;
        leave_types_used: Array<{
          days_used: number;
          leave_type: {
            title: string;
          };
        }>;
      }) => {
        const user = request?.user;
        const fullName = `${user?.first_name} ${user?.last_name}`;
        const days = request.days.map((day) => {
          const formattedDate = new Date(day.date).getDate();
          return {
            formattedDate: formattedDate?.toString(),
            leave_type: day?.leave_type,
            leave_request: {
              days_off: day?.leave_request?.days_off,
              first_last_days: day?.leave_request?.first_last_days
            }
          };
        });

        return {
          user_id: user?.user_id,
          name: fullName,
          days: days,
          project: user?.project_id,
          leave_types_used: request?.leave_types_used?.map((typesUsed) => ({
            days_used: typesUsed?.days_used,
            leave_type: {
              title: typesUsed?.leave_type.title
            }
          }))
        };
      }
    );
  }
});

const filteredLeaves = computed(() => {
  if (selectedDate.value.startDate && selectedDate.value.endDate) {
    const startDate = new Date(selectedDate.value.startDate);
    const endDate = new Date(selectedDate.value.endDate);

    return getLeaveBetweenDates(
      ListLeaves.value,
      startDate,
      endDate
    );
  } else {
    return ListLeaves.value;
  }
});
</script>
