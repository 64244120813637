<template>
    <div class="flex gap-20 items-center relative">
        <div class="flex flex-col w-[50vw]">
            <div class="w-full p-2 border rounded-lg cursor-pointer mb-4" v-for="(faqItem, index) in faqs" :key="index">
                <div class="flex items-center justify-between" :class="faqItem.showItems ? 'mb-4' : ''"
                    @click="toggleItems(index)">
                    <div class="flex gap-2 items-center">
                        <p class="text-base N800">{{ faqItem.header }}</p>
                    </div>
                    <ToggleArrow :is-open="faqItem.showItems" />
                </div>

                <slot v-if="faqItem.showItems">
                    <p class="text-base N600">{{ faqItem.response }}</p>
                </slot>
            </div>
        </div>
        <div>
            <HelpImageSvg />
        </div>
    </div>
</template>

<script setup lang="ts">
import ToggleArrow from "@/apps/leave-management/assets/svgComponents/ToggleArrow.vue";
import HelpImageSvg from "@/apps/leave-management/assets/svgComponents/HelpImageSvg.vue";
import { ref } from "vue";

interface FaqItem {
    header: string;
    response: string;
    showItems: boolean;
}

const faqs = ref<FaqItem[]>([
    { header: "How does the Leave Management Service work, and why is it necessary for me?", response: "All employees can book leaves and view their past bookings with the help of the Leave Management Service. Furthermore, it gives managers the ability to approve or deny leave requests. HR can also configure various settings like holidays, leave types, policies, and access levels.", showItems: true },
    { header: "How are carryover leaves used?", response: "The dashboard will display any carryover leaves that are applied on top of the annual leave balance. After March 31st of the year, your carryover expires.", showItems: false },
    { header: "Why can't I book different types of leaves?", response: "Employees are only able to book annual leave. For additional leave types, please contact human resources (HR).", showItems: false },
]);

const toggleItems = (index: number) => {
    faqs.value[index].showItems = !faqs.value[index].showItems;
};
</script>