<template>
    <GDialog v-model="hrEmployeeCsvExportDialog" max-width="80rem" height="fit-content"
        data-cy="hrEmployeeCsvExportDialog">
        <div class="py-4 px-4">
            <div class="flex items-center justify-between">
                <h3 class="H700 N900 relative" data-cy="dialogTitle">Employee Analysis <span
                        class="absolute text-xs top-2 -right-8 font-normal N600">CSV</span></h3>
                <CloseCircle @click="$emit('closeDialog'); resetForm();" data-cy="closeButton" />
            </div>

            <div class="flex items-center justify-between py-4">
                <p class="flex gap-2">
                    <TimeIconVue color="#696969" />{{ formatDateShort(startDate) }} to {{
                        formatDateShort(endDate) }}
                </p>
                <div class="flex items-center gap-x-2 p-2">
                    <SearchComponent data-cy="search" class="py-2" :clear="clear" @search="searchTableData" />
                    <ButtonComponent class="flex gap-2 items-center" v-if="leavesPerm?.manage_hr_employee_info"
                        title="Export employee analysis" data-cy="saveExportButton" @click="exportCsv();">
                        Export employee analysis
                        <ExportCsv color="#fff" />
                    </ButtonComponent>
                </div>
            </div>

            <div class="w-full lg:w-full rounded-lg">
                <TableComponentVue :overflowXAuto="true" :headers="headers" :items="filteredLeaveCsvOverview"
                    :itemsPerPage="10" :customScrollbar="true" emptyMessage="No employees data to export">
                    <template #employee_name="slotProps">
                        <span class="flex items-center cursor-pointer gap-2 py-3">
                            {{ slotProps.item?.user_name }}
                        </span>
                    </template>
                    <template #annual_used="slotProps">
                        <span class="flex items-center">
                            {{ slotProps.item?.annual_used }}
                        </span>
                    </template>

                    <template #sick_used="slotProps">
                        <span class="flex items-center">
                            {{ slotProps.item?.sick_used }}
                        </span>
                    </template>

                    <template #maternity_used="slotProps">
                        <span class="flex items-center">
                            {{ slotProps.item?.maternity_used }}
                        </span>
                    </template>

                    <template #bereavement_used="slotProps">
                        <span class="flex items-center">
                            {{ slotProps.item?.bereavement_used }}
                        </span>
                    </template>

                    <template #paternity_used="slotProps">
                        <span class="flex items-center">
                            {{ slotProps.item?.paternity_used }}
                        </span>
                    </template>

                    <template #compensatory_used="slotProps">
                        <span class="flex items-center">
                            {{ slotProps.item?.compensatory_used }}
                        </span>
                    </template>

                    <template #total_used="slotProps">
                        <span class="flex items-center">
                            {{ slotProps.item?.total_used }}
                        </span>
                    </template>
                </TableComponentVue>

                <div v-if="queryLoading">
                    <LoaderIconVue />
                </div>
                <AlertComponent :message-block="messageBlock" data-cy="leaveRequestAlert" />
            </div>
        </div>
    </GDialog>
</template>

<script setup lang="ts">
import { computed, defineProps, reactive, ref, toRefs, watch, watchEffect } from "vue";
import CloseCircle from "@/apps/leave-management/assets/svgComponents/CloseCircle.vue";
import TimeIconVue from "@/apps/leave-management/assets/svgComponents/TimeIcon.vue";
import ButtonComponent from "@/ui-kit/button/ButtonComponent.vue";
import SearchComponent from "@/components/SearchComponent.vue";
import { useQuery, useQueryLoading } from "@vue/apollo-composable";
import TableComponentVue from "@/ui-kit/table/TableComponent.vue";
import ExportCsv from "../../assets/svgComponents/ExportCsv.vue";
import LoaderIconVue from "@/ui-kit/loader/LoaderIcon.vue";
import { GET_EMPLOYEE_LEAVES_CSV } from "../../graphql/querries/hr-dashboard/getHrApprovals";
import { useAccessStore } from "@/apps/leave-management/store/store_leaves_permissions";
import AlertComponent from '@/ui-kit/AlertComponent.vue';
import {
    formatDateShort
} from "../../helpers/leaveHelperFunctions";
import { useStore } from "@/store";

// Constants
const store = useStore();

// Reactive Variables
const Access = useAccessStore();
const permissions = Access.permissions?.settings?.leave_management;
const leavesPerm = permissions ?? {};

const props = defineProps({
    startDate: String,
    endDate: String
});
const { startDate, endDate } = toRefs(props);

const messageBlock = reactive({ open: false, severity: '', message: '' });
const hrEmployeeCsvExportDialog = ref(false);
let queryLoading = useQueryLoading();
const ListLeaveCsvOverview = ref<
    { user_name: string; annual_used: number; sick_used: number; maternity_used: number; bereavement_used: number; paternity_used: number; compensatory_used: number; total_used: number }[]
>([]);
const searchQuery = ref('');
const clear = ref(false);

const headers = [
    { key: "employee_name", label: "Employee name" },
    { key: "annual_used", label: "Annual leave taken" },
    { key: "sick_used", label: "Sick leave taken" },
    { key: "maternity_used", label: "Maternity leave taken" },
    { key: "bereavement_used", label: "Bereavement leave taken" },
    { key: "paternity_used", label: "Paternity leave taken" },
    { key: "compensatory_used", label: "Compensatory leave taken" },
    { key: "total_used", label: "Total used" },
];

const resetForm = (): void => {
    searchQuery.value = '';
    clear.value = false;
};

watchEffect(() => {
    if (!hrEmployeeCsvExportDialog.value) {
        close();
        resetForm();
    }
});

const leaveCsvOverview = useQuery(GET_EMPLOYEE_LEAVES_CSV, {
    startDate: startDate?.value,
    endDate: endDate?.value,
    organizationId: store?.user?.organization,
});

watch([startDate, endDate], () => {
    leaveCsvOverview.refetch({
        startDate: startDate?.value,
        endDate: endDate?.value,
        organizationId: store?.user?.organization,
    });
});

const filteredLeaveCsvOverview = computed(() => {
    const query = searchQuery.value.trim().toLowerCase();
    if (ListLeaveCsvOverview.value && Array.isArray(ListLeaveCsvOverview.value)) {
        return ListLeaveCsvOverview.value.filter(entry =>
            entry && entry.user_name && typeof entry.user_name === 'string' &&
            entry.user_name.toLowerCase().includes(query)
        );
    } else {
        return [];
    }
});

const searchTableData = (searchString: string) => {
    searchQuery.value = searchString;
};

const formatDecimal = (value: number) => {
    if (typeof value === 'number' && !Number.isInteger(value)) {
        return value.toFixed(2);
    } else {
        return value.toString();
    }
};

leaveCsvOverview.onResult((result) => {
    if (result.data?.exportDaysUsedByUser) {
        const newData = [...result.data.exportDaysUsedByUser];
        ListLeaveCsvOverview.value = newData
            .filter(item => item.user_name !== null && item.user_name !== undefined && item.user_name !== '')
            .map(item => ({
                user_name: item.user_name,
                annual_used: formatDecimal(item.leave_types_used.find((leave: { leave_type: string; }) => leave.leave_type === 'Annual Leave')?.hours_used ?? 0),
                sick_used: formatDecimal(item.leave_types_used.find((leave: { leave_type: string; }) => leave.leave_type === 'Sick Leave')?.hours_used ?? 0),
                maternity_used: formatDecimal(item.leave_types_used.find((leave: { leave_type: string; }) => leave.leave_type === 'Maternity Leave')?.hours_used ?? 0),
                bereavement_used: formatDecimal(item.leave_types_used.find((leave: { leave_type: string; }) => leave.leave_type === 'Bereavement Leave')?.hours_used ?? 0),
                paternity_used: formatDecimal(item.leave_types_used.find((leave: { leave_type: string; }) => leave.leave_type === 'Paternity Leave')?.hours_used ?? 0),
                compensatory_used: formatDecimal(item.leave_types_used.find((leave: { leave_type: string; }) => leave.leave_type === 'Compensatory Leave')?.hours_used ?? 0),
                total_used: formatDecimal(calculateTotalUsed(item.leave_types_used))
            }));
    }
});

const exportCsv = () => {
    let csvContent = "Full Name";
    const leaveTypes = ['Annual leave taken', 'Sick leave taken', 'Maternity leave taken', 'Bereavement leave taken', 'Paternity leave taken', 'Compensatory leave taken', 'Total Used'];
    leaveTypes.forEach(leaveType => {
        csvContent += `,${leaveType}`;
    });
    csvContent += '\n';

    ListLeaveCsvOverview.value.forEach((entry) => {
        const { user_name, annual_used, sick_used, maternity_used, bereavement_used, paternity_used, compensatory_used, total_used } = entry;
        csvContent += `${user_name}`;
        [annual_used, sick_used, maternity_used, bereavement_used, paternity_used, compensatory_used, total_used].forEach(hoursUsed => {
            csvContent += `,${hoursUsed}`;
        });
        csvContent += '\n';
    });

    const fileName = `employee_analysis_${startDate?.value}_to_${endDate?.value}.csv`;
    const blob = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};


const calculateTotalUsed = (leaveTypesUsed: { leave_type: string; hours_used: number }[]) => {
    let total = 0;
    leaveTypesUsed.forEach(leave => {
        total += leave.hours_used;
    });
    return total;
};
</script>
