<template>
  <GDialog v-model="employeePlannerDialog" max-width="fit-content">
    <div class="container p-4">
      <div class="flex items-center justify-between mb-2">
        <h3 class="H600 N900">Leave Planner</h3>
        <CloseCircle @click="$emit('closeDialog')" />
      </div>

      <div class="py-5">
        <div class="flex items-center gap-10 w-fit">
          <InputFieldValue data-cy="datesInput" class="BG0" type="Date" :showlength="false" v-model="selectedDate" />
        </div>

        <div class="py-4">
          <TableComponentVue :overflowXAuto="true" :headers="headers"
            :headerBgColor="headers.map(header => ({ bgcolor: header.bgcolor || 'bg-gray-50' }))" :items="ListLeaves"
            :itemsPerPage="30" :customScrollbar="true" emptyMessage="No planner requests">
            <template #employee_name="slotProps">
              <div class="flex items-center mr-4">
                <span :title="slotProps.item.name" class="flex items-center gap-2 py-3">
                  <ProfileImageComponent :initials="getUserNameInitials(slotProps.item.name)" :imgSrc="''"
                    widthSize="3rem" heightSize="3rem" text-size="text-2xl" />
                  {{ slotProps.item.name }}
                </span>
                <div v-for="day in slotProps.item.days" :key="day.formattedDate" class="flex">
                  <span class="relative cursor-pointer group left-8">
                    <div :class="{
    'bg-[#D3F5F7]': day.leave_type === 'Annual Leave',
    'bg-[#f4c4c4]': day.leave_type === 'Sick Leave',
    'bg-[#fcefd6]': day.leave_type === 'Bereavement Leave',
    'bg-[#f0d0f7]': day.leave_type === 'Compensatory Leave',
    'bg-[#d6d0f7]': day.leave_type === 'Maternity Leave',
    'bg-[#ddf7d0]': day.leave_type === 'Paternity Leave',
    'bg-[#f2f2f2]': ![
      'Annual Leave',
      'Sick Leave',
      'Bereavement Leave',
      'Annual holiday',
      'Compensatory Leave',
      'Maternity Leave',
      'Paternity Leave'
    ].includes(day.leave_type)
  }" class="
                flex
                items-center
                justify-center
                cursor-point
                h-[40px]
                w-[65px]
                rounded
                cursor-grabbing
              " :title="slotProps.item.name">
                      {{ day.formattedDate }}
                      <span class="
                  absolute
                  h-[30px]
                  right-0
                  border-r-2 border-gray-200
                "></span>
                      <div class="
                  w-fit
                  max-h-62
                  bg-opacity-90 bg-[#101840]
                  text-white text-md
                  rounded
                  p-2
                  absolute
                  -top-20
                  left-full
                  hidden
                  group-hover:block
                  z-10
                  text-left
                ">
                        <span class="text-[#52BD94]">Leave Details</span>
                        <p>{{ day.leave_type }}</p>
                        <p>
                          Leave Start Date {{
    formatDateCorrectly(
      day.leave_request.first_last_days[0]
    )
  }}
                        </p>
                        <p>
                          Leave Stop Date {{
      formatDateCorrectly(
        day.leave_request.first_last_days[1]
      )
    }}
                        </p>
                        <p>Days Taken {{ day.leave_request.days_off }}</p>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </template>
            <template #annual_leave="slotProps">
              <span :title="slotProps.item.name">{{ getTotalLeaveUsed(slotProps.item, 'Annual Leave') }}</span>
            </template>
            <template #sick_leave="slotProps">
              <span :title="slotProps.item.name">{{ getTotalLeaveUsed(slotProps.item, 'Sick Leave') }}</span>
            </template>
            <template #maternity_leave="slotProps">
              <span :title="slotProps.item.name">{{ getTotalLeaveUsed(slotProps.item, 'Maternity Leave') }}</span>
            </template>
            <template #paternity_leave="slotProps">
              <span :title="slotProps.item.name">{{ getTotalLeaveUsed(slotProps.item, 'Paternity Leave') }}</span>
            </template>
            <template #bereavement_leave="slotProps">
              <span :title="slotProps.item.name">{{ getTotalLeaveUsed(slotProps.item, 'Bereavement Leave') }}</span>
            </template>
          </TableComponentVue>
        </div>
      </div>
    </div>
  </GDialog>
</template>

<script setup lang="ts">
// Vue Imports
import { ref, toRefs } from "vue";

// helper functions
import { getUserNameInitials } from "@/helpers/functions";
import { formatDateCorrectly } from "../../helpers/leaveHelperFunctions";

// Interfaces
import { LeaveManagerPlanner } from "../../interfaces/manager-dashboard/LeaveManagerApprovals";

// UI Component Imports
import ProfileImageComponent from "@/components/ProfileImageComponent.vue";
import CloseCircle from "@/apps/leave-management/assets/svgComponents/CloseCircle.vue";
import TableComponentVue from "@/ui-kit/table/TableComponent.vue";
import InputFieldValue from "@/ui-kit/Inputs/InputField.vue";

// Apollo Imports
import { useQuery } from "@vue/apollo-composable";
import { GET_EMPLOYEE_PLANNER } from "../../graphql/querries/manager-dashboard/getManagerApprovals";

const props = defineProps({
  employeeId: String,
});
const { employeeId } = toRefs(props);

const employeePlannerDialog = ref(false);
const selectedDate = ref(new Date().toISOString().split("T")[0]);

const table_heads: { name: string; days: string[] }[] = [];
const tableHeadStartDate = ref(new Date()); // Current date
const tableHeadEndDate = ref(new Date(tableHeadStartDate.value));
tableHeadEndDate.value.setFullYear(tableHeadStartDate.value.getFullYear() + 1); // One year from now

for (let i = 1; i <= 1; i++) {
  table_heads.push({ name: "Employee name", days: [] });
}

const tableHeadGenerateDates = () => {
  while (tableHeadStartDate.value <= tableHeadEndDate.value) {
    for (let i = 0; i < table_heads.length; i++) {
      const oneusercurrentDate = new Date(tableHeadStartDate.value);
      const dayOfWeek = oneusercurrentDate.getDay();

      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        const tableHeadDaysOfWeek = days[dayOfWeek];
        table_heads[i].days.push(tableHeadDaysOfWeek);
      }
    }
    tableHeadStartDate.value.setDate(tableHeadStartDate.value.getDate() + 1);
  }
};

tableHeadGenerateDates();

const headers = [
  { key: "employee_name", label: "Employee name" },
  { key: "annual_leave", label: "Total Annual Days", bgcolor: 'bg-[#A3E6CD]' },
  { key: "sick_leave", label: "Total Sick Days", bgcolor: 'bg-[#F4B6B6]' },
  { key: "maternity_leave", label: "Total Maternity Days", bgcolor: 'bg-[#d6d0f7]' },
  { key: "paternity_leave", label: "Total Paternity Days", bgcolor: 'bg-[#ddf7d0]' },
  { key: "bereavement_leave", label: "Total Bearevement Days", bgcolor: 'bg-[#FFDFA6]' },
];

const ListLeaves = ref<LeaveManagerPlanner[]>([]);
const startDate = ref(new Date());
const endDate = ref(new Date(startDate.value));
endDate.value.setFullYear(startDate.value.getFullYear() + 1);

const userId = employeeId;
const leaveTypes = useQuery(GET_EMPLOYEE_PLANNER, {
  userId,
});

leaveTypes.onResult((result) => {
  if (result.data && result.data.getEmployeeLeavePlanner) {
    const plannerData = result?.data?.getEmployeeLeavePlanner;
    if (plannerData.days && plannerData.leave_types_used) {
      const user = plannerData?.user;
      const fullName = `${user?.first_name} ${user?.last_name}`;
      const days = plannerData.days.map((day) => ({
        formattedDate: new Date(day?.date).getDate().toString(),
        leave_type: day?.leave_type,
        leave_request: {
          days_off: day?.leave_request?.days_off,
          first_last_days: day?.leave_request?.first_last_days,
        },
      }));
      const leaveTypesUsed = plannerData.leave_types_used.map((typesUsed) => ({
        days_used: typesUsed?.days_used,
        leave_type: {
          title: typesUsed?.leave_type?.title,
        },
      }));

      ListLeaves.value = [
        {
          user_id: user?.user_id,
          name: fullName,
          days: days,
          project: user?.project_id,
          leave_types_used: leaveTypesUsed,
        },
      ];
    }
  }
});

const getTotalLeaveUsed = (user: LeaveManagerPlanner, leaveType: string): number => {
  const total = user.leave_types_used.reduce((total, item) => {
    if (item.leave_type.title === leaveType) {
      return total + item.days_used;
    }
    return total;
  }, 0);

  return parseFloat(total.toFixed(2));
};
</script>