<template>
  <GDialog v-model="employeeDialog" max-width="65rem" persistent>
    <div class="p-4">
      <div data-cy="leaveDetailsModelHeader" class="flex items-center justify-between">
        <h3 data-cy="leaveDetailsModelTitle" class="H600 N900 dark:text-">Employee Leave Details</h3>
        <CloseCircle data-cy="closeLeaveDetailsModel" @click="$emit('closeDialog')" />
      </div>

      <!-- Employee Informations -->
      <div class="flex flex-col gap-5 mt-2 lg:flex-row lg:gap-5 lg:m-5">
        <div class="
            border border-gray-300
            rounded-lg
            p-5
            h-[auto]
            lg:w-[40rem]
            w-full
          ">
          <h3 class="H600 N900">Applicant details</h3>
          <div class="flex flex-col gap-5 mt-2 lg:flex-row lg:gap-5 lg:m-5">
            <div class="w-[15rem]">
              <ProfileImageComponent :initials="GetEmployeeInformation?.full_name
                ? getUserNameInitials(GetEmployeeInformation.full_name)
                : ''
                " :imgSrc="GetEmployeeInformation?.profile_image ?? ''" widthSize="10rem" heightSize="10rem"
                text-size="text-4xl" class="rounded-[50%]" />
            </div>
            <div v-if="!isLoading">
              <h3 class="mb-2">{{ GetEmployeeInformation?.full_name }}</h3>
              <p class="font-normal N700">
                {{ GetEmployeeInformation?.department_name || "No department" }}
                |
                {{ GetEmployeeInformation?.position_name || "No position" }}
              </p>

              <p class="font-normal N700 lg:w-80 lg:truncate" :title="GetEmployeeInformation?.email">
                {{ GetEmployeeInformation?.email || "No email" }}
              </p>
            </div>
            <div v-else>Hold on...</div>
          </div>
        </div>
        <div class="
            border border-gray-300
            rounded-lg
            p-5
            h-[auto]
            lg:w-[25rem]
            w-full
          ">
          <h3 class="H600 N900 mb-5">Leave Summary</h3>
          <p data-cy="totalLeaveDaysUsed" class="mt-2">
            Total entitlement:
            <span class="N700">{{ userLeaveDaysEntitlement?.toFixed(2) || "0.00" }}</span>
          </p>
          <p data-cy="totalLeaveDaysUsed" class="mt-2">
            For annual leave:
            <span class="N700">{{ GetAccruedDays?.max_accrual?.toFixed(2) || "0.00" }}</span>
          </p>
          <p data-cy="availableDays" class="mt-2">
            Accrued days:
            <span class="N700">{{ userLeaveDaysAvailable?.toFixed(2) || "0.00" }}</span>
          </p>
          <p data-cy="availableDays" class="mt-2">
            Available days:
            <span class="N700">{{ GetAccruedDays?.leaveDaysAccrued?.toFixed(2) || "0.00" }}</span>
          </p>
          <p data-cy="leaveRequests" class="mt-2">
            Total leave requests:
            <span class="N700">{{ ListLeavesBookedTimeOffTableData.length + ListLeavesBookedTableData.length }}</span>
          </p>
        </div>
      </div>
      <hr class="my-5" />
      <!-- Calculator, Add and Book leave Actions -->
      <div class="flex items-center justify-end gap-5">
        <span title="Leave Calculator">
          <LeaveCalculator data-cy="leaveCalculator" @click="calculatorDialog = true"
            class="border border-gray-200 p-2 rounded-lg cursor-pointer" />
        </span>
        <ButtonComponent v-if="leavesPerm.manage_hr_employee_info" data-cy="addLeave" @click="hrAddAccruedDialog = true"
          variant="secondary" class="
            flex
            items-center
            with_border
            border border-gray-200
            h-[3rem]
            cursor-pointer
            truncate
          ">
          Add Leave
        </ButtonComponent>
        <ButtonComponent v-if="leavesPerm.manage_hr_employee_info" data-cy="bookLeave" class="flex items-center h-12"
          @submit="[(editMode = false), (scheduleMode = false), (addDialog = true)]">
          Book Leave
        </ButtonComponent>
      </div>

      <!-- Employee table -->
      <div class="mt-5 mb-5 h-[20rem] overflow-y-auto">
        <h4 class="py-2 my-3 H600 N900">
          Leave Requests ({{ ListLeavesBookedTimeOffTableData.length + ListLeavesBookedTableData.length }})
        </h4>
        <TableComponentVue :overflowXAuto="false" :headers="displayedHeaders" :itemsPerPage="9"
          :items="CombinedLeavesTableData" emptyMessage="No Upcoming timeoff & History requests">
          <!-- Slot Templates -->
          <template #days="{ item }">
            <TooltipComponent :text="getSpanTitle(item.first_last_days)" :position="'right'">
              <div class="flex items-center w-fit">
                {{ formatDateShort(item.first_last_days[0]) }} -
                {{ formatDateShort(item.first_last_days[1]) }}
              </div>
            </TooltipComponent>
          </template>

          <template #leave_type="slotProps">
            <span class="flex items-center gap-2">
              {{ slotProps.item.leave_type }}
              <span v-if="hasTouchPointComments(slotProps.item)" class="relative group">
                <ToolTipIcon />
                <div class="
              w-80 max-h-32 bg-opacity-90 bg-[#101840] z-10 N0 text-sm rounded p-2 absolute left-full hidden group-hover:block overflow-hidden whitespace-normal -top-8
            ">
                  {{ slotProps.item.touch_point_comments?.[0]?.message }}
                </div>
              </span>
            </span>
          </template>

          <template #days_off="slotProps">
            <span class="flex items-center">
              {{ (slotProps.item as LeaveRequest).days_off.toFixed(2) }}
              <span v-if="(slotProps.item as LeaveRequest).carry_over_used > 0" title="Carry over"
                class="text-[0.7rem] O300">
                &nbsp;+{{ (slotProps.item as LeaveRequest).carry_over_used.toFixed(2) }}
              </span>
            </span>
          </template>

          <template #status="slotProps">
            <div class="px-2 rounded-lg flex items-center gap-x-2 w-fit truncate" :class="statusClass(slotProps.item)">
              <span class="text-[1.3rem]">•</span>
              {{ getStatusText(slotProps.item) }}
            </div>
          </template>

          <template #actions="slotProps">
            <!-- Table Action Buttons -->
            <div class="flex justify-start">
              <DropdownMenu :isLastItem="isLastItem(slotProps)" :bottom-class="'-mt-[10rem] absolute z-50'"
                data-cy="actions" @selectOption="(name: string) => handleSelectOption(slotProps.item, name)"
                :optionsList="computedDropdownMenu(slotProps.item)" />
            </div>
          </template>
        </TableComponentVue>
      </div>

      <!-- Dialog action -->
      <div class="flex flex-col gap-4 justify-end pt-2">
        <div class="flex items-center justify-end gap-5">
          <ButtonComponent data-cy="openLeaveEmployeeDialog" @click="() => {
            $emit('closeDialog');
          }
            ">
            Ok
          </ButtonComponent>
        </div>

        <AlertComponent :message-block="messageBlock" />
      </div>
    </div>

    <!-- Dialogs for Book Request -->
    <LeaveRequestDialog v-if="addDialog" v-model="addDialog" @closeDialog="addDialog = false"
      :maxAccrual="GetAccruedDays?.max_accrual" :leaveDaysUsed="GetAccruedDays?.leaveDaysUsed"
      :leaveDaysAccrued="GetAccruedDays?.leaveDaysAccrued" :leaveDaysCarryOver="GetAccruedDays?.carry_over_days"
      :leaveDaysCanNegate="GetAccruedDays?.can_negate" :listLeavesHolidays="ListLeavesHolidays"
      :editLeaveRequest="editMode" :editLeaveRequestId="leave.id" :editLeaveRequestDescription="leave.description"
      :editLeaveRequestDocument="leave.document" :editLeaveRequestLeaveType="leave.leave_type"
      :editLeaveRequestFirstLastDaysStart="leave.first_last_days_start"
      :editLeaveRequestFirstLastDaysEnd="leave.first_last_days_end" :editLeaveRequestManagerId="leave.manager_id"
      :employee-id="employeeId" />

    <!-- Dialogs for employee calculator -->
    <LeaveCalculatorDialog v-model="calculatorDialog" @closeDialog="calculatorDialog = false" :employee-id="employeeId"
      :leaveDaysAccrued="GetAccruedDays?.leaveDaysAccrued" v-if="calculatorDialog" />

    <!-- Dialogs for employee add accruels -->
    <LeaveHrAddAccruedDialog v-model="hrAddAccruedDialog" @closeDialog="hrAddAccruedDialog = false"
      :employee-id="employeeId" :leaveDaysAccrued="GetAccruedDays?.leaveDaysAccrued"
      :employee-information="GetEmployeeInformation" v-if="hrAddAccruedDialog" />

    <!-- Archive dialog -->
    <GDialog v-model="archiveFormDialog" max-width="29.688rem">
      <ArchiveDialog @close-dialog="archiveFormDialog = false" :component-title="archiveDialogTitle"
        :deleteBtn="archiveDialogDeleteBtn" @delete="archive" :delete-message="archiveDialogDeleteMessage" />
    </GDialog>
  </GDialog>
</template>

<script setup lang="ts">
// Vue imports
import { ref, toRefs, reactive, computed } from "vue";
import { useStore } from '@/store';

//interfaces
import { LeaveHoliday } from "@/apps/leave-management/interfaces/leave-configuration/LeaveHolidays";
import {
  LeaveRequest,
  EditLeaveRequest,
} from "@/apps/leave-management/interfaces/book-leaves/LeaveRequests";

// Helper functions
import { formatDateShort } from "../../helpers/leaveHelperFunctions";
import { getUserNameInitials } from "@/helpers/functions";

// SVG Component Imports
import CloseCircle from "@/apps/leave-management/assets/svgComponents/CloseCircle.vue";
import LeaveCalculator from "../../assets/svgComponents/LeaveCalculator.vue";
import EditIcon from "@/assets/EditSvg.vue";
import TrashIcon from "@/assets/TrashSvg.vue";
import ToolTipIcon from "@/apps/leave-management/assets/svgComponents/ToolTipIcon.vue";

// UI Component Imports
import LeaveRequestDialog from "../../reusables/book-leaves/LeaveRequestDialog.vue";
import LeaveCalculatorDialog from "../../reusables/book-leaves/LeaveCalculator.vue";
import LeaveHrAddAccruedDialog from "../../reusables/hr-dashboard/HrAddAccrued.vue";
import ButtonComponent from "@/ui-kit/button/ButtonComponent.vue";
import TableComponentVue from "@/ui-kit/table/TableComponent.vue";
import TooltipComponent from "@/ui-kit/Tooltip/TooltipComponent.vue";
import AlertComponent from "@/ui-kit/AlertComponent.vue";
import ProfileImageComponent from "@/components/ProfileImageComponent.vue";
import ArchiveDialog from "@/ui-kit/DeleteDialog.vue";
import DropdownMenu from "@/components/DropdownMenu.vue";

// Apollo Imports
import { useQuery, useMutation } from "@vue/apollo-composable";

// GraphQL Mutations and Queries
import { LIST_HOLIDAYS } from "../../graphql/querries/leave-configuration/getHolidays";
import { UPCOMING_LIST_LEAVE_REQUEST, LIST_LEAVE_REQUEST, GET_ACCRUED_DAYS } from "@/apps/leave-management/graphql/querries/book-leaves/getLeaveRequests";
import { GET_HR_EMPLOYEES_OVERVIEW, GET_EMPLOYEE_INFO } from "../../graphql/querries/hr-dashboard/getHrApprovals";
import { SOFT_DELETE_LEAVE_REQUEST } from "@/apps/leave-management/graphql/mutations/book-leaves/setLeaveRequests";
import { useAccessStore } from "@/apps/leave-management/store/store_leaves_permissions";
import { removeActionHeader } from "@/apps/leave-management/store/permissionFunctions";

const props = defineProps({
  employeeId: String,
  userCountry: String,
  userLeaveDaysAvailable: Number,
  userLeaveDaysEntitlement: Number
});
const { employeeId, userCountry } = toRefs(props);

// Reactive Variables
const store = useStore();
const Access = useAccessStore();
const permissions = Access.permissions?.settings?.leave_management;
const leavesPerm = permissions ?? {};

const headers = [
  { key: "days", label: "Days" },
  { key: "leave_type", label: "Leave type" },
  { key: "days_off", label: "Duration" },
  { key: "status", label: "Status" },
  { key: "actions", label: "Actions" },
];

let editMode = ref(false);
let scheduleMode = ref(false);
const addDialog = ref(false);
const calculatorDialog = ref(false);
const hrAddAccruedDialog = ref(false);
let archiveFormDialog = ref(false);
const employeeDialog = ref(false);
let ListLeavesHolidays = ref<LeaveHoliday[]>([]);

const messageBlock = reactive({ open: false, severity: "", message: "" });

let leave: LeaveRequest = reactive<LeaveRequest>({
  id: "",
  created_by: "",
  is_hr_approved: false,
  leave_type_id: "",
  leave_type: "",
  selectedDate: {
    startDate: "",
    endDate: "",
  },
  description: "",
  document: "",
  file: "",
  dates: [],
  days: "",
  duration: 0,
  carry_over_used: 0,
  first_last_days_start: "",
  first_last_days_end: "",
  first_last_days: [],
  days_off: 0,
  status: "",
  comments: "",
  employee_detail: "",
  manager_approved: "",
  manager_id: "",
  is_archived: false,
  can_negate: false,
  touch_point_comments: [],
});

// computed properties
const displayedHeaders = computed(() =>
  removeActionHeader(headers, leavesPerm.manage_hr_employee_info)
);

const computedDropdownMenu = (item: LeaveRequest) => {
  const options = [];

  options.push({
    id: 1,
    name: "Edit",
    label: "Edit",
    icon: EditIcon,
    fill: "#696F8C",
    allowAccess: true,
  });

  options.push({
    id: 2,
    name: "Remove",
    label: "Remove",
    icon: TrashIcon,
    fill: "#696F8C",
    allowAccess: true,
  });

  return options;
};

// functions
const handleSelectOption = (item: EditLeaveRequest, name: string) => {
  if (name === "Edit") {
    editLeaveRequest(item);
  } else if (name === "Remove") {
    handleArchiveClick(item);
  }
};

const editLeaveRequest = (item: EditLeaveRequest) => {
  leave.id = item.id;
  leave.description = item.touch_point_comments?.[0]?.message;
  leave.document = item.file;
  leave.leave_type = item.leave_type;
  leave.first_last_days_start = item.first_last_days[0];
  leave.first_last_days_end = item.first_last_days[1];
  leave.manager_id = item.manager_id;
  editMode.value = true;
  addDialog.value = true;
  scheduleMode.value = false;
};

const getSpanTitle = ([firstDay]: [Date, Date]): "Upcoming timeoff" | "History" => {
  const currentDate = new Date();
  const startDate = new Date(firstDay);

  return startDate > currentDate ? "Upcoming timeoff" : "History";
};

const hasTouchPointComments = (item: LeaveRequest) => {
  return item["touch_point_comments"]?.[0]?.message.length > 0;
};

const statusClass = (item: LeaveRequest) => {
  const { status, manager_approved } = item;

  if (status === 'APPROVED') {
    return 'text-[#10899e] bg-[#D3F5F7]';
  } else if (status === 'PENDING' && manager_approved === 'PENDING') {
    return 'text-[#b48429] bg-[#fcefd6]';
  } else if (status === 'DENIED') {
    return 'text-[#b42f2f] bg-[#f7c5c5]';
  } else if (manager_approved === 'APPROVED' && status !== 'DENIED') {
    return 'text-[#138fa5] bg-[#D3F5F7]';
  } else if (manager_approved === 'DENIED' && status !== 'APPROVED') {
    return 'text-[#ab2b2b] bg-[#f7c5c5]';
  } else if (manager_approved === 'PENDING') {
    return 'text-[#0e8ba1] bg-[#d1f6f8]';
  } else {
    return '';
  }
};

const getStatusText = (item: LeaveRequest) => {
  const { status, manager_approved } = item;

  if (status === 'APPROVED') {
    return 'Approved by HR';
  } else if (status === 'DENIED') {
    return 'Disapproved by HR';
  } else if (manager_approved === 'APPROVED' && status !== 'DENIED') {
    return 'Approved by manager';
  } else if (manager_approved === 'DENIED' && status !== 'APPROVED') {
    return 'Disapproved by manager';
  } else if (status === 'PENDING' && manager_approved === 'PENDING') {
    return 'Pending approval';
  } else {
    return '';
  }
};

const isLastItem = (slotProps: { displayedItems: number; index: number }) => {
  const { displayedItems, index } = slotProps;
  return displayedItems > 3 ? [displayedItems - 1, displayedItems - 2, displayedItems - 3].includes(index) : [displayedItems - 1, displayedItems - 2].includes(index);
};

// Mutations & Queries
const GetAccruedDays = ref({
  leaveDaysAccrued: 0,
  leaveDaysUsed: 0,
  max_accrual: 0,
  carry_over_days: 0,
  can_negate: true,
});

const GetAccruedDaysByUserId = useQuery(GET_ACCRUED_DAYS, {
  userId: employeeId,
});

GetAccruedDaysByUserId.onResult((result) => {
  if (result.data?.getAccruedDaysByUserId) {
    const accruedData = result.data.getAccruedDaysByUserId;
    GetAccruedDays.value.leaveDaysAccrued = accruedData?.totalAccumulated;
    GetAccruedDays.value.leaveDaysUsed =
      accruedData?.activeAccrual?.leaveDaysUsed;
    GetAccruedDays.value.carry_over_days = accruedData.carry_over_days || 0;
    GetAccruedDays.value.can_negate = true;
    GetAccruedDays.value.max_accrual = accruedData?.activeAccrual?.max_accrual;
  }
});

const GetEmployeeInformation = ref<{
  profile_image: string;
  full_name: string;
  country: string;
  email: string;
  department_name: string;
  position_name: string;
}>({
  profile_image: '',
  full_name: '',
  country: '',
  email: '',
  department_name: '',
  position_name: '',
});

const GetEmployeeInformationByUserId = useQuery(GET_EMPLOYEE_INFO, {
  userId: employeeId,
});

const isLoading = ref(true);

GetEmployeeInformationByUserId.onResult((result) => {
  if (result.data && result.data.getLeavesEmployeeActiveInfo) {
    const employeeInfo = result.data.getLeavesEmployeeActiveInfo;

    GetEmployeeInformation.value = {
      profile_image: employeeInfo?.users?.profile_image,
      full_name: employeeInfo?.users?.full_name,
      email: employeeInfo?.users?.email,
      department_name: employeeInfo?.organization?.name,
      position_name: employeeInfo?.position?.position_name,
      country: employeeInfo?.organization?.country,
    };

    isLoading.value = false;
  }
});

const leaveHolidays = useQuery(LIST_HOLIDAYS);
const employeeCountry = ref(userCountry?.value);
leaveHolidays.onResult((result) => {
  if (result.data?.listHolidays) {
    const currentYear = new Date().getFullYear();
    ListLeavesHolidays.value = result.data.listHolidays.filter(
      (holiday: { is_archived: boolean; country: string; start_day: Date }) =>
        !holiday.is_archived &&
        holiday.country === employeeCountry.value &&
        new Date(holiday.start_day).getFullYear() === currentYear
    );
  }
});

// Table
const CombinedLeavesTableData = computed(() => {
  const leavesBookedData = ListLeavesBookedTableData.value;
  const leavesBookedTimeOffData = ListLeavesBookedTimeOffTableData.value;

  const sortedLeavesBookedTimeOffData = leavesBookedTimeOffData.slice().sort((a, b) => {
    //
  });
  const combinedData = [...sortedLeavesBookedTimeOffData, ...leavesBookedData];
  return combinedData;
});

let ListLeavesBookedTimeOffTableData = ref<LeaveRequest[]>([]);
const leaveBookedTimeOff = useQuery(UPCOMING_LIST_LEAVE_REQUEST, {
  userId: employeeId,
});

leaveBookedTimeOff.onResult((result) => {
  if (result.data?.getUpcomingTimeOff) {
    ListLeavesBookedTimeOffTableData.value = result.data.getUpcomingTimeOff;
  }
});

let ListLeavesBookedTableData = ref<LeaveRequest[]>([]);
const leaveBookedHistory = useQuery(LIST_LEAVE_REQUEST, {
  userId: employeeId,
});

leaveBookedHistory.onResult((result) => {
  if (result.data?.getLeaveHistoryByUserId) {
    ListLeavesBookedTableData.value = result.data.getLeaveHistoryByUserId;
  }
});

const archiveDialogTitle = ref("");
const archiveDialogDeleteBtn = ref("");
const archiveDialogDeleteMessage = ref("");

const handleArchiveClick = (item: EditLeaveRequest) => {
  leave.id = item.id;
  leave.leave_type = item.leave_type;
  leave.status = item.status;
  leave.first_last_days_start = item.first_last_days[0];
  leave.first_last_days_end = item.first_last_days[1];

  archiveFormDialog.value = true;
  archiveDialogTitle.value = "Confirmation";
  archiveDialogDeleteBtn.value = "Remove";
  archiveDialogDeleteMessage.value = `Do you really want to remove ${leave.leave_type
    } from ${formatDateShort(
      leave.first_last_days_start
    )} to ${formatDateShort(
      leave.first_last_days_end
    )}?`;
};

const archive_leave_request = useMutation(SOFT_DELETE_LEAVE_REQUEST, () => ({
  variables: {
    deleteBookedLeaveId: leave.id,
    isArchived: true,
  },
}));

const archive = () => {
  archive_leave_request.mutate();
};

const { refetch: refetchGetLeaveAccruedData } = useQuery(GET_ACCRUED_DAYS, {
  userId: employeeId,
});
const { refetch: refetchTableData } = useQuery(LIST_LEAVE_REQUEST, {
  userId: employeeId,
});
const { refetch: refetchListLeavesBookedTimeOff } = useQuery(
  UPCOMING_LIST_LEAVE_REQUEST,
  {
    userId: employeeId,
  }
);
const variablesOverView = {
  data: {
    organization_id: store?.user?.organization
  }
};
const { refetch: refetchOverviewCounts } = useQuery(GET_HR_EMPLOYEES_OVERVIEW, variablesOverView);


archive_leave_request.onDone(() => {
  refetchTableData();
  refetchListLeavesBookedTimeOff();
  refetchGetLeaveAccruedData();
  refetchOverviewCounts();
  messageBlock.open = true;
  messageBlock.severity = "success";
  archiveFormDialog.value = false;
  messageBlock.message = "Leave request removed successfully";
  refetchGetLeaveAccruedData();
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});

archive_leave_request.onError((error) => {
  messageBlock.open = true;
  archiveFormDialog.value = false;
  messageBlock.severity = "error";
  messageBlock.message =
    error.message.length <= 100 ? error.message : "Please contact support";
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});
</script>
