<template>
  <template v-if="!env.VUE_APP_LEAVE_TYPE && leavesSettingsPerm.view_leave_type">
    <!-- ONBOARDING CARD -->
    <OnboardingCardComponent v-if="tableData.length === 0 && showOnboarding" class="z-10" @btncall="setLocal"
      title="Welcome to Leave Types"
      content="On this page, you can easily manage leave types for your organization. Click on add a leave type to get started now!" />

    <div>
      <!-- Message Alert -->
      <div v-if="tableData.length === 0 && showAlertMessage && leavesSettingsPerm.view_leave_type" class="
          mt-4
          flex
          border-2 border-solid border-[#E6E8F0]
          rounded-lg
          bg-[#FAFBFF]
          gap-x-[0.69rem]
          py-2
          p-[0.69rem]
          justify-between
          items-center
        ">
        <div class="flex items-center gap-x-4">
          <div class="">
            <InfoClose />
          </div>
          <p class="text-base N700">
            Setup leave types for your organisation.
            <span class="text-[#DD5928]">Learn more about setting up leave types.</span>
          </p>
        </div>
        <span @click="showAlertMessage = false" type="button">
          <CloseCircle />
        </span>
      </div>

      <!-- Leave type section -->
      <div class="
          flex flex-col
          items-center
          justify-between
          gap-5
          my-2
          lg:flex-row lg:gap-0
        ">
        <div class="flex items-center gap-x-2">
          <FilterComponent data-cy="filterButton" @click="toggleFilters" />
          <SearchComponent data-cy="search" class="py-2" @search="searchTableData" />
        </div>
        <div v-if="leavesSettingsPerm.manage_leave_type" class="flex gap-2">
          <ButtonComponent buttonLabel="Add a Leave type" variant="primary"
            @click="[(editMode = false), (addDialog = true)]" data-cy="addLeaveType" class="shadow-md" />
        </div>
      </div>

      <div v-show="showFilters" class="flex gap-2 items-center mb-3">
        <p class="N900">Filter</p>
        <div class="holidaySelect">
          <SelectFieldValue :showSearch="false" :showLabel="false" id="status" data-cy="filterField"
            placeholder="Filter" :options="['Unarchived', 'Archived']" v-model="selectedFilter" />
        </div>
      </div>

      <TableComponentVue :overflowXAuto="false" :headers="displayedHeaders" :items="tableData" :itemsPerPage="5"
        emptyMessage="You haven't created any leave type for your organisation">
        <template #title="slotProps">
          <span class="flex items-center gap-2 pt-3 pb-3">
            {{ (slotProps.item as LeaveTypes)["title"] }}
            <span v-if="(slotProps.item as LeaveTypes)['description']" class="relative group">
              <ToolTipIcon />
              <div class="
                  w-80
                  max-h-32
                  bg-opacity-90 bg-[#101840]
                  z-10
                  N0
                  text-sm
                  rounded
                  p-2
                  absolute
                  left-full
                  hidden
                  group-hover:block
                  overflow-hidden
                  whitespace-normal
                  -top-8
                ">
                {{ (slotProps.item as LeaveTypes)["description"] }}
              </div>
            </span>
          </span>
        </template>

        <template #organization="slotProps">
          <span class="flex items-center">
            {{ (slotProps.item as LeaveTypes)?.organization?.name }}
          </span>
        </template>

        <template #action="slotProps">
          <div class="flex justify-start">
            <DropdownMenu :isLastItem="slotProps.displayedItems > 3 ? slotProps.displayedItems - 1 === slotProps.index ||
              slotProps.displayedItems - 2 === slotProps.index ||
              slotProps.displayedItems - 3 === slotProps.index :
              slotProps.displayedItems - 1 === slotProps.index ||
              slotProps.displayedItems - 2 === slotProps.index" :bottom-class="'-mt-[14rem] absolute z-50'"
              data-cy="actions" @selectOption="(name: string) => handleSelectOption(slotProps.item, name)" :optionsList="[
                {
                  id: 1,
                  name: 'Edit',
                  label: 'Edit',
                  icon: EditIcon,
                  fill: '#696F8C',
                  allowAccess: true,
                },
                {
                  id: 2,
                  name: slotProps.item.is_archived ? 'Unarchive' : 'Archive',
                  label: slotProps.item.is_archived ? 'Unarchive' : 'Archive',
                  icon: ArchiveSvg,
                  fill: '#696F8C',
                  allowAccess: true,
                },
                {
                  id: 3,
                  name: 'Remove',
                  label: 'Remove',
                  icon: TrashIcon,
                  fill: '#696F8C',
                  allowAccess: true,
                },
              ]" />
          </div>
        </template>
      </TableComponentVue>

      <!-- Loading state -->
      <div v-if="queryLoading && ListLeaveTypes.length === 0">
        <LoaderIconVue />
      </div>

      <!-- Dialog for edit, add, and archive -->
      <ModalComponent :open="addDialog" @close="addDialog = false">
        <div class="p-4 w-[34rem]">
          <div class="flex items-center justify-between">
            <h3 class="H600 N900 dark:text-">
              {{ editMode ? "Edit leave type" : "Add leave type" }}
            </h3>
            <CloseCircle @click="close" />
          </div>
          <div class="mt-5">
            <InputFieldValue data-cy="name" label="Name" placeholder="Name" :requireTag="true" v-model="leave.title" />
          </div>
          <div class="pt-4">
            <MultiLineInputBox data-cy="description" label="Description" :showlength="true" :maxlength="200"
              :requireTag="false" placeholder="Description" v-model="leave.description" />
          </div>
          <div class="mt-5">
            <SelectFieldValue id="organisation" :showSearch="true" data-cy="organisation" label="Organisation"
              placeholder="Choose organisation" :requireTag="true" :options="organisations.map((type) => type.title)"
              v-model="selectedOrganisation" />
          </div>

          <!-- Form actions -->
          <div class="flex justify-end gap-2 pt-2">
            <ButtonComponent button-label="Cancel" :variant="'secondary'" @click.stop="close" dataCy="cancel">
            </ButtonComponent>
            <ButtonComponent @click="updateLeaveType.mutate" data-cy="updateLeaveType" v-if="editMode" :disabled="leave.title.trim().length < 1 ||
              selectedOrganisation.trim().length < 1
              " :loading="loading">
              Update
            </ButtonComponent>
            <ButtonComponent data-cy="saveLeaveType" v-else @click="addLeaveType.mutate" :disabled="leave.title.trim().length < 1 ||
              selectedOrganisation.trim().length < 1
              " :loading="loading">
              Save
            </ButtonComponent>
          </div>
        </div>
      </ModalComponent>

      <!-- Archive dialog -->
      <GDialog v-model="archiveFormDialog" max-width="29.688rem">
        <ArchiveDialog v-if="leave.is_archived === false" @close-dialog="archiveFormDialog = false"
          :component-title="archiveDialogTitle" :deleteBtn="archiveDialogDeleteBtn" @delete="archive"
          :delete-message="archiveDialogDeleteMessage" />
        <ArchiveDialog v-else @close-dialog="archiveFormDialog = false" :component-title="unarchiveDialogTitle"
          :deleteBtn="unarchiveDialogDeleteBtn" @delete="unarchive" :delete-message="unarchiveDialogDeleteMessage" />
      </GDialog>

      <!-- Remove dialog -->
      <GDialog v-model="removeFormDialog" max-width="29.688rem">
        <ArchiveDialog @close-dialog="removeFormDialog = false" :component-title="removeDialogTitle"
          :deleteBtn="removeDialogDeleteBtn" @delete="remove" :delete-message="removeDialogDeleteMessage" />
      </GDialog>
    </div>
    <AlertComponent :message-block="messageBlock" />
  </template>
  <div v-else-if="env.VUE_APP_LEAVE_TYPE">
    <VersionDisplay class="" />
  </div>
  <div class="flex flex-col" v-else>
    <div class="p-3 mx-auto mt-10"></div>
    <NotAuthorised class="" />
  </div>
</template>

<script setup lang="ts">
// Vue imports
import { reactive, ref, computed, watchEffect } from "vue";

// Interfaces
import { LeaveTypes } from "../../interfaces/leave-configuration/LeaveTypes";

// SVG Component Imports
import CloseCircle from "../../assets/svgComponents/CloseCircle.vue";
import InfoClose from "../../assets/svgComponents/InfoClose.vue";
import ToolTipIcon from "../../assets/svgComponents/ToolTipIcon.vue";
import EditIcon from "@/assets/EditSvg.vue";
import TrashIcon from "@/assets/TrashSvg.vue";
import ArchiveSvg from "@/assets/ArchiveSvg.vue";

// UI Component Imports
import ModalComponent from "@/ui-kit/ModalComponent.vue";
import ArchiveDialog from "@/ui-kit/DeleteDialog.vue";
import MultiLineInputBox from "@/ui-kit/Inputs/TextArea.vue";
import SelectFieldValue from "@/ui-kit/Inputs/SelectField.vue";
import InputFieldValue from "@/ui-kit/Inputs/InputField.vue";
import ButtonComponent from "@/ui-kit/button/ButtonComponent.vue";
import LoaderIconVue from "@/ui-kit/loader/LoaderIcon.vue";
import TableComponentVue from "@/ui-kit/table/TableComponent.vue";
import AlertComponent from "@/ui-kit/AlertComponent.vue";
import VersionDisplay from "@/components/HelloWorld.vue";
import OnboardingCardComponent from "@/components/OnboardingCardComponent.vue";
import SearchComponent from "@/components/SearchComponent.vue";
import DropdownMenu from "@/components/DropdownMenu.vue";
import FilterComponent from "@/components/FilterComponent.vue";

// Apollo Imports
import {
  useQuery,
  useMutation,
  useMutationLoading,
  useQueryLoading,
} from "@vue/apollo-composable";

// GraphQL Mutations and Queries
import {
  CREATE_LEAVE_TYPE,
  UPDATE_LEAVE_TYPE,
  TOGGLE_LEAVE_TYPE_STATUS,
  REMOVE_LEAVE_TYPE_STATUS,
} from "../../graphql/mutations/leave-configuration/setLeaveTypes";
import {
  GET_ALL_LEAVE_TYPES,
  GET_ASSIGNED_LEAVE_POLICIES, GET_ORGANISATIONS
} from "../../graphql/querries/leave-configuration/getLeaveTypes";

import { useAccessStore } from "@/apps/leave-management/store/store_leaves_permissions";
import NotAuthorised from "@/components/NotAuthorized.vue";
import { removeActionHeader } from "@/apps/leave-management/store/permissionFunctions";

// Constants
const env = process.env;

// Reactive Variables
const Access = useAccessStore();
const permissions = Access.permissions?.settings?.leave_management;
const leavesSettingsPerm = permissions ?? {};
const searchQuery = ref('');

let showOnboarding = ref(true);
const setLocal = () => {
  showOnboarding.value = false;
};

const headers = [
  { key: "title", label: "Name" },
  { key: "organization", label: "Assigned to Location" },
  { key: "action", label: "Actions" },
];

let addDialog = ref(false);
let editMode = ref(false);
const showFilters = ref(false);
let archiveFormDialog = ref(false);
let removeFormDialog = ref(false);
let ListLeaveTypes = ref<LeaveTypes[]>([]);
let showAlertMessage = ref(true);
let selectedFilter = ref("Unarchived");
const selectedOrganisation = ref("");

const messageBlock = reactive({ open: false, severity: "", message: "" });
const loading = useMutationLoading();
let queryLoading = useQueryLoading();

let leave: LeaveTypes = reactive<LeaveTypes>({
  id: "",
  title: "",
  description: "",
  organization_id: "",
  organization: { name: "" },
  country: "",
  status: "",
  is_archived: false,
});

//computed properties
const displayedHeaders = computed(() =>
  removeActionHeader(headers, leavesSettingsPerm.manage_leave_type)
);

const organisations = computed<{ id: string; title: string }[]>(() => {
  if (
    organizationsResult.value &&
    organizationsResult.value.getAllLeavesOrganizations
  ) {
    return organizationsResult.value.getAllLeavesOrganizations.map(
      (item: { id: string; name: string }) => ({
        id: item?.id,
        title: item ? item.name : ''
      })
    );
  } else {
    return [];
  }
});

const tableData = computed(() => {
  if (searchQuery.value.trim() !== "") {
    return ListLeaveTypes?.value?.filter((item: { title: string }) => {
      return (
        item?.title.toLowerCase().indexOf(searchQuery.value.toLowerCase()) !== -1
      );
    });
  } else {
    let filteredData = (ListLeaveTypes.value as LeaveTypes[]).filter((item) => {
      if (selectedFilter.value === "Archived") {
        return item.is_archived;
      } else if (selectedFilter.value === "Unarchived") {
        return !item.is_archived;
      }
    });

    filteredData.sort((a, b) => {
      const titleA = a.title.toLowerCase();
      const titleB = b.title.toLowerCase();
      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }
      return 0;
    });

    return filteredData.slice();
  }
});

// Functions
const close = () => {
  leave.id = "";
  leave.title = "";
  leave.description = "";
  selectedOrganisation.value = "";
  editMode.value = false;
  addDialog.value = false;
};

watchEffect(() => {
  if (!addDialog.value) {
    close();
  }
});

const searchTableData = (searchString: string) => {
  searchQuery.value = searchString;
};

const toggleFilters = () => {
  showFilters.value = !showFilters.value;
};

const handleSelectOption = (item: LeaveTypes, name: string) => {
  if (name === "Edit") {
    editLeaveType(item);
  } else if (name === "Archive" || name === "Unarchive") {
    handleArchiveClick(item);
  } else if (name === "Remove") {
    handleRemoveClick(item);
  }
};

const editLeaveType = (item: LeaveTypes) => {
  leave.id = item.id;
  leave.title = item.title;
  leave.description = item.description;
  selectedOrganisation.value =
    organisations.value.find(
      (leaveType) => leaveType.id === item.organization_id
    )?.title || "Unknown Organisation";
  editMode.value = true;
  addDialog.value = true;
};

// Mutations and Queries
const { result: organizationsResult } = useQuery(GET_ORGANISATIONS);
const leaveTypes = useQuery(GET_ALL_LEAVE_TYPES);

leaveTypes.onResult((result) => {
  if (result.data && result.data.listLeaveTypes) {
    ListLeaveTypes.value = result.data.listLeaveTypes;
  }
});

const addLeaveType = useMutation(CREATE_LEAVE_TYPE, () => ({
  variables: {
    data: {
      title: leave.title,
      description: leave.description,
      organization_id:
        organisations.value.find(
          (type) => type.title === selectedOrganisation.value
        )?.id || "",
    },
  },
}));

const { refetch: refetchLeavesTypes } = useQuery(GET_ALL_LEAVE_TYPES);
addLeaveType.onDone(() => {
  refetchLeavesTypes();
  close();
  messageBlock.open = true;
  messageBlock.severity = "success";
  messageBlock.message = "Leave type added successfully";
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});

addLeaveType.onError((error) => {
  messageBlock.open = true;
  messageBlock.severity = "error";
  messageBlock.message =
    error.message.length <= 100 ? error.message : "Leave type creation failed";
  close();
  setTimeout(() => {
    messageBlock.open = false;
  }, 5000);
});

const updateLeaveType = useMutation(UPDATE_LEAVE_TYPE, () => ({
  variables: {
    leaveTypeId: leave.id,
    data: {
      id: leave.id,
      title: leave.title,
      description: leave.description,
      organization_id: selectedOrganisation.value
        ? organisations.value.find(
          (type) => type.title === selectedOrganisation.value
        )?.id
        : null,
    },
  },
}));

updateLeaveType.onDone(() => {
  refetchLeavesTypes();
  messageBlock.open = true;
  messageBlock.severity = "success";
  close();
  messageBlock.message = "Leave type updated successfully";
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});

updateLeaveType.onError((error) => {
  messageBlock.open = true;
  addDialog.value = false;
  messageBlock.severity = "error";
  messageBlock.message =
    error.message.length <= 100 ? error.message : "Please contact support";
  close();
  setTimeout(() => {
    messageBlock.open = false;
  }, 5000);
});

const removeDialogTitle = ref("");
const removeDialogDeleteBtn = ref("");
const removeDialogDeleteMessage = ref("");

const handleRemoveClick = async (item: LeaveTypes) => {
  leave.id = item.id;
  leave.title = item.title;
  leave.is_archived = item.is_archived;

  removeFormDialog.value = true;
  removeDialogTitle.value = "Remove Leave Type";
  removeDialogDeleteMessage.value = `Do you really want to remove ${leave.title}?`;
  removeDialogDeleteBtn.value = "Remove";
};

const remove_leave_type = useMutation(REMOVE_LEAVE_TYPE_STATUS, () => ({
  variables: {
    deleteLeaveTypeId: leave.id,
  },
}));

const remove = () => {
  remove_leave_type.mutate();
};

remove_leave_type.onDone(() => {
  refetchLeavesTypes();
  messageBlock.open = true;
  messageBlock.severity = "success";
  removeFormDialog.value = false;
  messageBlock.message = "Leave type removed successfully";
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});

remove_leave_type.onError((error) => {
  messageBlock.open = true;
  removeFormDialog.value = false;
  messageBlock.severity = "error";
  messageBlock.message =
    error.message.length <= 100 ? error.message : "Please contact support";
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});

const archiveDialogTitle = ref("");
const archiveDialogDeleteBtn = ref("");
const archiveDialogDeleteMessage = ref("");
const unarchiveDialogTitle = ref("");
const unarchiveDialogDeleteBtn = ref("");
const unarchiveDialogDeleteMessage = ref("");

const assignedPoliciesCount = ref(null);
const { result: policiesResult, refetch: fetchAssignedPolicies } = useQuery(
  GET_ASSIGNED_LEAVE_POLICIES,
  {}
);

watchEffect(() => {
  if (policiesResult.value) {
    assignedPoliciesCount.value =
      policiesResult.value?.data?.checkForAssignedLeavePolicies || null;
  } else {
    assignedPoliciesCount.value = null;
  }
});

const handleArchiveClick = async (item: LeaveTypes) => {
  leave.id = item.id;
  leave.title = item.title;
  leave.is_archived = item.is_archived;

  try {
    const leaveTypeId = item.id;
    const policiesResult = await fetchAssignedPolicies({ leaveTypeId });
    if (policiesResult && policiesResult.data) {
      const policiesCount = policiesResult.data.checkForAssignedLeavePolicies;

      if (leave.is_archived === false) {
        archiveFormDialog.value = true;
        archiveDialogTitle.value = "Archive Leave Type";
        archiveDialogDeleteMessage.value = `Do you really want to archive ${leave.title}?`;
        archiveDialogDeleteBtn.value = "Archive";
        if (policiesCount > 0) {
          archiveDialogDeleteMessage.value += ` This Leave Type has been assigned to ${policiesCount} ${policiesCount > 1 ? "policies" : "policy"} and archiving will permanently unassign`;
        }
      } else if (leave.is_archived === true) {
        archiveFormDialog.value = true;
        unarchiveDialogTitle.value = "Unarchive Leave Type";
        unarchiveDialogDeleteBtn.value = "Unarchive";
        unarchiveDialogDeleteMessage.value = `Are you sure you want to unarchive ${leave.title}?`;
      }
    } else {
      messageBlock.open = true;
      messageBlock.severity = "error";
      messageBlock.message = "Error fetching assigned policies";
      setTimeout(() => {
        messageBlock.open = false;
      }, 5000);
    }
  } catch (error) {
    messageBlock.open = true;
    messageBlock.severity = "error";
    messageBlock.message = "Error fetching assigned policies";
    setTimeout(() => {
      messageBlock.open = false;
    }, 5000);
  }
};

const archive_leave_type = useMutation(TOGGLE_LEAVE_TYPE_STATUS, () => ({
  variables: {
    archiveLeaveTypeId: leave.id,
    isArchived: true,
  },
}));

const archive = () => {
  archive_leave_type.mutate();
};

archive_leave_type.onDone(() => {
  messageBlock.open = true;
  messageBlock.severity = "success";
  archiveFormDialog.value = false;
  messageBlock.message = "Leave type archived successfully";
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});

archive_leave_type.onError((error) => {
  messageBlock.open = true;
  messageBlock.severity = "error";
  archiveFormDialog.value = false;
  messageBlock.message =
    error.message.length <= 100 ? error.message : "Please contact support";
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});

const unarchive_leave_type = useMutation(TOGGLE_LEAVE_TYPE_STATUS, () => ({
  variables: {
    archiveLeaveTypeId: leave.id,
    isArchived: false,
  },
}));

const unarchive = () => {
  unarchive_leave_type.mutate();
};

unarchive_leave_type.onDone(() => {
  messageBlock.open = true;
  messageBlock.severity = "success";
  messageBlock.message = "Leave type unarchived successfully";
  archiveFormDialog.value = false;
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});

unarchive_leave_type.onError((error) => {
  messageBlock.open = true;
  messageBlock.severity = "error";
  archiveFormDialog.value = false;
  messageBlock.message =
    error.message.length <= 100 ? error.message : "Please contact support";
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});
</script>
