<template>
  <template v-if="!env.VUE_APP_LEAVE_HOLIDAYS && leavesSettingsPerm.view_leave_holidays">
    <!-- ONBOARDING CARD -->
    <OnboardingCardComponent v-if="tableData.length === 0 && showOnboarding" class="z-10" @btncall="setLocal"
      title="Welcome to Holidays"
      content="On this page you can easily manage holidays for accurate working day calculations by adding or importing them to your organisation. Get started now!" />
    <div>
      <!-- Holidays section -->
      <div class="
          flex flex-col
          items-center
          justify-between
          gap-5
          my-2
          lg:flex-row lg:gap-0
        ">
        <div class="flex items-center gap-x-2">
          <FilterComponent data-cy="filterButton" @click="toggleFilters" />
          <SearchComponent data-cy="search" class="py-2" @search="searchTableData" />
        </div>
        <div v-if="!env.VUE_APP_LEAVE_HOLIDAYS && leavesSettingsPerm.manage_leave_holidays" class="flex gap-2">
          <RouterLink to="holidays-import-view" class="
              flex
              gap-2
              px-4
              py-2
              text-center
              transition
              duration-300
              border border-gray-300
              BG0
              rounded-lg
              cursor-pointer
              N700
              hover:bg-gray-100 hover:text-gray-600 hover:shadow-md
            ">
            Import Holidays</RouterLink>
          <ButtonComponent buttonLabel="Add a Holiday" variant="primary"
            @submit="[(editMode = false), (addDialog = true)]" data-cy="addLeaveHoliday" class="shadow-md" />
        </div>
      </div>

      <div v-show="showFilters" class="flex gap-2 items-center mb-3">
        <p class="N900">Filter</p>
        <div>
          <SelectFieldValue :showSearch="false" :showLabel="false" id="status" data-cy="filterField"
            placeholder="Filter" :options="['Unarchived', 'Archived']" v-model="selectedFilter" />
        </div>
        <div>
          <SelectFieldValue :showSearch="true" :showLabel="false" id="status" data-cy="filterByCountries"
            placeholder="Filter" :options="contriesFilter" v-model="selectedCountry" />
        </div>
        <div>
          <SelectFieldValue :showSearch="true" :showLabel="false" id="status" data-cy="filterByYear"
            placeholder="Filter" :options="holidaysFilter.map((year) => year.toString())" v-model="selectedHoliday" />
        </div>
      </div>

      <TableComponentVue :overflowXAuto="false" :headers="displayedHeaders" :items="tableData" :itemsPerPage="9"
        emptyMessage="You haven't created any leave holiday for your organisation">
        <template #title="slotProps">
          <span class="flex items-center gap-2 pt-3 pb-3">
            {{ (slotProps.item as LeaveHoliday)["title"] }}
            <span v-if="(slotProps.item as LeaveHoliday)['description']" class="relative group">
              <ToolTipIcon />
              <div class="
                  w-80
                  max-h-32
                  bg-opacity-90 bg-[#101840]
                  z-10
                  N0
                  text-sm
                  rounded
                  p-2
                  absolute
                  left-full
                  hidden
                  group-hover:block
                  overflow-hidden
                  whitespace-normal
                  -top-8
                ">
                {{ (slotProps.item as LeaveHoliday)["description"] }}
              </div>
            </span>
          </span>
        </template>
        <template #start_day="slotProps">
          <span class="flex items-center">
            {{
              formatDateApi((slotProps.item as LeaveHoliday)["start_day"])
            }}
          </span>
        </template>
        <template #end_day="slotProps">
          <span class="flex items-center">
            {{ getDayOfWeek((slotProps.item as LeaveHoliday)["start_day"]) }}
          </span>
        </template>
        <template #action="slotProps">
          <div class="flex justify-start">
            <DropdownMenu :isLastItem="slotProps.displayedItems > 3 ? slotProps.displayedItems - 1 === slotProps.index ||
              slotProps.displayedItems - 2 === slotProps.index ||
              slotProps.displayedItems - 3 === slotProps.index :
              slotProps.displayedItems - 1 === slotProps.index ||
              slotProps.displayedItems - 2 === slotProps.index" :bottom-class="'-mt-[14rem] absolute z-50'"
              data-cy="actions" @selectOption="(name: string) => handleSelectOption(slotProps.item, name)" :optionsList="[
                {
                  id: 1,
                  name: 'Edit',
                  label: 'Edit',
                  icon: EditIcon,
                  fill: '#696F8C',
                  allowAccess: true,
                },
                {
                  id: 2,
                  name: slotProps.item.is_archived ? 'Unarchive' : 'Archive',
                  label: slotProps.item.is_archived ? 'Unarchive' : 'Archive',
                  icon: ArchiveSvg,
                  fill: '#696F8C',
                  allowAccess: true,
                },
                {
                  id: 3,
                  name: 'Remove',
                  label: 'Remove',
                  icon: TrashIcon,
                  fill: '#696F8C',
                  allowAccess: true,
                },
              ]" />
          </div>
        </template>
      </TableComponentVue>

      <!-- Loading state -->
      <div v-if="queryLoading && ListHolidays.length === 0">
        <LoaderIconVue />
      </div>

      <!-- Dialog for edit, add and archive -->
      <ModalComponent :open="addDialog" @close="addDialog = false">
        <div class="p-4 w-[34rem]">
          <div class="flex items-center justify-between">
            <h3 class="H600 N900 dark:text-">
              {{ editMode ? "Edit Holiday" : "Add Holiday" }}
            </h3>
            <CloseCircle @click="close()" />
          </div>
          <div class="mt-5">
            <InputFieldValue data-cy="name" label="Name" placeholder="Name" :requireTag="true" v-model="leave.title" />
          </div>
          <div class="pt-2">
            <MultiLineInputBox data-cy="description" label="Description" :showlength="true" :maxlength="200"
              :requireTag="false" placeholder="Description" v-model="leave.description" />
          </div>
          <div class="flex items-center justify-between">
            <InputFieldValue data-cy="startDate" label="Start Date" type="date" :requireTag="true"
              v-model="leave.start_day" />
          </div>
          <div class="mt-5">
            <SelectFieldValue data-cy="country" id="country" label="Country" placeholder="Choose a country"
              :requireTag="true" :options="countries" v-model="leave.country" />
          </div>

          <!-- Form actions -->
          <div class="flex justify-end gap-2 pt-2">
            <ButtonComponent button-label="Cancel" :variant="'secondary'" @click.stop="close" dataCy="cancel">
            </ButtonComponent>
            <ButtonComponent data-cy="editLeaveHoliday" v-if="editMode" @click="() => updateLeaveHoliday.mutate()"
              :disabled="leave.title.trim().length < 1 ||
                leave.start_day.trim().length < 1 ||
                leave.country.trim().length < 1
                " :loading="loading">
              Update
            </ButtonComponent>
            <ButtonComponent data-cy="saveLeaveHoliday" v-else @click="() => addLeaveHoliday.mutate()" :disabled="leave.title.trim().length < 1 ||
              leave.start_day.trim().length < 1 ||
              leave.country.trim().length < 1
              " :loading="loading">
              Save
            </ButtonComponent>
          </div>
        </div>
      </ModalComponent>

      <!-- Archive dialog -->
      <GDialog v-model="archiveFormDialog" max-width="29.688rem">
        <ArchiveDialog v-if="leave.is_archived === false" @close-dialog="archiveFormDialog = false"
          :component-title="archiveDialogTitle" :deleteBtn="archiveDialogDeleteBtn" @delete="archive"
          :delete-message="archiveDialogDeleteMessage" />
        <ArchiveDialog v-else @close-dialog="archiveFormDialog = false" :component-title="unarchiveDialogTitle"
          :deleteBtn="unarchiveDialogDeleteBtn" @delete="unarchive" :delete-message="unarchiveDialogDeleteMessage" />
      </GDialog>

      <!-- Remove dialog -->
      <GDialog v-model="removeFormDialog" max-width="29.688rem">
        <ArchiveDialog @close-dialog="removeFormDialog = false" :component-title="removeDialogTitle"
          :deleteBtn="removeDialogDeleteBtn" @delete="remove" :delete-message="removeDialogDeleteMessage" />
      </GDialog>
    </div>
    <AlertComponent :message-block="messageBlock" />
  </template>
  <div v-else-if="env.VUE_APP_LEAVE_HOLIDAYS">
    <VersionDisplay class="" />
  </div>
  <div class="flex flex-col" v-else>
    <NotAuthorised class="" />
  </div>
</template>

<script setup lang="ts">
// Vue imports
import { reactive, ref, computed, watchEffect, Ref } from "vue";

// Helper functions
import {
  formatDateApi,
  getDayOfWeek,
  getYearFromDate,
  generateListOfCountries,
} from "../../helpers/leaveHelperFunctions";

// Interfaces
import {
  LeaveHoliday,
  EditLeaveHoliday,
} from "../../interfaces/leave-configuration/LeaveHolidays";

// SVG Component Imports
import CloseCircle from "../../assets/svgComponents/CloseCircle.vue";
import ToolTipIcon from "../../assets/svgComponents/ToolTipIcon.vue";
import EditIcon from "@/assets/EditSvg.vue";
import TrashIcon from "@/assets/TrashSvg.vue";
import ArchiveSvg from "@/assets/ArchiveSvg.vue";

// UI Component Imports
import ModalComponent from "@/ui-kit/ModalComponent.vue";
import ArchiveDialog from "@/ui-kit/DeleteDialog.vue";
import MultiLineInputBox from "@/ui-kit/Inputs/TextArea.vue";
import SelectFieldValue from "@/ui-kit/Inputs/SelectField.vue";
import InputFieldValue from "@/ui-kit/Inputs/InputField.vue";
import ButtonComponent from "@/ui-kit/button/ButtonComponent.vue";
import LoaderIconVue from "@/ui-kit/loader/LoaderIcon.vue";
import TableComponentVue from "@/ui-kit/table/TableComponent.vue";
import AlertComponent from "@/ui-kit/AlertComponent.vue";
import VersionDisplay from "@/components/HelloWorld.vue";
import OnboardingCardComponent from "@/components/OnboardingCardComponent.vue";
import SearchComponent from "@/components/SearchComponent.vue";
import DropdownMenu from "@/components/DropdownMenu.vue";
import FilterComponent from "@/components/FilterComponent.vue";

// Apollo Imports
import {
  useQuery,
  useMutation,
  useMutationLoading,
  useQueryLoading,
} from "@vue/apollo-composable";

// GraphQL Mutations and Queries
import {
  CREATE_HOLIDAY,
  EDIT_HOLIDAYS,
  SOFT_DELETE_HOLIDAY,
  REMOVE_LEAVE_HOLIDAY_STATUS,
} from "../../graphql/mutations/leave-configuration/setHolidays";
import { LIST_HOLIDAYS } from "../../graphql/querries/leave-configuration/getHolidays";

import { useAccessStore } from "@/apps/leave-management/store/store_leaves_permissions"; import NotAuthorised from "@/components/NotAuthorized.vue";
import { removeActionHeader } from "@/apps/leave-management/store/permissionFunctions";

// Constants
const env = process.env;

// Reactive Variables
const Access = useAccessStore();
const permissions = Access.permissions?.settings?.leave_management;
const leavesSettingsPerm = permissions ?? {};
let selectedCountry: Ref<string | null> = ref(localStorage.getItem('countryName'));
const searchedQuery = ref("");
const selectedYear = ref<number>(new Date().getFullYear());

let showOnboarding = ref(true);
const setLocal = () => {
  showOnboarding.value = false;
};

const headers = [
  { key: "title", label: "Holiday name" },
  { key: "start_day", label: "Date(s)" },
  { key: "end_day", label: "Day(s)" },
  { key: "action", label: "Actions" },
];

let addDialog = ref(false);
let editMode = ref(false);
const showFilters = ref(false);
let ListHolidays = ref<LeaveHoliday[]>([]);
let archiveFormDialog = ref(false);
let removeFormDialog = ref(false);
let selectedFilter = ref("Unarchived");
let selectedHoliday = ref(new Date().getFullYear().toString());
const moreThanOneDayChecked = ref(false);
const countries = ref([]);
generateListOfCountries().then((data) => {
  countries.value = data;
});

const messageBlock = reactive({ open: false, severity: "", message: "" });
const loading = useMutationLoading();
let queryLoading = useQueryLoading();

let leave: LeaveHoliday = reactive<LeaveHoliday>({
  id: "",
  title: "",
  description: "",
  start_day: "",
  end_day: "",
  country: "",
  is_archived: false,
  listHolidays: []
});

// computed properties
const displayedHeaders = computed(() =>
  removeActionHeader(headers, leavesSettingsPerm.manage_leave_holidays)
);

const contriesFilter = computed(() => {
  const uniqueCountries = new Set();

  ListHolidays.value.forEach((holiday) => {
    uniqueCountries.add(holiday.country);
  });

  return Array.from(uniqueCountries);
});

const holidaysFilter = computed(() => {
  const uniqueHolidays = new Set();

  ListHolidays.value.forEach((holiday) => {
    uniqueHolidays.add(new Date(holiday.start_day).getFullYear());
  });

  const sortedHolidays = Array.from(uniqueHolidays as Set<number>).sort(
    (a, b) => a - b
  );

  return sortedHolidays;
});

const tableData = computed(() => {
  if (searchedQuery.value.trim() !== "") {
    return ListHolidays?.value?.filter((item: { title: string }) => {
      return (
        item?.title.toLowerCase().indexOf(searchedQuery.value.toLowerCase()) !=
        -1
      );
    });
  } else {
    let filteredData = (ListHolidays.value as LeaveHoliday[]).filter((item) => {
      const itemYear = getYearFromDate(item.start_day);

      if (selectedFilter.value === "Archived") {
        return item.is_archived;
      } else if (selectedFilter.value === "Unarchived") {
        return !item.is_archived;
      } else {
        return itemYear === selectedYear.value;
      }
    });

    filteredData = filteredData.filter(
      (holiday) => holiday.country === selectedCountry.value
    );
    filteredData = filteredData.filter(
      (holiday) =>
        new Date(holiday.start_day).getFullYear() ===
        Number(selectedHoliday.value)
    );

    filteredData.sort((a, b) => {
      const titleA = a.title.toLowerCase();
      const titleB = b.title.toLowerCase();
      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }
      return 0;
    });

    return filteredData.slice();
  }
});

// functions
const close = () => {
  leave.id = "";
  leave.title = "";
  leave.description = "";
  leave.start_day = "";
  leave.end_day = "";
  leave.country = "";
  moreThanOneDayChecked.value = false;
  editMode.value = false;
  addDialog.value = false;
};

watchEffect(() => {
  if (!addDialog.value) {
    close();
  }
});

const toggleFilters = () => {
  showFilters.value = !showFilters.value;
};

function searchTableData(searchString: string) {
  searchedQuery.value = searchString;
}

const handleSelectOption = (item: LeaveHoliday, name: string) => {
  if (name === "Edit") {
    editLeaveHoliday(item);
  } else if (name === "Archive" || name === "Unarchive") {
    handleArchiveClick(item);
  } else if (name === "Remove") {
    handleRemoveClick(item);
  }
};

const editLeaveHoliday = (item: EditLeaveHoliday) => {
  leave.id = item.id;
  leave.title = item.title;
  leave.description = item.description;
  leave.country = item.country;
  leave.start_day = new Date(item.start_day).toISOString().split("T")[0];
  leave.end_day = item.end_day
    ? new Date(item.end_day).toISOString().split("T")[0]
    : "";
  editMode.value = true;
  addDialog.value = true;
};

// Mutations and Queries
const leaveHolidays = useQuery(LIST_HOLIDAYS, selectedFilter);

leaveHolidays.onResult((result) => {
  if (result.data && result.data.listHolidays) {
    ListHolidays.value = result.data.listHolidays;
  }
});

const addLeaveHoliday = useMutation(CREATE_HOLIDAY, () => ({
  variables: {
    data: {
      title: leave.title,
      description: leave.description,
      country: leave.country,
      start_day: leave.start_day,
      end_day: leave.end_day || null,
    },
  },
}));

const { refetch: refetchLeavesHolidays } = useQuery(LIST_HOLIDAYS);
addLeaveHoliday.onDone(() => {
  refetchLeavesHolidays();
  close();
  messageBlock.open = true;
  messageBlock.severity = "success";
  messageBlock.message = "Leave holiday added successfully";
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});

addLeaveHoliday.onError((error) => {
  messageBlock.open = true;
  messageBlock.severity = "error";
  messageBlock.message =
    error.message.length <= 100
      ? error.message
      : "Leave holiday creation failed";
  close();
  setTimeout(() => {
    messageBlock.open = false;
  }, 10000);
});

const updateLeaveHoliday = useMutation(EDIT_HOLIDAYS, () => ({
  variables: {
    updateHolidayId: leave.id,
    data: {
      id: leave.id,
      title: leave.title,
      description: leave.description,
      country: leave.country,
      start_day: leave.start_day,
      end_day: leave.end_day || null,
    },
  },
}));

updateLeaveHoliday.onDone(() => {
  refetchLeavesHolidays();
  messageBlock.open = true;
  messageBlock.severity = "success";
  close();
  messageBlock.message = "Leave holiday updated successfully";
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});

updateLeaveHoliday.onError((error) => {
  messageBlock.open = true;
  addDialog.value = false;
  messageBlock.severity = "error";
  messageBlock.message =
    error.message.length <= 100 ? error.message : "Please contact support";
  close();
  setTimeout(() => {
    messageBlock.open = false;
  }, 10000);
});

const removeDialogTitle = ref("");
const removeDialogDeleteBtn = ref("");
const removeDialogDeleteMessage = ref("");

const handleRemoveClick = async (item: LeaveHoliday) => {
  leave.id = item.id;
  leave.title = item.title;
  leave.is_archived = item.is_archived;

  removeFormDialog.value = true;
  removeDialogTitle.value = "Remove Leave Holiday";
  removeDialogDeleteMessage.value = `Do you really want to remove ${leave.title}?`;
  removeDialogDeleteBtn.value = "Remove";
};

const remove_leave_type = useMutation(REMOVE_LEAVE_HOLIDAY_STATUS, () => ({
  variables: {
    deleteHolidayId: leave.id,
  },
}));

const remove = () => {
  remove_leave_type.mutate();
};

remove_leave_type.onDone(() => {
  refetchLeavesHolidays();
  messageBlock.open = true;
  messageBlock.severity = "success";
  removeFormDialog.value = false;
  messageBlock.message = "Leave holiday removed successfully";
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});

remove_leave_type.onError((error) => {
  messageBlock.open = true;
  removeFormDialog.value = false;
  messageBlock.severity = "error";
  messageBlock.message =
    error.message.length <= 100 ? error.message : "Please contact support";
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});

const archiveDialogTitle = ref("");
const archiveDialogDeleteBtn = ref("");
const archiveDialogDeleteMessage = ref("");
const unarchiveDialogTitle = ref("");
const unarchiveDialogDeleteBtn = ref("");
const unarchiveDialogDeleteMessage = ref("");

const handleArchiveClick = (item: LeaveHoliday) => {
  leave.id = item.id;
  leave.title = item.title;
  leave.is_archived = item.is_archived;

  if (leave.is_archived === false) {
    archiveFormDialog.value = true;
    archiveDialogTitle.value = "Archive Leave Holiday";
    archiveDialogDeleteBtn.value = "Archive";
    archiveDialogDeleteMessage.value = `Do you really want to archive ${leave.title}?`;
  } else if (leave.is_archived === true) {
    archiveFormDialog.value = true;
    unarchiveDialogTitle.value = "Unarchive Leave Holiday";
    unarchiveDialogDeleteBtn.value = "Unarchive";
    unarchiveDialogDeleteMessage.value = `Are you sure you want to unarchive ${leave.title}?`;
  }
};

const archive_leave_holiday = useMutation(SOFT_DELETE_HOLIDAY, () => ({
  variables: {
    archiveHolidayId: leave.id,
    isArchived: true,
  },
}));

const archive = () => {
  archive_leave_holiday.mutate();
};

archive_leave_holiday.onDone(() => {
  messageBlock.open = true;
  messageBlock.severity = "success";
  archiveFormDialog.value = false;
  messageBlock.message = "Leave holiday archived successfully";
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});

archive_leave_holiday.onError((error) => {
  messageBlock.open = true;
  archiveFormDialog.value = false;
  messageBlock.severity = "error";
  messageBlock.message =
    error.message.length <= 100 ? error.message : "Please contact support";
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});

const unarchive_leave_holiday = useMutation(SOFT_DELETE_HOLIDAY, () => ({
  variables: {
    archiveHolidayId: leave.id,
    isArchived: false,
  },
}));

const unarchive = () => {
  unarchive_leave_holiday.mutate();
};

unarchive_leave_holiday.onDone(() => {
  messageBlock.open = true;
  messageBlock.severity = "success";
  archiveFormDialog.value = false;
  messageBlock.message = "Leave holiday unarchived successfully";
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});

unarchive_leave_holiday.onError((error) => {
  messageBlock.open = true;
  archiveFormDialog.value = false;
  messageBlock.severity = "error";
  messageBlock.message =
    error.message.length <= 100 ? error.message : "Please contact support";
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});
</script>
