<script setup>
import { ref } from "vue";
import BreadCrumbsComponent from "@/ui-kit/bread-crumbs/BreadCrumbsComponent.vue";
import LeaveNavigationLinks from "@/ui-kit/navigations/NavigationMenu.vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { useStore } from "@/store";
import TabletAndMobileView from "@/components/TabletAndMobileView.vue";

const route = useRoute();
const activeRoute = route.path.slice(1);
const store = useStore();
const { showAll } = storeToRefs(store);

const navigationItems = [
  { label: "Leave Type", to: "leave-type", dataCy: "leaveTypeNavigation" },
  { label: "Leave Policies", to: "leave-policy", dataCy: "leavePolicyNavigation" },
  { label: "Public Holidays", to: "holidays-view", dataCy: "holidaysViewNavigation" },
  { label: "Leave Approvers", to: "leave-approvers", dataCy: "leaveApproversViewNavigation" },
  { label: "Access Level", to: "access-level-view", dataCy: "accesslevelViewNavigation" },
];

const leaveBreadCrumbs = ref([
  { label: "Home", to: "/home", dataCy: "homeBreadcrumb" },
  { label: route.name, to: route.path, dataCy: "currentRouteBreadcrumb" },
]);
</script>

<template>
  <div data-cy="leaveConfigurationContainer" class="container"
    :class="{ 'max-[974px]:hidden': showAll, 'max-[1084px]:hidden': !showAll }">
    <BreadCrumbsComponent :breadcrumbs="leaveBreadCrumbs" />

    <div class="BG0 px-5 py-2 pb-8 rounded-md">
      <h3 class="py-2" data-cy="leaveConfigurationTitle">Leave Configuration</h3>

      <LeaveNavigationLinks :items="navigationItems" :activeItem="activeRoute" data-cy="leaveNavigationLinks" />
      <router-view></router-view>
    </div>
  </div>
  <div :class="showAll ? 'min-[974px]:hidden' : 'min-[1085px]:hidden'">
    <div>
      <TabletAndMobileView />
    </div>
  </div>
</template>
