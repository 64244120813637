<template>
  <div class="input_box">
    <div class="flex justify-between label_div">
      <label class="block mb-2 my-1 pt-0.5 P250 N900 dark:text-gray-300">{{ label }}</label>

      <span v-if="requireTag"
        class="bg-red-100 requiredSpan rounded-lg text-[#DD5928] text-xs px-1.5 py-1.5 mb-1.5 my-2 rounded dark:bg-red-200 dark:text-red-900">
        Required
      </span>
    </div>
    <form>
      <div
        class="file_input bg-gray-50 border border[#d8dae5] hover:border-[#8F95B2] text-gray-900 rounded-lg focus:outline-[#E47A53] focus:ring-[#F2BEAB] focus:ring peer block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-[#F2BEAB] dark:focus:border[#d8dae5] p-2">
        <label class="lab_el">
          <input type="file" name="attachment[]" @change="onFileChange" ref="fileInput" multiple
            :disabled="files.length >= 5" :placeholder="placeholder" :required="requireTag" />
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.9801 12V14.3333C11.9801 15.62 13.0268 16.6667 14.3135 16.6667C15.6001 16.6667 16.6468 15.62 16.6468 14.3333V10.6667C16.6468 8.08667 14.5601 6 11.9801 6C9.40014 6 7.31348 8.08667 7.31348 10.6667V14.6667C7.31348 16.8733 9.10681 18.6667 11.3135 18.6667"
              stroke="#8F95B2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </label>
        <div>
          <div class="file_name" v-for="(file, index) in files" :key="index">
            <p>
              {{ file.name }}
              <CloseCircle @click="removeFile(index)" class="cursor-pointer" />
            </p>

            <p class="size_text" v-if="file.size > 2097152">
              Please select file less than 2mb
            </p>
          </div>
        </div>
      </div>
    </form>
    <p class="text-[0.8rem] text-[#8F95B2]">Upload PDF(max size 2MB)</p>
    <p class="text-[0.8rem]"> </p>
    <p class="size_text" v-if="files.length >= 5">You cannot upload more 5 files</p>
  </div>
</template>
  
<script setup lang="ts">
import { inject, ref, watch } from "vue";
import CloseCircle from "@/apps/leave-management/assets/svgComponents/CloseCircle.vue";

defineProps({
  label: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  requireTag: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{
  (e: "onFileChange", files: any): void;
  (e: "onFileClear"): void;
}>();

let files = ref<FileList[]>([]);
const reset = inject("resetFiles");

const onFileChange = (e: Event) => {
  if (files.value.length < 5) {
    const newFiles = Array.from((e.target as HTMLInputElement).files as FileList);
    const remainingSlots = 5 - files.value.length;
    files.value = files.value.concat(newFiles.slice(0, remainingSlots) as File[]);
  }
  emit("onFileChange", files.value);
};

watch(reset, (value) => {
  if (value) {
    files.value = [];
  }
});

const removeFile = (index: number) => {
  files.value.splice(index, 1);
  emit("onFileChange", files.value);
};
</script>
<style scoped>
input[type="file"] {
  display: none;
}

.file_input {
  flex-direction: row-reverse !important;
  display: flex !important;
  justify-content: space-between;
  width: 100% !important;
}

.input_box {
  background-color: none;
  border: none;
  width: 100%;
}

form {
  background-color: transparent;
  width: 100%
}

.file_name>p {
  background-color: #edeff5;
  border-radius: 4px;
  padding: 2px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.file_name {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.size_text {
  color: red;
}

.input_box {
  width: 100%;

}
</style>
  