import gql from "graphql-tag";

export const APPROVE_LEAVE_REQUEST = gql`
  mutation AddManagerLeaveApproval($data: addLeaveApprovalInput!) {
    addManagerLeaveApproval(data: $data) {
      id
      status
      days_off
      user_id
      manager_id
      manager_approved
      days
      file
    }
  }
`;

export const SEND_TOUCH_POINT = gql`
  mutation SendTouchPoint($data: TouchPointInput!) {
    sendTouchPoint(data: $data) {
      user_id
      success
    }
  }
`;
