import EmployeeDashboard from '@/apps/leave-management/views/book-leaves/EmployeeDashboard.vue';
import LeaveRequests from '@/apps/leave-management/views/book-leaves/LeaveRequests.vue';
import HrApprovers from '@/apps/leave-management/views/leave-configuration/HrApprovers.vue';
import ManagerApprovals from '@/apps/leave-management/views/manager-dashboard/ManagerApprovals.vue';
import ManagerPlanner from '@/apps/leave-management/views/manager-dashboard/ManagerPlanner.vue';
import HrDashboard from '@/apps/leave-management/views/hr-dashboard/HrDashboard.vue';
import HrStaffLeaveRequest from '@/apps/leave-management/views/hr-dashboard/HrStaffLeaveRequest.vue';
import HrEmployeeInformation from '@/apps/leave-management/views/hr-dashboard/HrEmployeeInformation.vue';
import LeaveConfiguration from '@/apps/leave-management/views/leave-configuration/LeaveConfiguration.vue';
import LeaveTypes from '@/apps/leave-management/views/leave-configuration/LeaveTypes.vue';
import LeavePolicies from '@/apps/leave-management/views/leave-configuration/LeavePolicies.vue';
import LeaveHolidays from '@/apps/leave-management/views/leave-configuration/LeaveHolidays.vue';
import LeaveAccessLevel from '@/apps/leave-management/views/leave-configuration/LeaveAccessLevel.vue';
import HolidaysImports from '@/apps/leave-management/views/leave-configuration/HolidaysImports.vue';
import HelpPage from '@/apps/leave-management/views/help-page/HelpPage.vue';

const routes = [
  {
    path: '/',
    component: EmployeeDashboard,
    children: [
      {
        path: '/',
        alias: '/home',
        component: LeaveRequests,
        meta: { auth: true }
      }
    ]
  },

  {
    path: '/leave-manager-approval',
    name: 'Manager Dashboard',
    meta: { auth: true },
    component: ManagerApprovals
  },
  {
    path: '/leave-assisstance',
    name: 'FAQ',
    meta: { auth: true },
    component: HelpPage
  },
  {
    path: '/leave-hr-approval',
    component: HrDashboard,
    children: [
      {
        path: '',
        name: 'Hr Dashboard',
        meta: { auth: true },
        component: HrStaffLeaveRequest
      },
      {
        path: '/leave-hr-employee-information',
        name: 'Employee Leave Information',
        meta: { auth: true },
        component: HrEmployeeInformation
      }
    ]
  },
  {
    path: '/leave-type',
    component: LeaveConfiguration,
    children: [
      {
        path: '',
        name: 'Leave Types',
        meta: { auth: true },
        component: LeaveTypes
      },
      {
        path: '/leave-policy',
        name: 'Leave Policies',
        meta: { auth: true },
        component: LeavePolicies
      },
      {
        path: '/holidays-view',
        name: 'Holidays',
        meta: { auth: true },
        component: LeaveHolidays
      },
      {
        path: '/access-level-view',
        name: 'Access Level',
        meta: { auth: true },
        component: LeaveAccessLevel
      },
      {
        path: '/leave-approvers',
        name: 'Leave Approvers',
        meta: { auth: true },
        component: HrApprovers
      }
    ]
  },
  {
    path: '/holidays-import-view',
    name: 'HolidaysImport',
    meta: { auth: true },
    component: HolidaysImports
  },
  {
    path: '/leave-manager-leave-planner',
    name: 'ManagerLeavePlanner',
    meta: { auth: true },
    component: ManagerPlanner
  }
];

export default routes;
