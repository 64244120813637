<script setup>
import { ref } from "vue";
import BreadCrumbsComponent from "@/ui-kit/bread-crumbs/BreadCrumbsComponent.vue";
import LeaveNavigationLinks from "@/ui-kit/navigations/NavigationMenu.vue";
import { useRoute } from "vue-router";
import NotAuthorised from "@/components/NotAuthorized.vue";
import { storeToRefs } from "pinia";
import { useStore } from "@/store";
import TabletAndMobileView from "@/components/TabletAndMobileView.vue";

const route = useRoute();
const activeRoute = route.path.slice(1);

const env = process.env;

const store = useStore();
const { showAll } = storeToRefs(store);

const navigationItems = [
  { label: "Staff Leave Request", to: "leave-hr-approval", dataCy: "staffLeaveRequest" },
  { label: "Employee Leave Information", to: "leave-hr-employee-information", dataCy: "employeeLeaveInformation" },
];

const leaveBreadCrumbs = ref([
  { label: "Home", to: "/home", dataCy: "homeBreadcrumb" },
  { label: route.name, to: route.path, dataCy: "currentRouteBreadcrumb" },
]);
</script>

<template>
  <template v-if="!env.VUE_APP_HR_DASHBOARD">
    <div class="container" :class="{ 'max-[974px]:hidden': showAll, 'max-[1084px]:hidden': !showAll }"
      data-cy="hrLeaveManagerContainer">
      <BreadCrumbsComponent :breadcrumbs="leaveBreadCrumbs" />
      <div class="BG0 px-5 py-2 pb-8 rounded-md">
        <h3 class="py-2" data-cy="hrLeaveManagerTitle">HR Manager Dashboard</h3>

        <LeaveNavigationLinks :items="navigationItems" :activeItem="activeRoute" data-cy="leaveNavigationLinks" />
        <router-view></router-view>
      </div>
    </div>
  </template>
  <div v-else-if="env.VUE_APP_HR_DASHBOARD" data-cy="versionDisplay">
    <VersionDisplay class="" />
  </div>
  <div class="flex flex-col" v-else data-cy="notAuthorizedContainer">
    <div class="p-3 mx-auto mt-10" data-cy="notAuthorized"></div>
    <NotAuthorised class="" />
  </div>
  <div :class="showAll ? 'min-[974px]:hidden' : 'min-[1085px]:hidden'">
    <div>
      <TabletAndMobileView />
    </div>
  </div>
</template>
