<template>
  <template v-if="!env.VUE_APP_LEAVE_MANAGER_APPROVAL && leavesPerm.view_manager_dashboard
  ">
    <!-- ONBOARDING CARD -->
    <OnboardingCardComponent v-if="tableData.length === 0 && showOnboarding" class="z-10" @btncall="setLocal"
      v-show="showOnboarding" title="Welcome to Manager Approval"
      content="On this page you can as a manager manage employee leave requests. Get started now!." />
    <div class="container" :class="{ 'max-[974px]:hidden': showAll, 'max-[1084px]:hidden': !showAll }">
      <!-- BreadCrum & Location -->
      <BreadCrumbsComponent :breadcrumbs="leaveBreadCrumbs" />
      <div class="px-5 py-2 pb-8 rounded-md BG0">
        <div class="flex items-center justify-between">
          <h3 class="pt-2 pb-2">Manager Dashboard</h3>
        </div>

        <!-- Manager Approval section -->
        <div class="flex flex-col items-center justify-between gap-5 mt-5 mb-2 lg:flex-row">
          <div class="flex flex-col items-center gap-5 lg:flex-row">
            <h3 class="py-2">{{ formatLeaveCount(countRequests) }} Requests</h3>
            <div class="flex items-center justify-center h-10">
              <span data-cy="pending" @click="handleStatusUpdate(RequestStatus.PENDING)"
                class="px-8 py-2 text-center bg-gray-100 border border-gray-300 cursor-pointer N700 rounded-tl-md rounded-bl-md hover:bg-gray-100 hover:text-gray-600"
                :class="{
                  'bg-white border-[#D8DAE5] p-2 px-4 N700 text-center border-r':
                    requestStatus == RequestStatus.PENDING
                }">Pending</span>
              <span data-cy="approve" @click="handleStatusUpdate(RequestStatus.APPROVED)" class="
                  p-2
                  px-8
                  text-center
                  N700
                  bg-gray-100
                  border-x-0 border-y border-[#D8DAE5]
                  cursor-pointer
                  hover:bg-gray-100 hover:text-gray-600
                " :class="{
                  'bg-white N700 border-[#D8DAE5] p-2 px-4  text-center ':
                    requestStatus == RequestStatus.APPROVED
                }">Approved</span>
              <span data-cy="disapprove" @click="handleStatusUpdate(RequestStatus.DENIED)"
                class="p-2 px-8 text-center bg-gray-100 border border-gray-300 cursor-pointer N700 rounded-tr-md rounded-br-md hover:bg-gray-100 hover:text-gray-600"
                :class="{
                  'bg-white N700 border-[#D8DAE5] p-2 px-4 N700 text-center border-r':
                    requestStatus == RequestStatus.DENIED
                }">Disapproved</span>
            </div>
          </div>
          <div class="relative flex">
            <SearchComponent data-cy="search" @search="searchTableData" />
          </div>
        </div>

        <TableComponentVue :overflowXAuto="true" :headers="headers" :items="tableData" :itemsPerPage="9"
          :hiddePagination="true" :customScrollbar="true" :emptyMessage=emptyMessage>
          <template #employee_name="slotProps">
            <span @click="callEmployeeRequest(slotProps.item)"
              class="flex flex-row items-center gap-2 pt-3 pb-3 cursor-pointer">
              <div class="w-[3rem]">
                <ProfileImageComponent :initials="getUserNameInitials(slotProps.item.user?.full_name)"
                  :imgSrc="slotProps.item.user?.profile_image ?? ''" widthSize="3rem" heightSize="3rem"
                  text-size="text-2xl" />
              </div>
              {{ slotProps.item.user?.full_name }}
            </span>
          </template>

          <template #duration="{ item }">
            <span class="flex items-center">
              {{ formatDateShort(item.first_last_days[0]) }} -
              {{ formatDateShort(item.first_last_days[1]) }}
            </span>
          </template>

          <template #leave_type="slotProps">
            <span class="flex items-center">
              {{ (slotProps.item as LeaveManagerApproval)["leave_type"] }}
            </span>
          </template>

          <template #days_off="slotProps">
            <span class="flex items-center">
              {{ (slotProps.item as LeaveManagerApproval)["days_off"].toFixed(2) }}
              <span v-if="(slotProps.item as LeaveManagerApproval)['carry_over_used'] > 0" title="Carry over"
                class="text-[0.7rem] O300">
                &nbsp;+{{ (slotProps.item as LeaveManagerApproval)['carry_over_used'].toFixed(2) }}
              </span>
            </span>
          </template>

          <template #status="slotProps">
            <div data-cy="approvalStatus" class="flex items-center px-2 truncate rounded-lg gap-x-2 w-fit"
              :class="statusClass(slotProps.item.manager_approved)">
              <span class="text-[1.3rem]">•</span>
              {{ statusText(slotProps.item.manager_approved) }}
            </div>
          </template>

          <template #action="slotProps">
            <div data--cy="viewDetails" @click="callEmployeeRequest(slotProps.item as LeaveManagerApproval)"
              class="cursor-pointer">
              View
            </div>
          </template>
        </TableComponentVue>

        <div class="flex items-center justify-end py-8" v-if="countRequests > variables.page.take">
          <paginator :total-items="countRequests" v-model="currentPage" :items-per-page="variables.page.take"
            :max-pages-shown="9" :on-click="loadMore" :key="currentPage" />
        </div>

        <!-- Loading state -->
        <div v-if="queryLoading">
          <LoaderIconVue />
        </div>

        <!-- Dialog for approve and dissapprove -->
        <GDialog v-model="addDialog" max-width="45rem">
          <div class="p-4">
            <div class="flex items-center justify-between">
              <h3 class="H600 N900 dark:text-">Time off Request</h3>
              <CloseCircle @click="close" />
            </div>
            <!-- User Profile -->
            <div class="
                flex
                items-stretch
                justify-start
                mt-[0.44rem]
                w-full
                gap-x-[0.62rem]
              ">
              <ProfileImageComponent :initials="GetEmployeeInformation?.full_name
                ? getUserNameInitials(GetEmployeeInformation.full_name)
                : ''
                " :imgSrc="GetEmployeeInformation?.profile_image ?? ''" widthSize="3rem" heightSize="3rem"
                text-size="text-2xl" />
              <div v-if="!isLoading" class="flex flex-col N700">
                <div class="flex text-base gap-x-2">
                  <p class="font-medium">
                    {{ GetEmployeeInformation?.full_name }}
                  </p>
                  <p class="N500">|</p>
                  <p class="font-normal">
                    {{ GetEmployeeInformation?.department_name }}
                  </p>
                </div>
                <div>
                  <p class="font-normal">
                    {{ GetEmployeeInformation?.position_name }}
                  </p>
                </div>
              </div>
              <div v-else>Hold on...</div>
            </div>

            <!-- Overview -->
            <div class="flex flex-col md:flex-row items-stretch mt-4 gap-x-4">
              <div class="
                  w-[35.1875rem]
                  bg-[#EDEFF5]
                  rounded-lg
                  flex flex-col
                  p-4
                  gap-y-4
                ">
                <div class="flex flex-col text-base gap-y-2">
                  <p class="text-[16px] leading-6 text-gray-400 font-[500]">Requested</p>
                  <p class="N800">
                    {{ formatDateShort(leave.first_last_days[0]) }} -
                    {{ formatDateShort(leave.first_last_days[1]) }}
                  </p>
                </div>
                <div class="flex flex-col text-base gap-y-2">
                  <p class="text-[16px] leading-6 text-gray-400 font-[500]">Available days</p>
                  <p v-if="!isLoading" class="N800">
                    {{ GetAccruedDays?.leaveDaysAccrued.toFixed(2) }} days
                  </p>
                  <p v-else>Hold on...</p>
                </div>
                <div class="flex flex-col text-base gap-y-2">
                  <p class="text-[16px] leading-6 text-gray-400 font-[500]">For Annual leave</p>
                  <p v-if="!isLoading" class="N800">
                    {{ GetAccruedDays?.max_accrual.toFixed(2) }} days
                  </p>
                  <p v-else>Hold on...</p>
                </div>
                <div class="flex flex-col text-base gap-y-2">
                  <p class="text-[16px] leading-6 text-gray-400 font-[500]">Policy</p>
                  <p class="N800">
                    {{ (leave as LeaveManagerApproval)["leave_type"] }}
                  </p>
                </div>
              </div>

              <!-- Calendar -->
              <div class="w-full sm:border-none py-4 lg:py-0 md:py-0 border border-solid rounded-lg border-[#E6E8F0]">
                <DatePicker :key="datePickerKey" :shortcuts="false" v-model="dateValue" as-single use-range no-input
                  :disable-date="diabaledDate" />
              </div>
            </div>

            <!-- Description -->
            <div class="flex flex-col mt-4 N700">
              <div class="flex items-center justify-between">
                <div class="flex px-2 rounded-lg gap-x-2 w-fit" :class="{
                  'bg-[#D3F5F7]  text-[#10899E]': (leave as LeaveManagerApproval)['status'] === RequestStatus.APPROVED,
                  'bg-[#f4c4c4]  text-[#b42f2f]': (leave as LeaveManagerApproval)['status'] === RequestStatus.DENIED
                }">
                  <p>
                    <span class="text-[1rem]" v-if="leave.status === RequestStatus.APPROVED">• Approved by
                      HR</span>
                    <span class="text-[1rem]" v-else-if="leave.status === RequestStatus.DENIED">• Disapproved by
                      HR</span>
                  </p>
                </div>
                <div class="flex items-center gap-4">
                  <!-- File to download-->
                  <a v-if="leave.file && leave.file.length > 0" data-cy="requestFile" @click.prevent="downloadFile"
                    title="Click to download" class="
      flex
      gap-2
      border border-gray-300
      bg-[#F4F6FA]
      rounded
      p-2
      group
      relative
      transition
      duration-300
      hover:shadow-md
      hover:text-gray-600
      cursor-pointer
      w-60
    ">
                    <DownloadIcon data-cy="requestFileIcon" />
                    <span class="truncate">{{ getFileName(leave.file) }}</span>{{
                      leave.file.slice(-4) }}
                  </a>

                  <!-- chat button -->
                  <span data-cy="commentButton" class="relative" title="Request chats" @click="toggleChat">
                    <ConversationIcon data-cy="commentIcon" /><span
                      class="absolute inline-block w-6 h-6 text-center text-white bg-red-500 rounded-full  -top-4 -right-1 N700"
                      v-if="filteredComments && filteredComments.length > 0">{{ filteredComments.length }}</span>
                  </span>
                </div>
              </div>

              <!-- Conversations -->
              <div v-show="isChatVisible" class="p-4 overflow-y-auto h-fit">

                <template v-if="filteredComments && filteredComments.length > 0">
                  <div data-cy="commentCard" v-for="comment in filteredComments" :key="comment.id"
                    class="relative p-2 mt-2 mb-2 transition duration-300 border-b border-gray-200 group hover:shadow-md">
                    <div class="flex items-center justify-between">
                      <div class="flex items-center gap-2">
                        <ProfileImageComponent :initials="getUserNameInitials(comment?.user?.full_name)
                          " :imgSrc="comment?.user?.profile_image ?? ''" widthSize="2rem" heightSize="2rem"
                          text-size="text-md" />
                        <p class="N900">
                          {{ comment?.user?.full_name || 'Unknown User' }}
                        </p>
                      </div>
                      <p class="text-sm N600">
                        {{
                          formatDateShort(comment?.timestamp) || 'Unknown Date'
                        }}
                      </p>
                    </div>
                    <p class="ml-10 N700">
                      {{ comment?.message || 'No message available' }}
                    </p>
                  </div>
                </template>

                <template v-else>
                  <p data-cy="noComments" class="N700">
                    No touch point comments available
                  </p>
                </template>
              </div>

              <div class="pt-4" v-if="leavesPerm.manage_manager_dashboard">
                <MultiLineInputBox data-cy="commentField" label="" :showlength="true" :maxlength="200"
                  :requireTag="false" placeholder="Write a comment" v-model="leave.comments" />
              </div>

              <!-- Form actions -->
              <div class="flex justify-end gap-4 pt-2" v-if="leavesPerm.manage_manager_dashboard">
                <span class="relative group">
                  <ButtonComponent @submit="dissapproveEmployeeRequest.mutate" variant="with_border"
                    data-cy="dissapprove" :loading="dissapproveEmployeeRequest.loading.value" :disabled="leave.comments.trim().length < 1 ||
                      leave.manager_approved === 'APPROVED' ||
                      leave.manager_approved === 'DENIED' ||
                      leave.status === 'APPROVED' ||
                      leave.status === 'DENIED'
                      ">
                    Disapprove
                  </ButtonComponent>
                  <div data-cy="commentRequired" v-if="leave.comments.length === 0 &&
                    leave.status !== 'APPROVED' &&
                    leave.status !== 'DENIED' &&
                    leave.manager_approved !== 'APPROVED' &&
                    leave.manager_approved !== 'DENIED'
                  " class="
                    w-60
                    max-h-32
                    bg-opacity-90 bg-[#101840]
                    z-10
                    N0
                    text-sm
                    rounded
                    p-2
                    absolute
                    -left-40
                    hidden
                    group-hover:block
                    overflow-hidden
                    whitespace-normal
                    -top-8
                  ">
                    A comment is required for disapproval.
                  </div>
                </span>

                <ButtonComponent @submit="approveEmployeeRequest.mutate" data-cy="approve"
                  :loading="approveEmployeeRequest.loading.value" :disabled="leave.manager_approved === 'APPROVED' ||
                    leave.manager_approved === 'DENIED' ||
                    leave.status === 'APPROVED' ||
                    leave.status === 'DENIED'
                    ">
                  Approve
                </ButtonComponent>
              </div>
            </div>
          </div>
        </GDialog>
      </div>
    </div>
    <AlertComponent :message-block="messageBlock" />
  </template>
  <div v-else-if="env.VUE_APP_LEAVE_MANAGER_APPROVAL">
    <VersionDisplay class="" />
  </div>
  <div class="flex flex-col" v-else>
    <NotAuthorised class="" />
  </div>
  <div :class="showAll ? 'min-[974px]:hidden' : 'min-[1085px]:hidden'">
    <div>
      <TabletAndMobileView />
    </div>
  </div>
</template>

<script setup lang="ts">
// Vue imports
import { reactive, ref, computed, watchEffect, onMounted } from 'vue';
import { useStore } from '@/store';

// Interfaces
import { LeaveManagerApproval } from '../../interfaces/manager-dashboard/LeaveManagerApprovals';

// Helper functions
import {
  formatDateShort,
  createStatusRef,
  updateStatus,
  RequestStatus,
  updateRequestTotal,
  filterByManagerApproved,
  formatLeaveCount,
  formatDate
} from '../../helpers/leaveHelperFunctions';
import { getUserNameInitials } from '@/helpers/functions';

// SVG Component Imports
import CloseCircle from '../../assets/svgComponents/CloseCircle.vue';
import DownloadIcon from '../../assets/svgComponents/DownloadIcon.vue';
import ConversationIcon from '../../assets/svgComponents/ConversationIcon.vue';

// UI Component Imports
import MultiLineInputBox from '@/ui-kit/Inputs/TextArea.vue';
import BreadCrumbsComponent from '@/ui-kit/bread-crumbs/BreadCrumbsComponent.vue';
import ButtonComponent from '@/ui-kit/button/ButtonComponent.vue';
import LoaderIconVue from '@/ui-kit/loader/LoaderIcon.vue';
import TableComponentVue from '@/ui-kit/table/TableComponent.vue';
import paginator from "@/ui-kit/pagination/PaginationComponent.vue";
import AlertComponent from '@/ui-kit/AlertComponent.vue';
import OnboardingCardComponent from '@/components/OnboardingCardComponent.vue';
import DatePicker from 'vue-tailwind-datepicker';
import ProfileImageComponent from '@/components/ProfileImageComponent.vue';
import SearchComponent from '@/components/SearchComponent.vue';

// Apollo Imports
import { useQuery, useMutation, useQueryLoading } from '@vue/apollo-composable';
// GraphQL Mutations and Queries
import { APPROVE_LEAVE_REQUEST } from '../../graphql/mutations/manager-dashboard/setManagerApprovals';
import {
  GET_MANAGER_REQUESTS, GET_TOTAL_MANAGER_REQUESTS,
  GET_TOUCH_POINT_COMMENTS
} from '../../graphql/querries/manager-dashboard/getManagerApprovals';
import { GET_EMPLOYEE_INFO } from '../../graphql/querries/hr-dashboard/getHrApprovals';
import { GET_ACCRUED_DAYS } from '@/apps/leave-management/graphql/querries/book-leaves/getLeaveRequests';

import { useAccessStore } from '@/apps/leave-management/store/store_leaves_permissions';
import NotAuthorised from '@/components/NotAuthorized.vue';
import VersionDisplay from '@/components/HelloWorld.vue';
import { storeToRefs } from "pinia";
import TabletAndMobileView from "@/components/TabletAndMobileView.vue";

// Constants
const env = process.env;
const store = useStore();
const { showAll } = storeToRefs(store);

// Reactive Variables
const Access = useAccessStore();
const permissions = Access.permissions?.settings?.leave_management;
const leavesPerm = permissions ?? {};
let currentUserId = store?.user?.user_id;
const managerId = currentUserId?.toString();
const searchedQuery = ref('');

let showOnboarding = ref(true);
const setLocal = () => {
  showOnboarding.value = false;
};

const leaveBreadCrumbs = ref([
  { label: 'Home', to: '/home' },
  { label: 'Manager Dashboard', to: '/leave-manager-approval' }
]);

const headers = [
  { key: 'employee_name', label: 'Employee name' },
  { key: 'duration', label: 'Duration' },
  { key: 'leave_type', label: 'Leave type' },
  { key: 'days_off', label: 'Days' },
  { key: 'status', label: 'Status' },
  { key: 'action', label: 'Actions' }
];

let addDialog = ref(false);
let editMode = ref(false);
let ListManagerRequests = ref<LeaveManagerApproval[]>([]);
const isChatVisible = ref(false);

const messageBlock = reactive({ open: false, severity: '', message: '' });
let queryLoading = useQueryLoading();

let leave: LeaveManagerApproval = reactive<LeaveManagerApproval>({
  id: '',
  user: {
    full_name: '',
    profile_image: ''
  },
  user_id: '',
  leave_type_id: '',
  leave_type: '',
  full_name: '',
  profile_image: '',
  description: '',
  document: '',
  days: '',
  days_off: 0,
  carry_over_used: 0,
  first_last_days: '',
  status: '',
  manager_approved: '',
  comments: '',
  file: '',
  is_archived: false,
  touch_point_comments: []
});

// computed properties
const emptyMessage = computed(() => {
  if (tableData.value.length === 0) {
    if (requestStatus.value === RequestStatus.APPROVED) {
      return "No approved leave requests";
    } else if (requestStatus.value === RequestStatus.PENDING) {
      return "No pending leave requests";
    } else if (requestStatus.value === RequestStatus.DENIED) {
      return "No disapproved leave requests";
    } else {
      return "No leave requests";
    }
  } else {
    return '';
  }
});

const tableData = computed<LeaveManagerApproval[]>(() => {
  if (searchedQuery.value.trim() !== '') {
    return ListManagerRequests?.value?.filter((item) => {
      const fullName = item.user?.full_name || '';
      return fullName.toLowerCase().includes(searchedQuery.value.toLowerCase());
    });
  } else {
    let filteredData = filterByManagerApproved(
      ListManagerRequests.value as LeaveManagerApproval[],
      requestStatus.value
    );

    filteredData = filteredData.filter((item) => {
      return (
        item.user?.full_name !== null && item.user?.full_name !== undefined
      );
    });

    return filteredData.slice();
  }
});

const filteredComments = computed(() => {
  return (
    leave.touch_point_comments &&
    leave.touch_point_comments.filter(
      (comment) => comment?.message !== undefined && comment?.message !== ''
    )
  );
});

// functions
const close = () => {
  leave.id = '';
  leave.comments = '';
  editMode.value = false;
  addDialog.value = false;
};

watchEffect(() => {
  if (!addDialog.value) {
    close();
  }
});

let requestStatus = createStatusRef(RequestStatus.PENDING);
const handleStatusUpdate = (manager_approved: RequestStatus) => {
  updateStatus(manager_approved, requestStatus);
};

let requestTotal = reactive({
  [RequestStatus.APPROVED]: 0,
  [RequestStatus.PENDING]: 0,
  [RequestStatus.DENIED]: 0
});

const variables = reactive({
  managerId: managerId,
  page: {
    take: 10,
    skip: 0,
    status: requestStatus.value,
    search: null as string | null
  }
});

const variablesTotal = reactive({
  managerId: managerId,
  data: {
    status: requestStatus.value,
    search: null as string | null
  }
});

watchEffect(() => {
  variables.page.status = requestStatus.value;
  variablesTotal.data.status = requestStatus.value;
  variables.page.skip = 0;
});

function searchTableData(searchString: string) {
  searchedQuery.value = searchString;
  variables.page.search = searchString.trim() !== "" ? searchString.trim() : null;
  variablesTotal.data.search = searchString.trim() !== "" ? searchString.trim() : null;
  leaveManagerRequests.refetch(variables);
  leaveTotalManagerRequests.refetch(variablesTotal);
}

const toggleChat = () => {
  isChatVisible.value = !isChatVisible.value;
};

const getFileName = (url: string) => {
  if (url) {
    const parts = url.split('/');
    return parts[parts.length - 1];
  }
  return '';
};

const downloadFile = () => {
  const fileUrl = leave.file;
  const link = document.createElement('a');
  link.href = fileUrl;
  link.setAttribute('download', '');
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const dateValue = ref({
  startDate: '',
  endDate: ''
});

const diabaledDate = () => {
  return true;
};

let datePickerKey = ref(0);

const { result, refetch: refetchTouchPointComments } = useQuery(
  GET_TOUCH_POINT_COMMENTS,
  {
    requestId: leave.id
  }
);

const callEmployeeRequest = async (item: LeaveManagerApproval) => {
  leave.id = item.id;
  leave.status = item.status;
  leave.user_id = item.user_id;
  leave.full_name = item.user?.full_name;
  leave.file = item.file;
  leave.manager_approved = item.manager_approved;
  leave.days = item.days;
  leave.first_last_days = item.first_last_days;
  leave.leave_type = item.leave_type;
  leave.profile_image = item.user?.profile_image;

  await refetchTouchPointComments({ requestId: leave.id });

  if (result.value) {
    leave.touch_point_comments = result.value.listCommentsById || [];
  }

  editMode.value = true;
  addDialog.value = true;

  if (leave.user_id) {
    await GetAccruedDaysByUserId.refetch({
      userId: leave.user_id.toString(),
    });

    await GetEmployeeInformationByUserId.refetch({
      userId: leave.user_id.toString(),
    });
  }

  dateValue.value = {
    startDate: formatDate(new Date(leave.first_last_days[0])),
    endDate: formatDate(new Date(leave.first_last_days[1])),
  };

  datePickerKey.value += 1;
};

const statusClass = (status: string) => {
  switch (status) {
    case RequestStatus.APPROVED:
      return 'text-[#10899E] bg-[#D3F5F7]';
    case RequestStatus.PENDING:
      return 'text-[#b48429] bg-[#fcefd6]';
    case RequestStatus.DENIED:
      return 'text-[#b42f2f] bg-[#f4c4c4]';
    default:
      return '';
  }
};

const statusText = (status: string) => {
  switch (status) {
    case RequestStatus.APPROVED:
      return 'Approved';
    case RequestStatus.PENDING:
      return 'Pending';
    case RequestStatus.DENIED:
      return 'Disapproved';
    default:
      return '';
  }
};

// Mutations & Queries
const GetAccruedDays = ref({
  leaveDaysAccrued: 0,
  leaveDaysUsed: 0,
  max_accrual: 0
});

const GetAccruedDaysByUserId = useQuery(GET_ACCRUED_DAYS, {
  userId: leave.user_id?.toString()
});

GetAccruedDaysByUserId.onResult((result) => {
  if (result.data && result.data.getAccruedDaysByUserId) {
    const accruedData = result.data.getAccruedDaysByUserId;

    if (accruedData.totalAccumulated !== null && accruedData.activeAccrual) {
      GetAccruedDays.value.leaveDaysAccrued = accruedData.totalAccumulated;
      GetAccruedDays.value.leaveDaysUsed =
        accruedData.activeAccrual.leaveDaysUsed;
      GetAccruedDays.value.max_accrual = accruedData.activeAccrual.max_accrual;
    }
  }
});

const GetEmployeeInformation = ref<{
  profile_image: string;
  full_name: string;
  country: string;
  email: string;
  department_name: string;
  position_name: string;
}>({
  profile_image: '',
  full_name: '',
  country: '',
  email: '',
  department_name: '',
  position_name: '',
});

const GetEmployeeInformationByUserId = useQuery(GET_EMPLOYEE_INFO, {
  userId: leave.user_id?.toString(),
});

const isLoading = ref(true);

GetEmployeeInformationByUserId.onResult((result) => {
  if (result.data && result.data.getLeavesEmployeeActiveInfo) {
    const employeeInfo = result.data.getLeavesEmployeeActiveInfo;

    GetEmployeeInformation.value = {
      profile_image: employeeInfo?.users?.profile_image,
      full_name: employeeInfo?.users?.full_name,
      email: employeeInfo?.users?.email,
      department_name: employeeInfo?.organization?.name,
      position_name: employeeInfo?.position?.position_name,
      country: employeeInfo?.organization?.country,
    };

    isLoading.value = false;
  }
});

const leaveManagerRequests = useQuery(GET_MANAGER_REQUESTS, variables, {
  fetchPolicy: 'network-only'
});

leaveManagerRequests.onResult((result) => {
  if (result.data && result.data.getManagerRequests) {
    ListManagerRequests.value = result.data.getManagerRequests;
    updateRequestTotal(ListManagerRequests.value, requestTotal);
  }
});

let leaveTotalManagerRequests = useQuery(GET_TOTAL_MANAGER_REQUESTS, variablesTotal, {
  fetchPolicy: 'network-only'
});
const countRequests = ref<number>(0);

onMounted(() => {
  leaveTotalManagerRequests.refetch();
});

leaveTotalManagerRequests.onResult((result) => {
  if (result.data && result.data.countManagerRequests) {
    countRequests.value = result.data.countManagerRequests.count;
  } else {
    countRequests.value = 0;
  }
});

onMounted(() => {
  leaveManagerRequests.refetch();
});

const approveEmployeeRequest = useMutation(APPROVE_LEAVE_REQUEST, () => ({
  variables: {
    data: {
      request_id: leave.id,
      approved_by: currentUserId?.toString(),
      comments: leave.comments,
      approval: RequestStatus.APPROVED
    }
  }
}));

approveEmployeeRequest.onDone(() => {
  leaveManagerRequests.refetch(variables);
  leaveTotalManagerRequests.refetch(variablesTotal);
  messageBlock.open = true;
  addDialog.value = false;
  close();
  messageBlock.severity = 'success';
  messageBlock.message = 'Leave request approved successfully';
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});

approveEmployeeRequest.onError((error) => {
  messageBlock.open = true;
  addDialog.value = false;
  close();
  messageBlock.severity = 'error';
  messageBlock.message =
    error.message.length <= 100 ? error.message : 'Please contact support';
  setTimeout(() => {
    messageBlock.open = false;
  }, 5000);
});

const dissapproveEmployeeRequest = useMutation(APPROVE_LEAVE_REQUEST, () => ({
  variables: {
    data: {
      request_id: leave.id,
      approved_by: currentUserId?.toString(),
      comments: leave.comments,
      approval: RequestStatus.DENIED
    }
  }
}));

dissapproveEmployeeRequest.onDone(() => {
  leaveManagerRequests.refetch(variables);
  leaveTotalManagerRequests.refetch(variablesTotal);
  messageBlock.open = true;
  addDialog.value = false;
  close();
  messageBlock.severity = 'success';
  messageBlock.message = 'Leave request dissaproved successfully';
  setTimeout(() => {
    messageBlock.open = false;
  }, 3000);
});

dissapproveEmployeeRequest.onError((error) => {
  messageBlock.open = true;
  addDialog.value = false;
  close();
  messageBlock.severity = 'error';
  messageBlock.message =
    error.message.length <= 100 ? error.message : 'Please contact support';
  setTimeout(() => {
    messageBlock.open = false;
  }, 5000);
});

const currentPage = ref<number>(1);

const loadMore = (num: number) => {
  variables.page.skip = (num - 1) * variables.page.take;
  leaveManagerRequests.refetch(variables);
};
</script>
